<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALINSERIRCLIENTENOVOETAPA.AVISO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{retornaTextoAviso()}}</p>
            </b-notification>
            <div class="panel">

                <p class="panel-block">
                    <searchIdName  :id.sync="ClienteId" :codigoExterno="true"
                        :trazDesativado="false" :label="$t('SISTEMA.CLIENTE')" table="Cliente" :camposAdicionais="'&unidadeId='+unidadeId" >
                            <slot>
                                <b-button icon-left="plus-box"
                                        @click="abrirTelaCadastroCliente()"                                       
                                        :title="$t('MODALINSERIRCLIENTENOVOETAPA.NOVOCLIENTE')"></b-button>
                            </slot>                    
                    </searchIdName>                                                                                    
                </p>                   

                <p class="panel-block">                                     
                    <searchIdName :id.sync="FuncionarioComercialId" :trazDesativado="false" 
                    :label="$t('GUIAATENDIMENTO.VENDEDOR')" table="FuncionarioComercial"
                    :camposAdicionais="'&unidadeId='+unidadeId" >
                    </searchIdName>                                              
                </p>      
                <p class="panel-block">                                     
                    <searchIdName :id.sync="FuncionarioProjetistaId" :trazDesativado="false" 
                    :label="$t('GUIAATENDIMENTO.PROJETISTA')" table="FuncionarioProjetista"
                    :camposAdicionais="'&unidadeId='+unidadeId" >
                    </searchIdName>                                            
                </p>               

                <p class="panel-block">                                     
                    <searchIdName  :id.sync="FuncionarioConferenteId" :trazDesativado="false" 
                    :label="$t('GUIAATENDIMENTO.CONFERENTE')" table="FuncionarioConferente">
                    </searchIdName>                                               
                </p>        
                
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.TIPO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-switch v-model="Assistencia"  type="is-primary" >
                        {{$t('VENDAS.ASSISTENCIA')}}?
                    </b-switch>                                   
                </div>                    

                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>           


            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click=" $emit('close'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    import searchIdName from '@/components/searchIdName.vue'    
    
    export default {

        props: {
            tipoEtapa: String                                                                  
        },        

        data() {
            return {                                                            
                salvandoDados :false,
                observacoes: null,
                clienteId:null,
                FuncionarioComercialId: null,
                FuncionarioProjetistaId: null,
                FuncionarioConferenteId: null,
                Assistencia: false,
            }
        },
        components: {                     
            searchIdName            
        },          
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            return true;
        },
        methods: {
            abrirTelaCadastroCliente(){
                this.$emit('close');      
                this.$router.push({ name: 'cliente'});                                  
            },
            retornaTextoAviso(){
              
              if(this.tipoEtapa == 'instalacaofutura'){
                return this.$t('MODALINSERIRCLIENTENOVOETAPA.AVISOETAPAINSTALACAOFUTURA');
              }else if(this.tipoEtapa == 'instalacaoincompleta'){
                return this.$t('MODALINSERIRCLIENTENOVOETAPA.AVISOETAPAINSTALACAOINCOMPLETA');
              }

              return '';
            },

            async confirmar() {

                if(this.tipoEtapa!=null && this.ClienteId>0 && this.FuncionarioComercialId>0
                && this.FuncionarioProjetistaId>0 && this.FuncionarioConferenteId>0 ){
                    this.salvandoDados=true;
                    const params = {
                        tipoEtapa:this.tipoEtapa,
                        ClienteId:this.ClienteId,
                        FuncionarioComercialId:this.FuncionarioComercialId,
                        FuncionarioProjetistaId:this.FuncionarioProjetistaId,
                        FuncionarioConferenteId:this.FuncionarioConferenteId,
                        Observacoes:this.observacoes,
                        Assistencia: this.Assistencia,
                        UnidadeId: this.unidadeId
                    }                              
                    try {                            
                        this.$http.post(`/api/posvenda/InserirClienteNovoEtapa`,params)
                        .then(res => res.data)
                        .then(data => {
                            this.salvandoDados=false;                            
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t("SISTEMA.SALVOSUCESSO"),
                                type: 'is-success'
                            });                              
                        }).finally(() => {                                                                                                                    
                            this.$emit('recarregarposvendasInstalacaoFutura')                            
                            this.$emit('close');                                                                
                        });                             
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }
                }

            },
            

        }
    }
</script>