<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <div  class="columns ">
                <div v-if="tipo=='todos'" class="column is-2">                
                <b-field>
                    <b-input :placeholder="$t('SISTEMA.CODIGO')"
                            v-model="codigoExterno"
                            type="number"
                                icon="magnify"
                            @input="loadAsyncData()">
                    </b-input>
                </b-field>                
            </div>            
            <div class="column is-3">
                <b-field>
                    <b-input :placeholder="$t('GUIALIST.BUSCARGUIASNOME')"
                             v-model="procurar"
                             icon="magnify"
                             @input="loadAsyncData()"></b-input>
                </b-field>
            </div>            
            <div class="column is-3">
                <b-field>
                    <b-select v-if="tipo=='todos'" v-model="statusFiltro" :placeholder="$t('SISTEMA.STATUS')"  @input="loadAsyncData()" expanded>
                        <option :value="null"></option>
                        <template v-for="(item, index) in this.StatusGuia">
                            <option v-if="statusFiltro == item.valor" selected v-bind:key="index" :value="item.valor">
                                {{item.descricao == 'Asssitencia' ?  'Assistencia' : item.descricao }}
                            </option>
                            <option v-else v-bind:key="index" :value="item.valor">
                                {{item.descricao == 'Asssitencia' ?  'Assistencia' : item.descricao }}
                            </option>
                        </template>
                    </b-select>
                    <b-select v-if="tipo=='instalacaofutura'" v-model="StatustipoVenda" :placeholder="$t('SISTEMA.STATUS')"  @input="loadAsyncData()" expanded>
                        <option :value="null"></option>
                        <template v-for="(item, index) in this.StatusTipoVenda">
                            <option v-if="statusFiltro == item.valor" selected v-bind:key="index" :value="item.valor">
                                {{item.descricao}}
                            </option>
                            <option v-else v-bind:key="index" :value="item.valor">
                                {{item.descricao}}
                            </option>
                        </template>
                    </b-select>    
                    <b-select v-if="tipo=='vistoria'" v-model="ordenarPorVistoria" placeholder="Ordenar Por"  @input="ordenacaoPorVistoria()" expanded>
                        <option :value="null"></option>
                        <template >
                            <option value ="previsaoinstalacao"> Ordenar por Previsão Instalação</option>     
                            <option value ="primeirocontato"> Ordenar por Primeiro Contato</option>                            
                            <option value ="proximavistoria">Ordenar por Próxima Vistoria</option>                            
                        </template>
                    </b-select>                                     
                </b-field>
            </div>   

            <div v-if="tipo != 'instalacaofutura' && tipo != 'conferenciasexecucao'" class="column is-3">
                <b-field>
                    <b-select  v-model="vendedorFiltro" :placeholder="$t('GUIALIST.VENDEDOR')"  @input="loadAsyncData()" expanded>
                        <option :value="null"></option>
                        <template v-for="(item, index) in this.listaVendedoresUnidade">
                            <option v-if="vendedorFiltro == item.id" selected v-bind:key="index" :value="item.id">
                                {{item.nome}}
                            </option>
                            <option v-else v-bind:key="index" :value="item.id">
                                {{item.nome}}
                            </option>
                        </template>
                    </b-select>              
                </b-field>
            </div>  
            <div v-if="tipo == 'negociacao' " class="column is-3">
                <b-field>
                    <b-select  v-model="temperaturaFiltro" :placeholder="$t('GUIALIST.TEMPERATURA')"  @input="loadAsyncData()" expanded>                        
                        <option :value="null"></option>
                        <option value="1">FRIO</option>
                        <option value="2">MORNO</option>
                        <option value="3">QUENTE</option>                        
                    </b-select>              
                </b-field>
            </div>  


 
            <div v-if="tipo == 'conferenciasexecucao' && !isConferente()" class="column is-3">
                <b-field>
                    <b-select  v-model="conferenteFiltro" :placeholder="$t('GUIALIST.CONFERENTE')"  @input="loadAsyncData()" expanded>
                        <option :value="null"></option>
                        <template v-for="(item, index) in this.listaconferentesUnidade">
                            <option v-if="conferenteFiltro == item.id" selected v-bind:key="index" :value="item.id">
                                {{item.nome}}
                            </option>
                            <option v-else v-bind:key="index" :value="item.id">
                                {{item.nome}}
                            </option>
                        </template>
                    </b-select>              
                </b-field>
            </div>               
            
            <div v-if="tipo == 'vistoria'" class="column is-2">
                <span v-if="isInRole('atendimento-unificar')"  class="is-pulled-right ">
                    <b-button v-if="!habilitaSelecionarUnificarVistoria"
                        :title="$t('POSVENDAS.UNIFICARATENDIMENTO')"  class="is-warning" 
                    icon-left="merge"  @click="selecionarAtendimentosUnificar('Vistoria')"></b-button>      
                    
                    <b-button v-if="habilitaSelecionarUnificarVistoria" :title="$t('POSVENDAS.CONFIRMARUNIFICACAO')"  class="is-success" 
                     icon-left="check"
                    @click="confirmarUnificar('Vistoria')"></b-button>  

                    <b-button v-if="habilitaSelecionarUnificarVistoria" :title="$t('POSVENDAS.CANCELARCONFIRMARLIBERACAO')"  class="is-danger" 
                     icon-left="close"
                    @click="cancelarSelecionarUnificar('Vistoria')"></b-button>                                                                           
                </span>    
            </div>  
        
            </div>

            <div  v-if="tipo == 'negociacao' " class="columns">

                <div class="column is-10">

                 </div>                  

                <div class="column is-2" style="text-align: right;">
                    <b-field>
                        <b-button icon-left="file-pdf-box" class="is-info"  size="is-small" :title="$t('SISTEMA.EXPORTAR')"
                        @click.prevent.stop="exportarResultadosPdf()">{{$t('SISTEMA.EXPORTAR')}}</b-button>    
                    </b-field>
                 </div>  


            </div>

            <div v-if="tipo=='todos' || tipo == 'vistoria' || tipo == 'instalacaofutura'"  class="columns ">
                <div class="column is-6">   
                    
                    <b-collapse class="panel m-0" animation="slide" :open="false" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Filtrar por Datas
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>
                        <p v-if="tipo=='todos'"  class="panel-tabs">
                            <a :class="{ 'is-active': dataFiltro == 'cadastro' }" @click="dataFiltro = 'cadastro'">{{$t('GUIALIST.EMISSAO')}}</a>
                            <a :class="{ 'is-active': dataFiltro == 'venda' }" @click="dataFiltro = 'venda'">{{$t('GUIALIST.DATAVENDA')}}</a>
                            <a :class="{ 'is-active': dataFiltro == 'posvenda' }" @click="dataFiltro = 'posvenda'">{{$t('GUIALIST.DATAPOSVENDA')}}</a>
                        </p>
                        <p v-else-if="tipo=='vistoria'"  class="panel-tabs">
                            <a :class="{ 'is-active': dataFiltro == 'previsaoinstalacao' }" @click="dataFiltro = 'previsaoinstalacao'">{{$t('GUIALIST.DATAPREVISAOINSTALACAO')}}</a>
                            <a :class="{ 'is-active': dataFiltro == 'primeirocontato' }" @click="dataFiltro = 'primeirocontato'">{{$t('GUIALIST.DATAPRIMEIROCONTATO')}}</a>
                            <a :class="{ 'is-active': dataFiltro == 'proximavistoria' }" @click="dataFiltro = 'proximavistoria'">{{$t('GUIALIST.DATAPROXIMAVISTORIA')}}</a>
                        </p>   
                        <p v-else-if="tipo=='instalacaofutura'"  class="panel-tabs">
                            <a :class="{ 'is-active': dataFiltro == 'previsaoinstalacao' }" @click="dataFiltro = 'previsaoinstalacao'">{{$t('GUIALIST.DATAPREVISAOINSTALACAO')}}</a>
                            <a :class="{ 'is-active': dataFiltro == 'previsaoentrega' }" @click="dataFiltro = 'previsaoentrega'">{{$t('GUIALIST.DATAPREVISAOENTREGA')}}</a>                            
                        </p>                                               

                        <div class="panel-block">
                            <periodoHora :dataInicial.sync="datas[0]"
                                    :dataFinal.sync="datas[1]"/>
                        </div>                        
                    </b-collapse>            

                </div> 
                
                <div v-if="tipo == 'vistoria'" class="column is-2">
                    <b-switch v-model="filtroTodos" @input="filtrarTodos()"  type="is-primary" > {{$t('SISTEMA.LISTARTODOS')}} </b-switch>
                    <b-switch v-model="filtroTodosAtrasados" @input="filtrarTodos()"  type="is-primary" > {{$t('SISTEMA.LISTARATRASADOS')}} </b-switch>
                </div> 
                
            
                <div v-if="tipo=='todos' && isInRole('menu-relatorio-clientes')"  class="column is-2">
                    <span  >
                        
                        <b-button icon-left="file-excel" class="is-info center ml-1" size="is-small"  :title="$t('SISTEMA.EXPORTAR')"
                        @click.prevent.stop="exportarResultadosExcel()">{{$t('SISTEMA.EXPORTAR')}}</b-button>    
                    </span>
                </div>            
                                    
            </div>




            


            
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                  :bordered="true"
                 :paginated=" (tipo=='todos')"
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 :aria-next-label="$t('SISTEMA.PROXPAGINA')"
                 :aria-previous-label="$t('SISTEMA.PAGINAANTERIOR')"
                 :aria-page-label="$t('SISTEMA.PAGINA')"
                 :aria-current-label="$t('SISTEMA.PAGINAATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 :row-class="(row, index) => verificaAtraso(row) "
                 @sort="onSort">

            <template >
                <b-table-column  v-slot="props" field="Id" :label="$t('SISTEMA.CODIGO')"  >

                    <span v-if="habilitaSelecionarUnificarVistoria && tipo == 'vistoria'">
                                    <b-checkbox  @input="changedUnificar(props.row)"
                                    size="is-small" type="is-info"  v-model="props.row.unificarAtendimento"></b-checkbox >                                    
                    </span>

                    {{ props.row.clienteCodigoExterno }}

                    <span v-if="props.row.status == 6">
                        <b-icon icon="trophy"
                                size="is-small"                                    
                                :title="$t('GUIALIST.ATENDIMENTOCOMPLETOFINALIZADO')"
                                type="is-warning"></b-icon>                           
                        
                    </span>

                    <span v-else-if="tipo == 'instalacaofutura'  ||  tipo == 'vistoria'  ||  tipo == 'conferenciasexecucao' ">
                            <b-icon v-if="props.row.vendaVinculadaId > 0"  icon="link"
                                        size="is-small"                                    
                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                        type="is-link"></b-icon>                                                                                                       
                            <b-icon v-if="props.row.tipo == 2"
                                            icon="cog"
                                            size="is-small"                                    
                                            :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                            <b-icon v-if="props.row.tipo == 3"
                                            icon="tools"
                                            size="is-small"                                    
                                            :title="$t('VENDAS.MOSTRUARIO')"></b-icon>                               
                    </span>

                    <span v-else>
                        <b-icon v-if="props.row.dataHoraCancelado !=null"
                                        icon="close-circle"
                                        size="is-small"                                    
                                        :title="$t('GUIALIST.ATENDIMENTOCANCELADO')"
                                        type="is-danger"></b-icon>      
                        <b-icon v-if="props.row.dataHoraVenda !=null || props.row.dataHoraEfetivacao !=null "
                                        icon="currency-usd"
                                        size="is-small"                                    
                                        :title="$t('GUIALIST.ATENDIMENTOVENDAEFETIVADO')"
                                        type="is-success"></b-icon>    
                        <b-icon v-if="props.row.dataHoraBloqueado !=null"
                                        icon="account-alert"
                                        size="is-small"                                    
                                        :title="$t('GUIALIST.CLIENTEEMESPERA')"
                                        type="is-warning"></b-icon> 
                        <b-icon v-if="props.row.status == 5"
                                        icon="archive-outline"
                                        size="is-small"                                    
                                        :title="$t('GUIALIST.POSVENDA')"
                                        ></b-icon> 
                        <b-icon v-if="props.row.guiaVinculadaId > 0"  icon="link"
                                        size="is-small"                                    
                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                        type="is-link"></b-icon>  
                        <b-icon v-if="props.row.tipo == 2"
                            icon="cog"
                            size="is-small"                                    
                            :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                        <b-icon v-if="props.row.tipo == 3"
                                        icon="tools"
                                        size="is-small"                                    
                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>                                                   
                                 
                    </span>                                                                                                                                                                                           
                </b-table-column>

                <b-table-column  v-if=" tipo== 'entregafutura' ||  tipo == 'instalacaofutura' ||  tipo == 'vistoria'  ||  tipo == 'conferenciasexecucao'" 
                  v-slot="props" field="CodigoContrato" :label="$t('SISTEMA.CODIGOCONTRATO')"  >                    
                    {{ props.row.codigoContrato }}
                </b-table-column>                

                <b-table-column  v-if=" tipo!= 'entregafutura' &&  tipo != 'instalacaofutura' &&  tipo != 'vistoria'  &&  tipo != 'conferenciasexecucao'"   v-slot="props" field="ClienteNome" :label="$t('SISTEMA.CLIENTE')" sortable  >
                    
                        <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                            <template  v-slot:content>
                                <span >       
                                    <b>ARQUITETO: </b>  {{props.row.clienteArquiteto}}                                                
                                </span>
                                <br>
                                <span v-if="props.row.temperatura>0">                                                
                                    <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                        <b> TEMPERATURA: </b> {{props.row.temperaturaNome}} 
                                        </small>                                            
                                </span>                                                                 
                                <span v-if="props.row.valorVenda>0">                                                
                                    <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                        <b>VALOR VENDA: </b> {{props.row.valorVendaFormatado}} 
                                    </small>                                            
                                </span> 
                                <span v-if="props.row.codigoContrato>0">
                                    <b>CONTRATO: </b> {{props.row.codigoContrato}}
                                    <br>
                                </span>                                                                                                 
                            </template>                                             
                            <span class="is-size-7">{{ props.row.clienteNome }}</span>
                        </b-tooltip>                              
                        <span>                                                              
                            <a class="has-text-info" @click="verStatusAtual(props.row,false)" :title="$t('GUIALIST.VERSTATUS')">
                                <b-icon  icon="eye" size="is-small"></b-icon>
                            </a>                                 
                        </span>                      
                </b-table-column>

                <b-table-column  v-if=" tipo == 'conferenciasexecucao'  "   v-slot="props" field="ClienteNome" :label="$t('SISTEMA.CLIENTE')" sortable  >
                    
                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                            <template v-slot:content>
                                <b>VENDEDOR: </b> {{props.row.funcionarioComercialNome}}
                                <span v-for="(prazo, index) in props.row.prazos" v-bind:key="index">                                                
                                    <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                        <b>PRAZO: </b> 
                                        {{prazo.prazoNome}} <b> - FALTAM:</b> {{props.row.prazoRestante[index]}}  DIAS 
                                    </small>                                            
                                </span>              
                                <span>                                                
                                    <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                        <b>VALOR VENDA: </b> {{props.row.valorVendaFormatado}} 
                                    </small>                                            
                                </span>                                                                                                                                  
                                <b>CONFERENTE: </b> {{props.row.funcionarioConferenteNome}}
                                <span>                                                
                                    <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                        <b>PRAZO: </b> {{props.row.prazoConferenciaEmDias}} dias<b> - FALTAM:</b>  {{props.row.prazoRestanteVenda}} DIAS 
                                    </small>                                            
                                                                                
                                </span>
                                
                                                                                                                                                                
                            </template>                                             
                        {{ props.row.clienteNome }}
                    </b-tooltip>                              
                    <span>                                                              
                        <a class="has-text-info" @click="verStatusAtual(props.row,false)" :title="$t('GUIALIST.VERSTATUS')">
                            <b-icon  icon="eye" size="is-small"></b-icon>
                        </a>                                 
                    </span>                      
                </b-table-column>                   

                <b-table-column  v-if="tipo == 'entregafutura' || tipo == 'vistoria'"   v-slot="props" field="ClienteNome" :label="$t('SISTEMA.CLIENTE')" sortable  >
                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                        <template  v-slot:content>
                            <span v-if="props.row.dataHoraBloqueadoFutura" >
                                <b>PRAZO LIBERAÇÃO: </b>  {{ $moment(props.row.dataHoraBloqueadoFutura).format("DD/MM/YYYY") }}  
                            <br>  </span>
                            <b>VALOR VENDA: </b> {{props.row.valorVendaFormatado}}                                                                                                                                                                                                                                                                                      
                        </template>                                             
                        {{ props.row.clienteNome }}
                    </b-tooltip>                  
                </b-table-column>                  

                <b-table-column  v-if="tipo == 'instalacaofutura'"   v-slot="props" field="ClienteNome" :label="$t('SISTEMA.CLIENTE')" sortable  >
                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                        <template v-slot:content>
                            <b>VENDEDOR: </b>  {{ props.row.funcionarioComercialNome.toUpperCase() }} <br>                                                                                                                            
                            <b>CONFERENTE: </b>  {{ props.row.funcionarioConferenteNome.toUpperCase() }}  <br>
                            <b>ELISTS: </b>  {{ props.row.elist }}  <br>
                            <b>VALOR VENDA: </b> {{props.row.valorVendaFormatado}}                                                                                                                                                                                
                        </template>                                             
                        {{ props.row.clienteNome }}
                    </b-tooltip>                  
                </b-table-column>                
    
                <b-table-column v-if="tipo =='negociacao'"   v-slot="props" field="Temperatura" :label="$t('GUIALIST.TEMPERATURA2')"  sortable >
                    <span class="is-size-7">
                        <span v-if="props.row.temperatura >0" @click="alterarTemperatura(props.row)"  class="cp ml-2 tag is-light">
                                <b v-if="props.row.temperatura == 1" :title="$t('GUIALIST.FRIO')" class="has-text-info">F</b> 
                                <b v-else-if="props.row.temperatura == 2" :title="$t('GUIALIST.MORNO')" class="has-text-warning">M</b> 
                                <b v-else-if="props.row.temperatura == 3" :title="$t('GUIALIST.QUENTE')" class="has-text-danger">Q</b>                       
                        </span> 
                        <span v-else>
                            <a class="has-text-grey" @click="alterarTemperatura(props.row)" >
                                    <b-icon  icon="pencil" size="is-small"></b-icon>
                            </a>                          
                        </span> 


                    </span>
                </b-table-column>                       
                <b-table-column v-if="tipo != 'instalacaofutura'  && tipo != 'conferenciasexecucao'"   v-slot="props" field="VendedorNome" :label="$t('GUIALIST.VENDEDOR')"  >
                    <p class="is-size-7 columns-nowrap-10" :title="props.row.funcionarioComercialNome.toUpperCase()"> {{ props.row.funcionarioComercialNome.toUpperCase() }}</p>
                </b-table-column>
                <b-table-column v-if="tipo =='negociacao'"   v-slot="props" field="ArquitetoNome" :label="$t('GUIALIST.ARQUITETO')"  >
                    <p class="is-size-7 columns-nowrap-10" :title="props.row.clienteArquiteto">{{ props.row.clienteArquiteto }}</p>
                </b-table-column> 
                         
                <b-table-column v-if="tipo == 'conferenciasexecucao'"   v-slot="props" field="ValorVenda" :label="$t('SISTEMA.VALOR')"  >
                    {{ props.row.valorVendaFormatado }}
                </b-table-column>       
                <b-table-column v-if="tipo == 'conferenciasexecucao'"   v-slot="props" field="ValorElist" :label="$t('SISTEMA.VALORELISTS')"  >
                    {{ props.row.valorElistsFormatado }}
                </b-table-column>                       
                <b-table-column v-if="tipo == 'negociacao'"   v-slot="props" field="Valor Efinance" :label="$t('SISTEMA.VALORPEFINANCE')"  >
                    <span class="is-size-7 no-wrap">{{ props.row.valorEfinanceFormatado }}
                        <a class="has-text-blue" @click="atualizarValorEfinance(props.row)" :title="$t('SISTEMA.VALORPEFINANCE')">
                                <b-icon  icon="refresh" size="is-small"></b-icon>
                    </a> </span>                        
                    
                </b-table-column>   
                <b-table-column v-if="tipo == 'negociacao'"   v-slot="props" field="Valor Previsto" :label="$t('SISTEMA.VALORPREVISTO')"  >
                    <span class="is-size-7 no-wrap">{{ props.row.valorPrevistoFormatado }}
                    <a  class="has-text-grey" @click="alterarValorPrevisto(props.row)" :title="$t('SISTEMA.VALORPREVISTO')">
                                <b-icon  icon="pencil" size="is-small"></b-icon>
                    </a> </span>
                </b-table-column>      
                <b-table-column v-if="tipo == 'negociacao'"   v-slot="props" field="Percentual" :label="$t('SISTEMA.PERCENTUAL')"  >
                    <span :class="retornaClassePercentual(props.row)"><b>{{ props.row.valorPercentualFormatado }}</b> </span>
                </b-table-column>                                                   
                <b-table-column v-if="tipo == 'conferenciasexecucao'"   v-slot="props" field="ConferenteNome" :label="$t('GUIALIST.CONFERENTE')"  >
                    {{ props.row.funcionarioConferenteNome.toUpperCase() }}
                </b-table-column>                

      
                <b-table-column v-if="tipo == 'instalacaofutura'"   v-slot="props" field="Vistoria" :label="$t('GUIALIST.VISTORIA')"  >
                    <b-switch :disabled="true" :value="(props.row.dataHoraVistoria!=null)"   ></b-switch>
                </b-table-column>   

                <b-table-column v-if="tipo == 'instalacaofutura'"   v-slot="props" field="Faturado" :label="$t('GUIALIST.FATURADO')"  >
                    
                    <span  v-if="!props.row.faturado">
                        <b-switch  :disabled="props.row.faturado" v-model="props.row.faturado" @input="confirmarFaturamento(props.row)"   ></b-switch>
                    </span>
                    <span  v-else-if="isInRole('posvendas-removerfaturamento') ">
                        <b-switch   v-model="props.row.faturado" @input="confirmarRemocaoFaturamento(props.row)"   ></b-switch>                        
                    </span>
                    <span v-else>
                        <b-switch  :disabled="props.row.faturado" v-model="props.row.faturado"  ></b-switch>
                    </span>                    
                    
             
                </b-table-column> 
                
                                                
                <b-table-column v-if="tipo == 'negociacao'" v-slot="props" field="ProximoPrazo" :label="$t('GUIALIST.PROXIMOPRAZO')"  >
                    <span class="is-size-7 no-wrap">{{ retornaDataHoraBloqueado(props.row.dataHoraBloqueado) }}</span>
                </b-table-column>  

                <b-table-column v-if="tipo == 'instalacaofutura' || tipo == 'vistoria'  " v-slot="props" field="PrazoContrato" :label="$t('GUIALIST.DATAPRAZOCONTRATO')"  >
                    
                    <small v-if="props.row.dataHoraPrazoContrato !=null">
                        <b>PRAZO:</b> {{ $moment(props.row.dataHoraPrazoContrato).format("DD/MM/YYYY")  }}
                    </small>
                    <small v-if="props.row.dataHoraPrazoContrato2 !=null">
                        <br>
                        <b>PRAZO:</b> {{ $moment(props.row.dataHoraPrazoContrato2).format("DD/MM/YYYY")  }}
                    </small>                                        
                </b-table-column>                    

                <b-table-column v-if="tipo == 'vistoria' || tipo == 'instalacaofutura'" v-slot="props" field="PrevisaoInstalacao" :label="$t('GUIALIST.DATAPREVISAOINSTALACAO')"  >
                    
                    <span v-if="props.row.dataHoraPrevisaoInstalacao !=null">
                        {{ $moment(props.row.dataHoraPrevisaoInstalacao).format("DD/MM/YYYY")  }}
                            <a  class="has-text-grey" @click="confirmarPrevisaoInstalacaoPosVenda(props.row)" :title="$t('GUIALIST.DATAPREVISAOINSTALACAO')">
                                <b-icon  icon="pencil" size="is-small"></b-icon>
                            </a>                          
                    </span>
                    <span v-else>a definir                       
                            <a  class="has-text-grey" @click="confirmarPrevisaoInstalacaoPosVenda(props.row)" :title="$t('GUIALIST.DATAPREVISAOINSTALACAO')">
                                <b-icon  icon="pencil" size="is-small"></b-icon>
                            </a>                         
                    </span>
                </b-table-column>   

                

                <b-table-column v-if="tipo == 'instalacaofutura'" v-slot="props" field="PrevisaoEntrega" :label="$t('GUIALIST.DATAPREVISAOENTREGA')" header-class="colunaatraso-color" cell-class="colunaatraso-color"  >
                    
                    <span v-if="props.row.dataHoraPrevisaoEntrega !=null">
                        {{ $moment(props.row.dataHoraPrevisaoEntrega).format("DD/MM/YYYY")  }}
                            <a  class="has-text-grey" @click="confirmarPrevisaoEntregaPosVenda(props.row)" :title="$t('GUIALIST.DATAPREVISAOENTREGA')">
                                <b-icon  icon="pencil" size="is-small"></b-icon>
                            </a>                          
                    </span>
                    <span v-else>a definir                       
                            <a  class="has-text-grey" @click="confirmarPrevisaoEntregaPosVenda(props.row)" :title="$t('GUIALIST.DATAPREVISAOENTREGA')">
                                <b-icon  icon="pencil" size="is-small"></b-icon>
                            </a>                         
                    </span>
                </b-table-column>                      
                             
                <b-table-column  v-if="tipo == 'instalacaofutura'" v-slot="props" field="Prazos" :label="$t('GUIALIST.PRAZOS')"  >                                    
                    <span  v-for="(prazo, index) in props.row.prazos" v-bind:key="index">                                                                                
                        <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                            <b>PRAZO: </b> {{prazo.prazoNome}} <b> - FALTAM:</b> {{ props.row.prazoRestante[index]}}  DIAS                                                                            
                        </small>
                    </span>     
                 
                    <small v-if="props.row.posics && props.row.posics.length>0 && props.row.posics[0]!=null " style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                        <b>PRAZO FÁBRICA 1: </b> 
                        <span v-if="props.row.posics[0].dataHoraPrevisao !=null">
                            {{$moment(props.row.posics[0].dataHoraPrevisao).format("DD/MM/YYYY") }}                                                       
                        </span>                                                     
                                                                      
                    </small>  
                    <small v-if="props.row.posics &&  props.row.posics.length>0 && props.row.posics[1]!=null "  style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                        <b>PRAZO FÁBRICA 2: </b> 
                        <span v-if="props.row.posics[1].dataHoraPrevisao !=null">
                            {{$moment(props.row.posics[1].dataHoraPrevisao).format("DD/MM/YYYY") }}                                                       
                        </span>                              
                                                                      
                    </small>
                    <small>
                        <span>                                                              
                                <a  class="has-text-info" @click="visualizarPosics(props.row)" :title="$t('GUIALIST.VISUALIZARPOSICS')">
                                    <b-icon  icon="eye" size="is-small"></b-icon> Ver Todos
                                </a>                                 
                        </span>
                    </small>                          
                                                                                              
                </b-table-column>                 

                <b-table-column v-if="tipo == 'vistoria' " v-slot="props" field="DataPrimeiroContatoVistoria" :label="$t('GUIALIST.PRIMEIROCONTATOVISTORIA')"  >
                    
                    <span v-if="props.row.dataHoraPrimeiroContatoVistoria !=null">
                        {{ $moment(props.row.dataHoraPrimeiroContatoVistoria).format("DD/MM/YYYY")  }}
                            <a class="has-text-grey" @click="confirmarPrimeiroContatoVistoriaPosVenda(props.row)" :title="$t('GUIALIST.PRAZOPRIMEIROCONTATOMENSAGEM')">
                                <b-icon  icon="pencil" size="is-small"></b-icon>
                            </a>                          
                    </span>
                    <span v-else>a definir                       
                    </span>
                    
                </b-table-column>                                   
                <b-table-column v-if="tipo == 'vistoria'" v-slot="props" field="ProximaVistoria" :label="$t('GUIALIST.PROXIMAVISTORIA')" header-class="colunaatraso-color" cell-class="colunaatraso-color" >
                    {{ retornaDataHoraAgendamentoVistoria(props.row.dataHoraAgendamentoVistoria) }}
                </b-table-column>                                

                <b-table-column v-if="tipo != 'instalacaofutura' && tipo != 'vistoria' && tipo != 'entregafutura'  && tipo != 'conferenciasexecucao'  && tipo != 'negociacao' " v-slot="props" field="DataDeEmissao" :label="$t('GUIALIST.EMISSAO')"  >
                    {{ $moment(props.row.dataHoraCadastro).format("DD/MM/YYYY HH:mm") }}
                </b-table-column>

                <b-table-column v-if="tipo == 'entregafutura'" v-slot="props" field="PrazoLiberacao" :label="$t('GUIALIST.PRAZOLIBERACAO')"  >
                    {{ $moment(props.row.dataHoraBloqueadoFutura).format("DD/MM/YYYY HH:mm") }}
                </b-table-column>                
                
                                                    
                <b-table-column  v-slot="props" custom-key="actions" numeric >
                    <router-link v-if="tipo != 'instalacaofutura' && tipo != 'vistoria'  && tipo != 'conferenciasexecucao' && tipo != 'negociacao'" :to="{ name: 'guia', params: { id: props.row.id, guiaList: (tipo=='todos')}}" class="button is-small is-primary" title="Editar">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                    <span v-else-if="tipo == 'negociacao'" >                        
                        <router-link v-if="props.row.dataHoraProjeto !=null"    :to="{ name: 'guia', params: { id: props.row.id, guiaList: (tipo=='todos')}}" class="button is-small is-primary" title="Editar">
                            <b-icon icon="pencil" size="is-small"></b-icon>
                        </router-link> 
                        <b-button  v-else @click="verStatusAtual(props.row,true)" 
                        class="is-small has-text-danger" > <b-icon icon="lock-alert-outline" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                                                                                                                                     
                    </span>

                    <router-link v-else-if="tipo == 'vistoria'  && !isVendedor()"   :to="{ name: 'guia', params: { id: props.row.guiaId, guiaList: (tipo=='todos'), emVistoria:true}}" class="button is-small is-primary" title="Editar">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>       
                    <router-link v-else-if="tipo == 'instalacaofutura'  && !isVendedor()"  :to="{ name: 'guia', params: { id: props.row.guiaId, guiaList: (tipo=='todos'),emInstalacaoFutura:true}}" class="button is-small is-primary" title="Editar">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>    
                    <router-link v-else-if="tipo == 'conferenciasexecucao'"  :to="{ name: 'guia', params: { id: props.row.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Editar">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>                         
                    <b-button v-if="isInRole('atendimento-alterarstatus') && tipo=='todos'"  
                        :title="$t('GUIALIST.ALTERARSTATUS')" 
                        class="is-small ml-1"
                        @click="confirmarAlteracaoStatus(props.row)" 
                        icon-left="playlist-edit"></b-button>                                                                                                                                                               
                </b-table-column>
                
            </template>
            <template #footer v-if="tipo == 'conferenciasexecucao'  ">
                <th >
                    <div class="th-wrap is-numeric">  </div>
                </th>
                <th > <div class="th-wrap"> </div> </th>        
                <th > <div class="th-wrap"> </div> </th>                                              
                <th >
                    <div class="th-wrap">  {{ retornarValorTotalVenda() }}  </div>
                </th>
                <th >
                    <div class="th-wrap">  {{ retornarValorElistsTotalVenda() }}  </div>
                </th>                
                <th > <div class="th-wrap"> </div> </th>                
                <th > <div class="th-wrap"> </div> </th>                

            </template>     
            <template #footer v-else-if="tipo == 'vistoria'">
                <th >
                    <div class="th-wrap is-numeric">  </div>
                </th>
                <th > <div class="th-wrap"> </div> </th>   
                <th >
                    <div class="th-wrap"> {{ retornarValorTotalVenda() }} </div>
                </th>
                <th > <div class="th-wrap"> </div> </th>                
                <th > <div class="th-wrap"> </div> </th>                
                <th > <div class="th-wrap"> </div> </th>                
                <th > <div class="th-wrap"> </div> </th>                
                <th > <div class="th-wrap"> </div> </th>                
                <th > <div class="th-wrap"> </div> </th>                

            </template> 
            <template #footer v-else-if="tipo == 'instalacaofutura'">
                <th >
                    <div class="th-wrap is-numeric">  </div>
                </th>
                <th > <div class="th-wrap"> </div> </th>   
                <th >
                    <div class="th-wrap"> {{ retornarValorTotalVenda() }} </div>
                </th>
                <th > <div class="th-wrap"> </div> </th>     
                <th > <div class="th-wrap"> </div> </th>                
                <th > <div class="th-wrap"> </div> </th>                
                <th > <div class="th-wrap"> </div> </th>                
                <th > <div class="th-wrap"> </div> </th>                
                <th > <div class="th-wrap"> </div> </th>                
                <th > <div class="th-wrap"> </div> </th>                

            </template>                               
            <template #footer v-else-if="tipo == 'negociacao'">
                <th >
                    <div class="th-wrap is-numeric">  </div>
                </th>
                <th > <div class="th-wrap"> </div> </th>                                              
                <th > <div class="th-wrap"> </div> </th>                
                <th > <div class="th-wrap"> </div> </th>  
                <th > <div class="th-wrap"> </div> </th>  
                <th >
                    <div class="th-wrap is-size-7 no-wrap">  {{ retornarValorTotalEfinance() }}  </div>                    
                </th>  
                <th >                    
                    <div class="th-wrap is-size-7 no-wrap">  {{ retornarValorTotalPrevisto() }}  </div>
                </th>                                 
                                
                <th > <div class="th-wrap"> </div> </th> 
                <th > <div class="th-wrap"> </div> </th> 
                <th > <div class="th-wrap"> </div> </th>                

            </template>                       
        </b-table>

        <nav class="level">
                <div class="level-item">
                    <p class="buttons">
                        <b-button type="is-danger"
                                  native-type="button"
                                  icon-left="close-circle"
                                  @click="$router.back();">
                            {{$t('SISTEMA.VOLTAR')}}
                        </b-button>
                         
                    </p>
                </div>
            </nav>        
    </section>
</template>



<style scoped>
    .has-background-dark {
        background-color: #363636d6 !important;
    }

     


</style>

<script>
    import titlebar from '@/components/titlebar.vue'
    import debounce from 'lodash/debounce'
    import modalFaturamentoParcialPosVenda from '@/components/posvenda/modalFaturamentoParcialPosVenda'  
    import modalAlterarStatusGuia from '@/components/atendimento/modalAlterarStatusGuia'      
    import modalVerStatusAtualGuia from '@/components/atendimento/modalVerStatusAtualGuia.vue'      
    import modalPrimeiroContatoVistoriaPosVenda from '@/components/posvenda/modalPrimeiroContatoVistoriaPosVenda'  
    import modalPrevisaoInstalacaoPosVenda from '@/components/posvenda/modalPrevisaoInstalacaoPosVenda.vue'  
    import modalAlteracaoValorPrevistoGuia from '@/components/atendimento/modalAlteracaoValorPrevistoGuia.vue'     
    import modalAlteracaoTemperaturaGuia from '@/components/atendimento/modalAlteracaoTemperaturaGuia.vue'   
    import modalPrevisaoEntregaPosVenda from '@/components/posvenda/modalPrevisaoEntregaPosVenda.vue'      
    import modalListarPosics from '@/components/posvenda/modalListarPosics.vue'
    import moment from 'moment'
    import periodoHora from '@/components/periodoHora.vue'
    import { mapState, mapGetters } from 'vuex'

    export default {
        data(){
            return {
                model: [],
                total: 0,
                loading: false,
                clienteId: this.$route.query.clienteId,                                       
                procurar: this.$route.query.procurar ?? null,
                tipo: this.$route.query.tipo ?? 'todos',
                procurarVendedor: null,                                             
                ordenarPor: '',
                ordenar: '',
                defaultOrdenar: '',
                page: 1,
                perPage: 20,                
                id: null,
                codigoExterno:null,

                StatusGuia:[],
                listaVendedoresUnidade:[],
                listaconferentesUnidade:[],
                vendedorFiltro:null,
                temperaturaFiltro:null,
                conferenteFiltro:null,
                StatusTipoVenda:[],
                statusFiltro: null,
                StatustipoVenda:null,
                ordenarPorVistoria:'previsaoinstalacao',
                habilitaSelecionarUnificarVistoria:false,  
                atendimentoSelecionadosUnificar: [], 
                dataFiltro: 'cadastro', 
                filtroTodos: false,
                filtroTodosAtrasados: false,
                datas: [
                        null,null
                    ]                          
            }
        },
        components: {
            titlebar,
            periodoHora
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SISTEMA.ATENDIMENTO'),this.retornarTitleStack(),                    
                ]
            },
            ...mapState([
                'unidadeId',
                'usuario'
            ]),
            ...mapGetters([
                'isInRole'
            ])            
        },
        watch: {            
            unidadeId: function(novaUnidade) {                 
                this.loadAsyncData();
                this.filtroTodos = false;                                                
            },
            datas: function() {                 
                this.loadAsyncData()                                                                
            },
            dataFiltro: function() {                 
                this.loadAsyncData();                
            },            
        },         
        methods: { 


            retornarValorTotalEfinance(){
        
                let valorTotalEfinance = 0.0;
                
                if(this.model.lista)
                    this.model.lista.forEach((item) => valorTotalEfinance += item.valorEfinance );                   

                return valorTotalEfinance.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                });
            },                        
            retornarValorTotalPrevisto(){
        
                let valorTotalPrevisto = 0.0;
                
                if(this.model.lista)
                    this.model.lista.forEach((item) => valorTotalPrevisto += item.valorPrevisto );                   

                return valorTotalPrevisto.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                });
            },  
            retornarValorTotalVenda(){
        
                let valorTotalVenda = 0.0;
                
                if(this.model.lista)
                    this.model.lista.forEach((item) => valorTotalVenda += item.valorVenda );                   

                return valorTotalVenda.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                });
            },     
            retornarValorElistsTotalVenda(){
        
                let valorTotalElist = 0.0;
                
                if(this.model.lista)
                    this.model.lista.forEach((item) => valorTotalElist += item.valorElists );                   

                return valorTotalElist.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                });
            },                     
            ordenacaoPorVistoria(){
                this.ordenarPor= '';
                this.ordenar= '';
                this.loadAsyncData(); 
            },
           
            filtrarTodos(){
                this.datas[0] = null;
                this.datas[1] = null;
                this.dataFiltro= null;
                this.loadAsyncData();            
            },
            visualizarPosics(posvenda){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalListarPosics,
                    props: {
                        posvenda: posvenda                      
                    },
                    events: {
                        loadData: this.loadAsyncData,                        
                    },                    
                    hasModalCard: true,
                    trapFocus: true                    
                })

            },               

            changedUnificar(item){

                if(this.atendimentoSelecionadosUnificar.length>0 && item.unificarAtendimento ){

                    if(this.atendimentoSelecionadosUnificar.filter(x=>x.clienteCodigoExterno == item.clienteCodigoExterno).length==0 ){
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('POSVENDAS.ATENDIMENTOSMESMOCONTRATO'),
                            type: 'is-warning',
                            hasIcon: true                                          
                        });  
                        setTimeout(() => { item.unificarAtendimento = false;},500);                   
                        return;                           
                    }

                }

                if(item.unificarAtendimento){
                    this.atendimentoSelecionadosUnificar.push(item);
                }else{
                    this.atendimentoSelecionadosUnificar = this.atendimentoSelecionadosUnificar.filter(x=>x.guiaId != item.guiaId);
                }
                    
            },            


            habilitarSelecionarUnificar(etapa,habilitar){
                if(etapa=="Vistoria"){
                    this.habilitaSelecionarUnificarVistoria = habilitar;
                }                   
                                          
            },
            selecionarAtendimentosUnificar(etapa){                
                this.habilitarSelecionarUnificar(etapa,true);
                this.atendimentoSelecionadosUnificar = []
            },  
            cancelarSelecionarUnificar(etapa){                
                this.habilitarSelecionarUnificar(etapa,false);
                this.atendimentoSelecionadosUnificar = [];   
                this.model.lista.forEach((item) => item.unificarAtendimento =false );             
            },          
            confirmarUnificar(etapa){

                if(this.atendimentoSelecionadosUnificar.length<=1){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('POSVENDAS.MENSAGEMUNIFICARATENDIMENTOS'),
                        type: 'is-warning',
                        hasIcon: true
                    });  
                    
                    return false;
                }

                this.$buefy.dialog.confirm({
                title: 'Confirmar',
                message: this.$t('POSVENDAS.MENSAGEMCONFIRMARUNIFICARATENDIMENTOS'),
                type: 'is-warning',
                hasIcon: true,
                cancelText: 'Não',
                confirmText: 'Sim',
                onConfirm: () => {

                    try {                            
                            this.$http.post(`/api/posvenda/ConfirmarUnificarAtendimentos`,this.atendimentoSelecionadosUnificar)
                            .then(res => res.data)
                            .then(data => {
                                return true;                                 
                            }).finally(() => {                                
                                this.loadAsyncData();                                                             
                                this.habilitarSelecionarUnificar(etapa,false);                                 
                            });                            

                            
                        } catch (e) {                                                  
                            console.error(e);
                        }

                    }
                });
                
            },                
            retornaDataHoraBloqueado(dataHoraBloqueado){

                if(dataHoraBloqueado){
                    return this.$moment(dataHoraBloqueado).format("DD/MM/YYYY HH:mm");
                }else{
                    return '';
                }
                
            },
            retornaDataHoraAgendamentoVistoria(dataHoraAgendamentoVistoria){

                if(dataHoraAgendamentoVistoria){
                    return this.$moment(dataHoraAgendamentoVistoria).format("DD/MM/YYYY HH:mm");
                }else{
                    return '';
                }
                
            },            

            removerconfirmarFaturamento(row){
                row.faturado = false;                
            },  
            
            confirmarFaturamento(row){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalFaturamentoParcialPosVenda,
                    props: {
                        row: row                      
                    },
                    events: {                        
                        removerconfirmarFaturamento: this.removerconfirmarFaturamento,
                        loadAsyncData: this.loadAsyncData,
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen:true
                })
            }, 
            
            confirmarRemocaoFaturamento(posvenda){
                this.$buefy.dialog.confirm({
                    message: this.$t('GUIALIST.CONFIRMARCANCELAMENTOFATURAMENTO'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        posvenda.faturado = false; 
                        
                        const params = [];                    
                        params.push(`id=${posvenda.id}`);                                                                                    
                    
                        this.$http.get(`/api/posvenda/CancelarFaturamento?${params.join('&')}`)
                        .then((res) => {
                            return true;
                        }).finally(() => {                                                            
                            this.loadAsyncData();
                        });                         
                        
                        
                    },
                    onCancel: () => {                        
                        posvenda.faturado = true;   
                    },
                });
            },             
                   
            
            confirmarAlteracaoStatus(item){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlterarStatusGuia,
                    props: {
                        item: item                      
                    },
                    events: {                                                
                        loadAsyncData: this.loadAsyncData,
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },      

            encontrarCliente(processo,etapa,nomeCliente){
                if(processo=="Atendimento"){

                    if(etapa=="Em negociação" || etapa=="Atendimento em espera"){
                        this.tipo='negociacao';
                        this.codigoExterno = null;
                        this.statusFiltro = null;
                        this.StatustipoVenda = null;
                        this.vendedorFiltro= null;
                        this.datas= [ null,null];  
                        this.procurar = nomeCliente;
                        this.carregarStatus();
                        this.carregarVendedoresUnidade();
                        this.carregarConferentesUnidade();            
                        this.loadAsyncData();                        

                    }else{
                        this.$router.push({ name: 'atendimento', params: { queryCliente: nomeCliente}})
                    }

                }else if(processo=="Vendido"){

                    if(etapa=='Entrega Futura'){                                                
                        this.tipo='entregafutura';
                        this.codigoExterno = null;
                        this.statusFiltro = null;
                        this.StatustipoVenda = null;
                        this.vendedorFiltro= null;
                        this.datas= [ null,null];  
                        this.procurar = nomeCliente;
                        this.carregarStatus();
                        this.carregarVendedoresUnidade();
                        this.carregarConferentesUnidade();            
                        this.loadAsyncData();
                    }else{                    
                        this.$router.push({ name: 'venda', params: { queryCliente: nomeCliente}})
                    }
                }else if (processo=="Pós venda"){

                    if(etapa=='Vistoria'){
                        this.tipo='vistoria';
                        this.codigoExterno = null;
                        this.statusFiltro = null;
                        this.StatustipoVenda = null;
                        this.vendedorFiltro= null;
                        this.datas= [ null,null];  
                        this.procurar = nomeCliente;
                        this.carregarStatus();
                        this.carregarVendedoresUnidade();
                        this.carregarConferentesUnidade();            
                        this.loadAsyncData();                        

                    }else if( etapa == 'Instalações Futuras'){
                        this.tipo='instalacaofutura';
                        this.codigoExterno = null;
                        this.statusFiltro = null;
                        this.StatustipoVenda = null;
                        this.vendedorFiltro= null;
                        this.datas= [ null,null];  
                        this.procurar = nomeCliente;
                        this.carregarStatus();
                        this.carregarVendedoresUnidade();
                        this.carregarConferentesUnidade();            
                        this.loadAsyncData();
                    }else{
                        this.$router.push({ name: 'posvenda', params: { queryCliente: nomeCliente}})
                    }
                    
                }
            },
            verStatusAtual(guia,mensagemBloqueio){  

                this.$http.get(`/api/atendimento/VerStatusAtual?id=${guia.id}`)
                .then(res => res.data)
                .then(data => {

                    this.$buefy.modal.open({
                        parent: this,
                        component: modalVerStatusAtualGuia,
                        props: {
                            processo: data.processo,
                            etapa: data.etapa,
                            clienteNome: guia.clienteNome                      
                        },
                        events: {                                                
                            encontrarCliente: this.encontrarCliente,
                        },
                        hasModalCard: true,
                        trapFocus: true
                    })                    

     
                });                             
                return;                
                
            },       

            alterarTemperatura(guia){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlteracaoTemperaturaGuia,
                    props: {
                        id: guia.id                        
                    },
                    events: {
                        loadData: this.loadAsyncData                    
                    },
                    hasModalCard: false,
                    trapFocus: true
                }) 
            },
            atualizarValorEfinance(guia){
                this.salvandoDados=true;
                const params = [];                                                                                                                      
                params.push(`guiaId=${guia.id}`);  
                params.push(`unidadeId=${this.unidadeId}`);  
                params.push(`clienteId=${guia.clienteCodigoExterno}`); 
                

                this.$buefy.dialog.confirm({
                title: 'Confirmar',
                message: this.$t('GUIALIST.CONFIRMARATUALIZARVALOREFINANCE'),
                type: 'is-warning',
                hasIcon: true,
                cancelText: 'Não',
                confirmText: 'Sim',
                onConfirm: () => {
                                                                                                                 
                                this.$http.get(`/api/integracao/AtualizarValorEfinance?${params.join('&')}`)                        
                                .then(res => res.data)
                                .then(data => {                                                
                                    this.salvandoDados=false;
                                }).finally(() => {                                
                                    this.salvandoDados = false;    
                                    this.$buefy.toast.open({
                                        duration: 10000,
                                        message: "Valor do Efinance atualizado com sucesso",
                                        type: 'is-success',
                                        queue: false
                                    });                                                                                                 
                                    this.loadAsyncData();                          
                                    
                                }).catch((error) => {    

                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: "Ocorreu um erro na integração com Elist!",
                                        type: 'is-danger',
                                        queue: false
                                    });                                                    
                                    throw error;

                                });                               
                              
                    }
                });                

               

            },                   
            retornaClassePercentual(guia){

                return "is-size-7 no-wrap " + guia.valorPercentualCor;

            },
            
            alterarValorPrevisto(guia){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlteracaoValorPrevistoGuia,
                    props: {
                        id: guia.id,
                        clienteId: guia.clienteCodigoExterno                        
                    },
                    events: {
                        loadData: this.loadAsyncData                    
                    },
                    hasModalCard: false,
                    trapFocus: true
                })                
                
     
            },              
            
            confirmarPrevisaoInstalacaoPosVenda(posvenda){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalPrevisaoInstalacaoPosVenda,
                    props: {
                        id: posvenda.id                        
                    },
                    events: {
                        loadData: this.loadAsyncData                    
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },   

            confirmarPrevisaoEntregaPosVenda(posvenda){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalPrevisaoEntregaPosVenda,
                    props: {
                        id: posvenda.id                        
                    },
                    events: {
                        loadData: this.loadAsyncData                    
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },               
                                    
            
            confirmarPrimeiroContatoVistoriaPosVenda(posvenda){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalPrimeiroContatoVistoriaPosVenda,
                    props: {
                        id: posvenda.id                        
                    },
                    events: {
                        loadData: this.loadAsyncData                    
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },   
                        

            alterarPrazoFabrica2(posvenda){                
                this.$buefy.dialog.prompt({
                    title: this.$t('GUIALIST.PRAZOFABRICA'),                    
                    inputAttrs: {
                        type: 'date',                        
                        placeholder: 'Prazo',
                        value: posvenda.dataHoraPrazoFabrica2
                    }, 
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.$http.get(`/api/posvenda/SalvarPrazoFabrica2?prazo=${moment(res).format('YYYY-MM-DDTHH:mm:ss')}&id=${posvenda.id}`)
                        .then((r) => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t("SISTEMA.SALVOSUCESSO"),
                                type: 'is-success'
                            });
                            posvenda.dataHoraPrazoFabrica2 = res;
                        }, error => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Não foi possível salvar a data",
                                type: 'is-danger'
                            });
                            posvenda.dataHoraPrazoFabrica2 = null;
                        }
                    )}
                });                
                return true;
            },            
            alterarPrazoFabrica(posvenda){                
                this.$buefy.dialog.prompt({
                    title: this.$t('GUIALIST.PRAZOFABRICA'),                    
                    inputAttrs: {
                        type: 'date',                        
                        placeholder: 'Prazo',
                        value: posvenda.dataHoraPrazoFabrica
                    }, 
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.$http.get(`/api/posvenda/SalvarPrazoFabrica?prazo=${moment(res).format('YYYY-MM-DDTHH:mm:ss')}&id=${posvenda.id}`)
                        .then((r) => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t("SISTEMA.SALVOSUCESSO"),
                                type: 'is-success'
                            });
                            posvenda.dataHoraPrazoFabrica = res;
                            this.loadAsyncData();
                        }, error => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Não foi possível salvar a data",
                                type: 'is-danger'
                            });
                            posvenda.dataHoraPrazoFabrica = null;
                        }
                    )}
                });                
                return true;
            },
            retornarTitleStack(){
                if(this.tipo == "negociacao" ){
                    return this.$t('GUIALIST.EMNEGOCIACAO');
                }else if(this.tipo == "entregafutura" ){
                    return this.$t('GUIALIST.ENTREGAFUTURA');                    
                }else if(this.tipo == "instalacaofutura" ){
                    return this.$t('GUIALIST.INSTALACAOFUTURA');                                                        
                }else if(this.tipo == "vistoria" ){
                    return this.$t('POSVENDAS.VISTORIA');                                        
                }else{                                        
                    return  this.$t('GUIALIST.GUIA');
                }
            },
            verificaAtraso(row){
                let classe = "";

                let temperatura="";

                if (row.temperatura == 1){
                    temperatura = " frio";
                }else if (row.temperatura == 2){
                    temperatura = " morno";
                }else if (row.temperatura == 3){
                    temperatura = " quente";
                }


                if(row.inside)
                    classe = 'has-background-dark has-text-white ';

                if(row.atraso)
                    classe = 'bg-atraso ';

                return classe + temperatura;

            },
            isVendedor(){                
                return this.usuario.nivel == 'Vendedor' || this.usuario.nivel.includes('Vendedor');
            },      
            isConferente(){                
                return this.usuario.nivel == 'Conferente';
            },             
            
            exportarResultadosExcel(){

                const params = this.montaParametros();
                                     
                                                                                                                                                                                                                                                            
                window.open(`/api/atendimento/ProcurarXLS?${params.join('&')}`,'_blank');
                
                
            },                

            exportarResultadosPdf(){

                const params = this.montaParametros();
                
                const url = `/api/atendimento/ProcurarEmNegociacaoPDF?${params.join('&')}`;
                
                window.open(url, "_blank");
            },            
                     
            loadAsyncData: debounce(function () {

                const params = this.montaParametros();
                    
                this.loading = true;

                let url = `/api/atendimento/procurar?${params.join('&')}`;

                if(this.tipo == "negociacao" ){
                    url= `/api/atendimento/ProcurarEmNegociacao?${params.join('&')}`;
                }else if (this.tipo == "entregafutura" ){
                    url= `/api/atendimento/ProcurarEntregaFutura?${params.join('&')}`;
                }else if (this.tipo == "instalacaofutura" ){
                    url= `/api/posvenda/ProcurarInstalacaoFutura?${params.join('&')}`;
                }else if (this.tipo == "vistoria" ){
                    url= `/api/posvenda/ProcurarVistoria?${params.join('&')}`;
                }else if (this.tipo == "conferenciasexecucao"){
                    url= `/api/venda/ProcurarConferenciaExecucao?${params.join('&')}`;
                }                
                    

                this.$http.get(url)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }
                        this.total = this.perPage * data.pageCount;
                        this.loading = false;
                        this.$router.replace({query: {procurar: this.procurar, page: this.page, tipo: this.tipo }});
                        this.filtroTodos = false; 
                        this.filtroTodosAtrasados = false;                            
          
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    }).finally(() => {                        
                        this.clienteId = null;
                    }); 
                                       
            }, 500),

            montaParametros(){
                const params = [                                        
                    `unidadeId=${this.unidadeId}`,                                        
                    `page=${this.page}`
                ];

                if(this.ordenarPor && this.ordenar)
                    params.push(`ordem=${this.ordenarPor}.${this.ordenar}`);                    

                if (this.procurar != null && this.procurar.length>=3) {                    
                    params.push(`nome=${this.procurar}`);                    
                }else if(this.procurarVendedor !=null && this.procurarVendedor.length>=3){                    
                    params.push(`nomeVendedor=${this.procurarVendedor}`);
                }
          
                if (this.clienteId != null) 
                    params.push(`clienteId=${this.clienteId}`);
                
                if (this.id != null) 
                    params.push(`id=${this.id}`);

                if (this.codigoExterno != null) 
                    params.push(`codigoExterno=${this.codigoExterno}`);          
                
                if (this.statusFiltro != null) 
                    params.push(`status=${this.statusFiltro}`);  

                if (this.StatustipoVenda != null) 
                    params.push(`tipo=${this.StatustipoVenda}`);  
                
                if (this.ordenarPorVistoria != null) 
                    params.push(`ordenarPorVistoria=${this.ordenarPorVistoria}`);                      
                                    
                if(this.isVendedor() ){
                    params.push(`comercialId=${this.usuario.funcionarioId}`);
                }else if (this.vendedorFiltro !=null){
                    params.push(`comercialId=${this.vendedorFiltro}`);
                }

                if (this.temperaturaFiltro !=null){
                    params.push(`temperatura=${this.temperaturaFiltro}`);
                }                

                if(this.isConferente() ){
                    params.push(`conferenteId=${this.usuario.funcionarioId}`);                
                }else if (this.conferenteFiltro !=null){
                    params.push(`conferenteId=${this.conferenteFiltro}`);
                }                

                if(this.tipo == 'todos' || this.tipo == 'vistoria' || this.tipo == 'instalacaofutura'  ){
                    if(this.dataFiltro)
                        params.push(`dataFiltro=${this.dataFiltro}`);

                    if(this.datas[0])
                        params.push(`dataInicial=${this.$moment(this.datas[0]).format('YYYY-MM-DDTHH:mm:ss')}`);                        

                    if(this.datas[1])
                        params.push(`dataFinal=${this.$moment(this.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`); 
                    
                    params.push(`somenteAtrasados=${this.filtroTodosAtrasados}`); 
                                            
                }

                return params;
            }  ,         
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPorVistoria=null;
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
  
            abrirCadastroInput() {
                //verifica se existe a guia
                if(this.id != null){
                    this.$http.get('/api/atendimento/edit?id=' + this.id)
                    .then(() => {
                        this.$router.push({
                            name: 'guia', params: {
                                id: this.id
                            }
                        })
                    })
                    .catch(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: this.$t('ATENDIMENTO.GUIA') + this.id + this.$t('ATENDIMENTO.NAOENCONTRADA'),
                            type: 'is-danger',
                            position: 'is-top-left',
                            queue: false
                        })
                    })
                }
            },
            carregarStatus(){

                if(this.tipo=='vistoria'){                    
                        this.dataFiltro ='previsaoinstalacao';                     
                }
                

                if(this.tipo=='instalacaofutura'){
                    this.$http.get('/api/search/ListarTiposVenda')
                    .then(res => res.data)
                    .then(data => {
                        if (data != null) {
                            this.StatusTipoVenda = data;
                        }
                    });                    

                }else{
                    this.$http.get('/api/search/ListarStatusGuiaFiltro')
                    .then(res => res.data)
                    .then(data => {
                        if (data != null) {
                            this.StatusGuia = data;
                        }
                    });
                }

            },
            carregarConferentesUnidade(){

                this.$http.get('/api/search/FuncionarioConferente')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.listaconferentesUnidade = data;
                    }
                });
                
            },                 
            carregarVendedoresUnidade(){


                this.$http.get('/api/search/FuncionarioComercial')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.listaVendedoresUnidade = data;
                    }
                });
                

            }            

                        
        },

            
        async mounted() {
            await this.carregarStatus();
            await this.carregarVendedoresUnidade();
            await this.carregarConferentesUnidade();            
            await this.loadAsyncData();
            
        }
    }
</script>