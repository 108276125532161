<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">            
            <p  class="modal-card-title">{{$t('MODALCONFIRMARPROJETOVENDEDOR.CONFIRMARPROJETO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                                
                <p >{{$t('MODALCONFIRMARPROJETOVENDEDOR.AVISO')}}</p>
            </b-notification>            
            <div class="panel">

                <p v-if="!alteracaoProjeto" class="panel-block">
                    <b-field :label="$t('MODALCONFIRMARPROJETOVENDEDOR.DATAPREVISTA')">
                    </b-field>
                </p>
                <div v-if="!alteracaoProjeto" class="panel-block">                    
                    <b-datetimepicker ref="data"                                        
                                    :placeholder="$t('AGENDAMENTO.SELECIONARHORA')"
                                    v-model="datahorabloqueado"
                                    icon="calendar-today"         
                                    :min-datetime="new Date()"                           
                                    :timepicker="{ enableSeconds: false, hourFormat: '24' }"
                                    horizontal-time-picker>
                    </b-datetimepicker>                                                                         
                </div>                    
                        
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            id: Number,
            alteracaoProjeto: Boolean,                                                                       
        },
        data() {
            return {                
              
                observacoes: null,
                salvandoDados :false,
                datahorabloqueado: null                
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            return true;
        },
        methods: {

            async confirmar() {
                                                                                                                                       
                    if(this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20)){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                    }     

                    
                    if(!this.alteracaoProjeto && this.datahorabloqueado ==null ){
                        return true;
                    }                    
                                                            
                    this.salvandoDados=true;
                    const params = [];                    
                    if (this.id) params.push(`id=${this.id}`);                                                                                                
                    if(this.observacoes) params.push(`observacoes=${this.observacoes}`);                                                            
                    
                    if(!this.alteracaoProjeto)
                        params.push(`datahorabloqueado=${this.$moment(this.datahorabloqueado).format('YYYY-MM-DDTHH:mm:ss')}`);      
                                        
                    try {                            
                        this.$http.get(`/api/atendimento/confirmarProjetoVendedor?${params.join('&')}`)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.isLoading = false;    
                            this.$emit('loadData')                            
                            this.$emit('close');                                                              
                        });                             
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    } 
                                                                           

            }
            

        }
    }
</script>