<template>
    <div class="panel">
        <div class="panel-heading">
            {{$t('FILTROS.FILTRO')}}
        </div>
        <div class="panel-block columns is-multiline">

            <div v-if="habilitarFiltroCodigo()" class="column is-6 is-full-mobile">
                <label  class="label">{{$t('FILTROS.CODIGO')}}</label>
                <b-input  :placeholder="$t('FILTROS.CODIGO')" v-model="filtros.codigo" type="number" icon="magnify" ></b-input>
            </div>              
            <div v-if="habilitarFiltroNome()" class="column is-6 is-full-mobile">
                <label  class="label">{{$t('FILTROS.NOME')}}</label>
                <b-input  :placeholder="$t('FILTROS.NOME')" v-model="filtros.nome" icon="magnify" ></b-input>
            </div>            
            <div  v-if="habilitarFiltroFuncionario()"   class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.funcionario.id" :item.sync="filtros.funcionario" table="FuncionariosUnidade" :camposAdicionais="'&unidadeId='+unidadeId" :label="$t('SISTEMA.FUNCIONARIO')"/>
            </div>
            <div  v-if="habilitarFiltroUnidade()"  class="column is-6 is-full-mobile">
                <searchIdName :id.sync="filtros.unidade.id" :item.sync="filtros.unidade" table="UnidadeFuncionario" :camposAdicionais="'&funcionarioId='+usuario.funcionarioId"  :label="$t('SISTEMA.UNIDADE')"/>
            </div>

            <div v-if="habilitarFiltroStatus()" class="column is-6 is-full-mobile">
                <label  class="label">{{$t('FILTROS.STATUS')}}</label>
                <div  class="control" style="width:100%">
                    <span class="select" style="width:100%">

                        <select :placeholder="$t('FILTROS.STATUS')" v-model="filtros.status" style="width:100%;">
                        <option v-for="(item,index) in listaStatus" :key="index"
                                :value="item.id">
                            {{ item.descricao }}
                        </option>
                        </select>
                    </span>
                </div>
            </div>

            <div v-if="habilitarFiltroData()"  class="column is-6 is-full-mobile">

                <label class="label">{{$t('FILTROS.PERIODO')}}</label>
                <div class="panel">

                    <p class="panel-tabs">
                        <a :class="{ 'is-active': filtros.dataFiltro == 'criacao' }" @click="filtros.dataFiltro = 'criacao'">{{$t('FILTROS.DATACRIACAO')}}</a>
                    </p>

                    <div class="panel-block">
                        <periodoHora :dataInicial.sync="filtros.datas[0]"
                                :dataFinal.sync="filtros.datas[1]"/>
                    </div>

                </div>
            </div>
               

        </div>
        <div  class="panel-block">
            <b-button icon-left="magnify" type="is-primary" :loading="loading"
             @click="filtros.exportarPdf=false; carregarResultados()">{{$t('SISTEMA.PROCURAR')}}</b-button>                       

            <b-button icon-left="file-excel" class="is-info center ml-1" v-if="exportExcel" :title="$t('SISTEMA.EXPORTAR')"
                @click.prevent.stop="exportarResultados()">{{$t('SISTEMA.EXPORTAR')}}</b-button>    

            <b-button type="is-danger" class="ml-1" native-type="button" icon-left="close-circle" 
            @click="$router.back();"> {{$t('SISTEMA.CANCELAR')}}</b-button>                         
        </div>
    </div>
</template>

<style scoped>
    hr{
        margin: 0.5rem 0  !important;
    }
</style>

<script>
    import searchIdName from '@/components/searchIdName.vue'
    import periodoHora from '@/components/periodoHora.vue'
    import { mapGetters,mapState } from 'vuex'

    export default {
        components: {
            searchIdName,
            periodoHora
        },
        props: {            
            tipo: String,
            listaStatus: Array, 
            exportExcel: {
                type: Boolean,
                default: true
            }                     
        },
        data() {
            return {
                filtros: {
                    dataFiltro: 'criacao',
                    status: 'todos',                    
                    funcionario: {
                        id: null
                    },
                    unidade: {
                        id: null
                    },
                    datas: [
                        this.$moment().subtract(7, 'days').toDate(),
                        this.$moment().toDate()
                    ]
                },
                loading: false                
            };
        },
        mounted() {
            return true;            
        },
        computed: {
            ...mapGetters([
                'isInRole',                
            ]),
            ...mapState([
                'unidadeId',
                'config',                                             
                'usuario', 
            ]),            
        },
        methods: {
            habilitarFiltroCodigo(){
                const habilitarRels = [];
                return ( habilitarRels.includes(this.tipo));
            },            
            habilitarFiltroNome(){
                const habilitarRels = ["clientes","funcionarios","desistencias","tempoesperafluxo"];
                return ( habilitarRels.includes(this.tipo));
            },
            habilitarFiltroUnidade(){
                const habilitarRels = ["clientes","notificacao","funcionarios","inatividade","anexos","desistencias","tempoesperafluxo"];
                return (this.usuario.administrador && habilitarRels.includes(this.tipo));
            },
            habilitarFiltroStatus(){
                const habilitarRels = ["notificacao"];
                return (habilitarRels.includes(this.tipo));
            },   
            habilitarFiltroFuncionario(){
                const habilitarRels =["logs","notificacao","inatividade"];
                return (habilitarRels.includes(this.tipo));
            },                     
            habilitarFiltroData(){
                const habilitarRels =["logs","notificacao","desistencias"];
                return (habilitarRels.includes(this.tipo));
            },                                 
            carregarResultados(){
                this.$emit('filtrar', this.filtros);
                
            }, 
            exportarResultados(){
                this.$emit('exportar', this.filtros);
            }                                 
        }
    }

</script>