<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALPRAZOENTREGAELETRO.PRAZOENTREGAELETRO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALPRAZOENTREGAELETRO.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-select expanded v-model="motivoSelecionado">
                        <option v-for="(motivo,index) in listaMotivos" :key="index"
                                :value="motivo.descricao">
                            {{ motivo.descricao }}
                        </option>
                    </b-select>                   
                    
                </div>

                <p class="panel-block">
                    <b-field :label="$t('MODALPRAZOENTREGAELETRO.NOVOPRAZO')">
                    </b-field>
                </p>
                <div class="panel-block">                    
                    <b-datetimepicker ref="data"                                        
                                    :placeholder="$t('AGENDAMENTO.SELECIONARHORA')"
                                    v-model="datahorabloqueado"
                                    icon="calendar-today"      
                                    :min-datetime="new Date()"                              
                                    :timepicker="{ enableSeconds: false, hourFormat: '24' }"
                                    horizontal-time-picker>
                    </b-datetimepicker>                                                                         
                </div>                  
                
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close');  $emit('removerPrazoEntregaEletro');">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            id: Number,                                                               
        },
        data() {
            return {                
                listaMotivos: [],
                motivoSelecionado: null,                
                observacoes: null,
                salvandoDados :false,
                datahorabloqueado: null
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            this.loadlistaMotivos()
        },
        methods: {
            loadlistaMotivos() {

                const params = [
                    `unidadeId=${this.unidadeId}`,                                        
                ].join('&');
            
                this.$http.get(`/api/search/MotivosPrazoEntregaEletros?${params}`)
                    .then(m => {
                        this.listaMotivos = m.data
                    })
            },
            async confirmar() {

                if(this.motivoSelecionado!=null && this.datahorabloqueado !=null ){

                    if(this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20)){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                    }
                                        
                    this.salvandoDados=true;
                    const params = [];                    
                    if (this.id) params.push(`id=${this.id}`);                                                            
                    params.push(`datahorabloqueado=${this.$moment(this.datahorabloqueado).format('YYYY-MM-DDTHH:mm:ss')}`);                                        
                    params.push(`motivo=${this.motivoSelecionado}`);     
                    if(this.observacoes) params.push(`observacoes=${this.observacoes}`);
                                        
                    try {                            
                        this.$http.get(`/api/venda/PrazoEntregaEletroVenda?${params.join('&')}`)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.isLoading = false;    
                            this.$emit('loadData')                            
                            this.$emit('close'); 
                            this.$router.back()                                    
                        });                             
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }
                }

            },
            

        }
    }
</script>