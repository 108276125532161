<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALEDITARANEXO.AVISO')}}</p>
        </header>        
        <section class="modal-card-body is-paddingless">
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.NOME')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input type="text" :disabled="true" v-model="anexo.fileName"></b-input>
                    <b-field class="file is-primary" :class="{'has-name': !!file}">
                        <b-upload
                        accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"
                            v-model="file" class="file-label">
                            
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload"></b-icon>                                                                                        
                                    <span class="file-label">Alterar Aquivo?</span>
                                </span>                                            
                            <span class="file-name" v-if="file">
                                {{ file.name }}
                            </span>
                        </b-upload>
                    </b-field>                      
                </div>   
                <p v-if="file" class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div v-if="file" class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                      

                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.TIPO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-select expanded v-model="anexo.tipoAnexoId">
                        <option v-for="(tipo,index) in listaTiposAnexo" :key="index"
                                :value="tipo.id">
                            {{ tipo.nome }}
                        </option>
                    </b-select>                                         
                    
                </div>
                                         
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            anexo: Object,                                                               
        },
        data() {
            return {                
                salvandoDados :false,
                listaTiposAnexo : [],
                file: null,
                observacoes:null
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            this.loadListaTiposAnexos()
        },
        methods: {
            loadListaTiposAnexos() {

                const params = [
                    `unidadeId=${this.unidadeId}`,                                        
                ].join('&');
            
                this.$http.get(`/api/search/ListarTiposAnexos?${params}`)
                    .then(m => {
                        this.listaTiposAnexo = m.data
                    })
            },
            async confirmar() {


                const formData = new FormData()
                formData.append('editarAnexo.anexoId', this.anexo.id);

                if(this.file){

                    if(this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20)){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                    }

                    formData.append('file', this.file);
                    formData.append('editarAnexo.observacoes', this.observacoes);
                    formData.append('type', 'guia');
                    formData.append('anexo', this.file, this.file.name);
                }

                if(this.anexo.tipoAnexoId !=null){
                    
                    formData.append('editarAnexo.tipoAnexo', this.anexo.tipoAnexoId);
                }


                try {                                                                  
                        this.$http.post('/api/atendimento/EditarAnexoGuia', formData)
                            .then((res) => {
                                if (res.body.erro) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: res.body.erro,
                                        type: 'is-danger'
                                    })
                                } else {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: "Anexo atualizado com sucesso !",
                                        type: 'is-success',
                                        queue: false
                                    });   
                                }
                            }).finally(() => {                                
                                this.isLoading = false;    
                                this.$emit('loadData')                            
                                this.$emit('close');  
                            });                         
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }                
                    


            },
            

        }
    }
</script>