<template>
    <div class="column is-12" >
        <div v-for="(evento,index) in eventos" :key="index" :class="tipoEventoStyle(evento)"  >
            <div class="column is-2 has-text-centered">
                <b :class="{ 'is-size-7  is-paddingless' : (tipoVisao=='mes') , 'is-size-6 is-paddingless is-size-7-mobile':  (tipoVisao=='dia') }" >{{evento.hour}}</b>
                <b-icon v-if="evento.tipoAgendamentoCor"
                        :style="tipoAgendamentoStyle(evento)"    
                        size="is-small"                                            
                        icon="square-rounded"></b-icon>
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && isEncaixe(evento)" :title="$t('AGENDA.CLIENTEENCAIXE')" class="has-text-black">&crarr;</b> 
                
                                       
            </div>
            <div class="column is-10" style="margin-left: 5px;">

                <span class="is-pulled-right">

                    <b-button class="is-pulled-right" position="is-bottom-left"  v-show="!isBloqueio(evento) && !isAgendado(evento)"   :title="$t('AGENDA.BLOQUEARHORARIO')" 
                        icon-left="cancel" type="is-black"   @click="bloquearHorario(evento)" ></b-button>                                                          
                    <b-button class="is-pulled-right" position="is-bottom-left" v-show="isBloqueio(evento)"  
                        :title="$t('AGENDA.DESBLOQUEARHORARIO')" icon-left="lock-open"  @click="desbloquearHorario(evento.id)"   ></b-button> 
                    <b-button class="is-pulled-right" position="is-bottom-left" v-show="!isBloqueio(evento) && !isAgendado(evento)" :title="$t('AGENDA.INCLUIRCLIENTE')"  
                        icon-left="account-plus" @click="incluirNovoHorarioDia(evento, evento.dataHora)"></b-button>                                

                        <b-dropdown
                            v-show=" (isAgendado(evento) && evento.unidadeId == unidadeId  && !isVendedor() )
                            || ( isAgendado(evento) && evento.unidadeId == unidadeId  && isVendedor() & evento.funcionarioResponsavelId == usuario.funcionarioId) "
                            append-to-body                    
                            position="is-bottom-left"                        
                            aria-role="list"
                            trap-focus >
                            <template #trigger="{ active }">
                                <b-button tag="a" :icon-right="tipoIcone(active)" />                        
                            </template>
                                                    
                            <b-dropdown-item aria-role="listitem" v-if="guia && evento.guiaId == 0 && evento.nome ==null"  @click="vincularAtendimento(evento)">{{$t('AGENDA.VINCULARATENDIMENTO')}} </b-dropdown-item>
                            <b-dropdown-item aria-role="listitem"  @click="incluirNovoHorarioDia(evento, evento.dataHora)">{{$t('SISTEMA.EDITAR')}}</b-dropdown-item>                            
                            <b-dropdown-item aria-role="listitem"  @click="confirmarCancelamento(evento)">{{$t('SISTEMA.CANCELAR')}} </b-dropdown-item>                                                                                
                            <hr class="dropdown-divider" aria-role="menuitem">                    
                            <b-dropdown-item aria-role="listitem"  @click="exibirHistorico(evento)"><b-icon  icon="clock" ></b-icon>{{$t('SISTEMA.HISTORICO')}} </b-dropdown-item>                                               
                        </b-dropdown>

                </span>
                
                <div class="is-flex is-justify-content-space-between mediaScreen-column">
                    <div>
                        <p  :class="{ 'is-size-7 text-nowrap' : (tipoVisao=='mes') , 'is-size-5 is-size-7-mobile':  (tipoVisao=='dia') }" >{{tratarNomeCliente(evento)}}</p>
                        <p v-if="evento.funcionarioResponsavelNome"  :class="{ 'is-size-7 text-nowrap' : (tipoVisao=='mes') , 'is-size-5 is-size-7-mobile':  (tipoVisao=='dia') }">
                            <b>{{tratarNome(evento.funcionarioResponsavelNome)}}</b>
                        </p>       
                    </div>
                    <div v-if="tipoVisao=='dia'" class="is-flex size-5 is-size-7-mobile mediaScreen-start"> 
                        <p class="is-flex is-flex-direction-column mr-2 is-full-mobile" >
                            <span>{{(evento.clienteCelular) ? evento.clienteCelular : evento.clienteFone}}</span>
                            <span>{{evento.convenioNome}}</span>                            
                        </p>
                        <p class="is-flex is-flex-direction-column mr-2">                            
                            <span>{{evento.funcionarioNome}}</span>
                        </p>                 
                    </div>
                </div>                
                
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    
    .text-nowrap{
        text-overflow: ellipsis;
        white-space: nowrap;        
        overflow: hidden;        
        max-width: 10rem;  
    }

    @media screen and (min-width: 769px){
        .mediaScreen-start{
            justify-content: flex-end;
        }
    }

    @media screen and (max-width: 769px){
        .mediaScreen-column{
            flex-direction: column;
        }
    }

</style>

<script>

import moment from 'moment';
import {  mapState } from 'vuex'
import historicoAlteracoes from "@/components/historicoAlteracoes.vue";
import modalCancelandoAgenda from '@/components/atendimento/modalCancelandoAgenda'

export default {
    props: {
        eventos: Array,
        tipoVisao: String,
        guia: {
            type: Object,
            default: null
        }               
    },

        computed: {
            ...mapState([                                
                'unidadeId',
                'usuario'
            ]),  
        },    
    methods: {

        isVendedor(){                
                return this.usuario.nivel == 'Vendedor' || this.usuario.nivel.includes('Vendedor');
            },          
        vincularAtendimento(evento){

            this.$buefy.dialog.confirm({
                title: 'Confirmar',
                message: this.$t('AGENDA.CONFIRMAVINCULARATENDIMENTO'),
                type: 'is-info',
                hasIcon: true,
                cancelText: 'Não',
                confirmText: 'Sim',
                onConfirm: () => {
                
                    const params = [];
                    params.push(`agendaId=${evento.id}`);
                    params.push(`guiaId=${this.guia.id}`);

                    const res =  this.$http.post(`/api/agenda/AgendaVincular?${params.join('&')}`)
                            .then(res => res.data)
                            .then(data => {  
                                this.$emit('cancelarReagendamento');   
                                this.$emit('loadData');                                                                                            
                            }); 
                }
            })

        },
        exibirHistorico(item) {
            this.$buefy.modal.open({
                parent: this,
                component: historicoAlteracoes,
                props: {
                    tabela: '',
                    chave: item.id,
                    toggle: true,
                    agendaLog: true,
                },
                hasModalCard: true,
                trapFocus: true
            })
        },          
        tipoIcone(active){
            
            if(this.tipoVisao =="mes"){
                return active ? 'menu-up' : 'menu-down';
            }else{
                return 'format-list-bulleted';
            }

        },
        tipoAgendamentoStyle(evento) {
            return 'color: #' + evento.tipoAgendamentoCor + '!important'
        },
        tipoEventoStyle(evento) {
            let classes = 'box columns is-mobile is-gapless is-paddingless';
            
            if(this.isBloqueio(evento))
                classes += ' has-text-light has-background-dark';

            if(this.isAgendado(evento) && this.tipoVisao=="dia")
                classes += ' has-background-info has-text-light';
                
            return classes;                

        },    
  
        tratarNomeCliente(evento){

            if(evento.id>0 && !evento.clienteNome && !evento.nome){
                return this.$t('AGENDA.HORARIOBLOQUEADO');
            }else if(this.isVendedor() && evento.tipoAgendamentoRestricaoVendedor && evento.funcionarioResponsavelId != this.usuario.funcionarioId){
                return 'Reservado';
            }else if(evento.clienteNome){
                return evento.clienteNome.toLowerCase();
            }else if(evento.nome){
                return evento.nome.toLowerCase();
            }
            
        },
        tratarNome(nome){            
            if(!nome){
                return null;
            }
            return nome.toLowerCase();                        
        },
        
        isBloqueio(evento){              
            
            return (evento.id && !evento.clienteNome && !evento.nome && evento.observacao=='Bloqueio');                
        }, 
        isAgendado(evento){              
            
            return ((evento.clienteNome || evento.nome) );                
        },  
        isConfirmado(evento){              
            
            return (evento.clienteConfirmado);                
        },  
        isEncaixe(evento){              
            
            return (evento.encaixe);                
        },                          
        desbloquearHorario(id){                
            const evento = {id: id};
            this.agendaStatus(evento, 'cancelar');
        },            
        bloquearHorario(evento){            
            const bloqueio = {
                data: evento.dataHora,
                observacao: 'Bloqueio',
                idcliente: 0,
                idTipoAgendamento: evento.tipoAgendamentoId,
                funcionarioId: null,
                cliente: { id: 0 },
                tipoAgendamento: { id: evento.tipoAgendamentoId},
            }

            this.adicionarAgendamento(bloqueio);
        }, 
        confirmarCliente(evento){
            this.$buefy.dialog.confirm({
                title: 'Confirmar',
                message: this.$t('AGENDA.CONFIRMACLIENTE'),
                type: 'is-info',
                hasIcon: true,
                cancelText: 'Não',
                confirmText: 'Sim',
                onConfirm: () => {
                    this.agendaStatus(evento,'confirmar')
                }
            })
        },                   
        confirmarCancelamento(evento){
          
            this.$buefy.modal.open({
                parent: this,
                component: modalCancelandoAgenda,
                props: {
                    id: evento.id
                },
                events: {
                    loadData: this.loadDataAgendaItem
                },
                hasModalCard: true,
                trapFocus: true
            })

        },

        loadDataAgendaItem(){
            this.$emit('loadData');
            this.$emit('loadDataDia')
        },
         verGuiasCliente(evento){
             this.$router.push({ name: 'guia-list', query: { clienteId: evento.clienteId } })
         },
         abrirImprimir(evento){            
            this.$emit("abrirImprimir", { id: evento.id });
         },
         incluirNovoHorarioDia(evento,dataHora){             
            this.$emit("incluirNovoHorarioDia", evento,dataHora);
         },         
        async agendaStatus(evento,acao) {
            const params = [];
            if (evento.id) params.push(`id=${evento.id}`);

            params.push(`acao=${acao}`);

            try {
                const url = `/api/agenda/AgendaStatus?${params.join('&')}`;
                const res = await this.$http.get(url);
                this.$emit('loadData')
            } catch (e) {
                console.error(e);
            }

        },

        async adicionarAgendamento(evento) {
            const params = [];
            if (evento.id) params.push(`id=${evento.id}`);
            params.push(`clienteId=${evento.cliente.id}`);
            params.push(`tipoAgendamentoId=${evento.tipoAgendamento.id}`);
            if(evento.funcionarioId == null) evento.funcionarioId = 0;
            params.push(`funcionarioId=${evento.funcionarioId}`);
            params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);
            params.push(`observacao=${evento.observacao ? evento.observacao : ''}`);
            if (this.$route.query.unidadeId != null)
                params.push(`unidadeId=${this.$route.query.unidadeId}`)
            else
                params.push(`unidadeid=${this.unidadeId}`);

            try {
                let url = `/api/agenda/agendaincluir?${params.join('&')}`;

                if (evento.id) {
                    url = `/api/agenda/agendaEditar?${params.join('&')}`;
                }

                this.isLoading = true;
                const res = await this.$http.post(url)
                        .then(res => res.data)
                        .then(data => {  
                            
                            if(data.mensagem){
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message:data.mensagem,
                                    type: 'is-danger',
                                    queue: false
                                })
                            }

                            this.$emit('loadData')
                                                        
                        })                  
                
                
            } catch (e) {                    
                if (e.status === 404) {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('AGENDA.ERROAOSALVAR'),
                        type: 'is-danger',
                        queue: false
                    })
                }
                console.error(e);
                
            }

        },                          
                
    }
};
</script>