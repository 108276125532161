<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>

        <div class="columns">
            
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderObrasPlantas==2 ? orderObrasPlantas= 0 : orderObrasPlantas++; ordenar('ObrasPlantas')">
                        <span><b-icon :icon="iconeOrderObrasPlantas"  size="is-small"> </b-icon></span>
                        {{$t('POSVENDAS.OBRASPLANTAS')}} 
                        ({{listaPosVendasObrasPlantas.length}}) 
                        <span v-if="listaPosVendasObrasPlantas.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPosVendasObrasPlantas.filter(x=>x.atraso).length }}</span>                                                                    
                    </p>
                    

                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaPosVendasObrasPlantas" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>                                      
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">                                    
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span>        
                                                <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                                                                        
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                                                                                                                                                                                                                                                                             
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>                                                                         
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('posvendas-obrasplantas')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>
                                            <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                            :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                                                                                                                     
                                    </div>
                                    <br>  
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>           
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>                           
                    
                </div>
            </div>    

            <div class="column is-3">
                <div class="panel">
                    <p class="panel-heading background-2  cp" @click="orderPlantasTecnicas==2 ? orderPlantasTecnicas= 0 : orderPlantasTecnicas++; ordenar('PlantasTecnicas')">
                        <span><b-icon :icon="iconeOrderPlantasTecnicas"  size="is-small"> </b-icon></span>
                        {{$t('POSVENDAS.PLANTASINSTALACAO')}}
                        ({{listaPosVendasPlantasInstalacao.length}}) 
                        <span v-if="listaPosVendasPlantasInstalacao.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPosVendasPlantasInstalacao.filter(x=>x.atraso).length }}</span>                                                                    
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaPosVendasPlantasInstalacao" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>                                      
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">                                    
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span>      
                                                <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                                     
                                                <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}}                                                                              
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                                    
                                            </span>                                                                                                                                                                                                                                                                                             
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>                                        
                                    </b-tooltip>                                                                        
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('posvendas-plantastecnicas')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                                                                                                                     
                                        <b-button v-if="isInRole('posvendas-plantastecnicas') && item.atraso" @click="confirmarAlteracaoPrazoObraNaoConforme(item)" 
                                        class="is-small has-text-danger" title="Alterar Prazo?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                                                                    
                                    </div>
                                    <br>  
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>           
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>  
                                    
                </div>
            </div> 

            <div class="column is-3">
                <div class="panel">
                    <p class="panel-heading background-2">
                        {{$t('POSVENDAS.VISTORIA')}}
                        ({{listaPosVendasVistoria.length}}) 
                        
                    </p>
                    <p v-if="agendaHoje.length > 0" class="panel-block">
                         {{$t('ATENDIMENTO.CLIENTESAGENDADOS')}}
                    </p>
                    <p v-if="agendaHoje.length == 0" class="panel-block">
                         {{$t('ATENDIMENTO.NENHUMCLIENTEAGENDADO')}}
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable m-0">     
                        <tbody>
                            <tr v-for="(item, index) in agendaHoje" v-bind:key="index">
                                <td> 
                                    <span class="is-size-7 is-flex">
                                        <b-icon :style="tipoAgendamentoStyle(item)"  size="is-small" icon="square-rounded"></b-icon>
                                        <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                            <template v-slot:content>
                                                <b>Responsável: </b> {{item.funcionarioResponsavelNome}}
                                            </template>                                             
                                            <span class="is-size-7">{{item.tipoAgendamentoNome}} - {{item.clienteNome ?? item.nome}} </span>
                                        </b-tooltip>
                                    </span>                                    
                                </td>
                                <td> <span class="tag is-info">{{item.dataHora | moment('HH:mm')}}</span> </td>
                                <td> 
                                    <router-link v-if="item.guiaId && item.guiaId >0 " :to="{ name: 'guia', params: { id: item.guiaId, emVistoria:true}}" 
                                    class="button is-small is-primary" title="Abrir Atendimento">
                                        <b-icon icon="pencil" size="is-small"></b-icon>
                                    </router-link>  
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="" >
                        <router-link v-if="isInRole('atendimento-agendamento')"  :to="{ name: 'agenda', params: {tipo: 2,filtrarTodos:true,voltarTela: 'posvenda' } }" class="button is-link is-outlined is-fullwidth">
                            {{$t('ATENDIMENTO.ABRIRCALENDARIO')}}
                        </router-link>
                        <router-link :to="{ name: 'guia-list', query: { tipo:'vistoria' }  }" class="button is-link is-outlined is-fullwidth">
                            {{$t('POSVENDAS.VISTORIA')}} <span class="ml-1 tag is-info">{{ listaPosVendasVistoria.length }}</span> 
                            <span v-if="listaPosVendasVistoria.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPosVendasVistoria.filter(x=>x.atraso).length }}</span>                                                                     
                        </router-link>
                    </div>                     
                                    
                </div>
            </div>             

            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2">
                        {{$t('POSVENDAS.INSTALACAOFUTURAS')}}
                       <span class="is-pulled-right ">
                            <b-button v-if="isInRole('posvendas-inserircliente-etapa')"  class="is-info" size="is-small" 
                            :title="$t('MODALINSERIRCLIENTENOVOETAPA.NOVOCLIENTE')" icon-left="account-plus"
                            @click="inserirClienteNovoEtapa('instalacaofutura')"></b-button>                                                                                                            
                        </span>                        
                    </p>     
                    <div class=""  v-if="isInRole('atendimento-agendamento')" >

                        <router-link :to="{ name: 'guia-list', query: { tipo:'instalacaofutura' }  }" class="button is-link is-outlined is-fullwidth">
                            {{$t('POSVENDAS.INSTALACAOFUTURAS')}} <span class="ml-1 tag is-info">{{ listaPosVendasInstalacaoFutura.length }}</span>  
                            <span v-if="listaPosVendasInstalacaoFutura.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPosVendasInstalacaoFutura.filter(x=>x.atraso).length }}</span>                                                                                                
                        </router-link>
                    </div>
                </div>
            </div> 

  

      

        </div>

        <div class="columns">
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderEntrega==2 ? orderEntrega= 0 : orderEntrega++; ordenar('Entrega')">
                        <span><b-icon :icon="iconeOrderEntrega"  size="is-small"> </b-icon></span>
                        {{$t('POSVENDAS.ENTREGA')}} 
                        ({{listaPosVendasEntrega.length}})
                        <span v-if="listaPosVendasEntrega.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPosVendasEntrega.filter(x=>x.atraso).length }}</span>                                                                    

                        <span v-if="isInRole('atendimento-unificar')" class="is-pulled-right ">
                            <b-button v-if="!habilitaSelecionarUnificarEntrega"
                             :title="$t('POSVENDAS.UNIFICARATENDIMENTO')"  class="is-warning" 
                            icon-left="merge" size="is-small"  @click="selecionarAtendimentosUnificar('Entrega')"></b-button>      
                            
                            <b-button v-if="habilitaSelecionarUnificarEntrega" :title="$t('POSVENDAS.CONFIRMARUNIFICACAO')"  class="is-success" 
                            size="is-small"  icon-left="check"
                            @click="confirmarUnificar('Entrega')"></b-button>  

                            <b-button v-if="habilitaSelecionarUnificarEntrega" :title="$t('POSVENDAS.CANCELARCONFIRMARLIBERACAO')"  class="is-danger" 
                            size="is-small" icon-left="close"
                            @click="cancelarSelecionarUnificar('Entrega')"></b-button>                                                                           
                        </span>                         

                        <span class="is-pulled-right ">
                            <b-button v-if="!habilitaSelecionar"  class="is-info" size="is-small" 
                            @click="selecionarEntregas()">{{$t('POSVENDAS.LIBERARENTREGA')}}</b-button>      
                            
                            <b-button v-if="habilitaSelecionar" :title="$t('POSVENDAS.CONFIRMARLIBERACAO')"  class="is-success" 
                            size="is-small"  icon-left="check"
                            @click="confirmarEntregas()"></b-button>  

                            <b-button v-if="habilitaSelecionar" :title="$t('POSVENDAS.CANCELARCONFIRMARLIBERACAO')"  class="is-danger" 
                            size="is-small" icon-left="close"
                            @click="cancelarSelecionarEntregas()"></b-button>                                                                           
                        </span>
                    </p>
                    <router-link v-if="isInRole('atendimento-agendamento')"  :to="{ name: 'agenda', params: {tipo: 3,filtrarTodos:true,voltarTela: 'posvenda' } }" class="button is-link is-outlined is-fullwidth">
                            {{$t('ATENDIMENTO.ABRIRCALENDARIO')}}
                    </router-link>                                        
                    <b-datepicker size="is-small" :placeholder="$t('SISTEMA.DATAINICIAL')" v-model="dataHoraInicialEntrega"  @blur="posvendasEntrega()" icon="calendar-today"  >
                        <b-button label="Limpar" type="is-warning" icon-left="close"  @click="dataHoraInicialEntrega = null; dataHoraFinalEntrega = null;" />                        
                    </b-datepicker>                    
                    <b-datepicker size="is-small" :placeholder="$t('SISTEMA.DATAFINAL')" v-model="dataHoraFinalEntrega" @blur="posvendasEntrega()" icon="calendar-today"  >                    
                        <b-button label="Limpar" type="is-warning" icon-left="close"  @click="dataHoraInicialEntrega = null; dataHoraFinalEntrega = null;" />                        
                    </b-datepicker>
                    
                    
                    <b-collapse class="panel" animation="slide" :open="false" v-model="collapses[0]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Aguardando Entrega ({{  listaPosVendasEntrega.length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>
                        <table  class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaPosVendasEntrega" v-bind:key="index">
                                <td v-if="habilitaSelecionar">
                                    <b-checkbox  @input="changed(item)"
                                    size="is-small" type="is-info"  v-model="item.liberarEntrega"></b-checkbox >                                    
                                </td>
                                <td v-if="habilitaSelecionarUnificarEntrega">
                                    <b-checkbox  @input="changedUnificar(item)"
                                    size="is-small" type="is-info"  v-model="item.unificarAtendimento"></b-checkbox >                                    
                                </td>                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                                    
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                                      
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>   
                                        <b-icon v-if="item.dataHoraAgendamentoEntrega"
                                                        icon="calendar"
                                                        size="is-small"
                                                        type="is-info"                                    
                                                        :title="$t('VENDAS.AGENDAMENTOENTREGAREALIZADO')"></b-icon>                                                                                             
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                            </span>                                                                                         
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span v-for="(prazo, index) in item.prazos" v-bind:key="index">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> 
                                                    {{prazo.prazoNome}} <b> - FALTAM:</b> {{item.prazoRestante[index]}}  DIAS 
                                                </small>                                            
                                            </span>  
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                                                                                                                  
                                            <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}}
                                            
                                            <span v-if="item.dataHoraAgendamentoEntrega == null && item.dataHoraPrevisaoEntrega">
                                                <br><b>PREVISÃO ENTREGA: </b> {{ $moment(item.dataHoraPrevisaoEntrega).format("DD/MM/YYYY")}}                                                
                                            </span>                                              
                                            <span v-if="item.dataHoraAgendamentoEntrega">       
                                                <br><b>ENTREGA: </b>  {{$moment(item.dataHoraAgendamentoEntrega).format('DD/MM/YYYY')}}                                                
                                            </span>
                                            <span v-if="item.dataHoraAgendamentoInstalacao">       
                                                <br><b>INSTALAÇÃO: </b>  {{$moment(item.dataHoraAgendamentoInstalacao).format('DD/MM/YYYY')}}                                                
                                            </span>                                                                                                                                                                                                                          
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>                                   
                                </td>                                
                                <td>
                                    
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('posvendas-entrega')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link> 
                                            <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                            :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                           
                                                                      
                                    </div>   
                                    <br>  
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>                

                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>                         
                    </b-collapse>                    

                   
                    
                </div>
            </div>              
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderParaInstalacao==2 ? orderParaInstalacao= 0 : orderParaInstalacao++; ordenar('ParaInstalacao')">
                        <span><b-icon :icon="iconeOrderParaInstalacao"  size="is-small"> </b-icon></span>
                        {{$t('POSVENDAS.PARAINSTALACAO')}} 
                        ({{listaPosVendasParaInstalacao.length}})
                        <span v-if="listaPosVendasParaInstalacao.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPosVendasParaInstalacao.filter(x=>x.atraso).length }}</span>                                                                    

                        <span v-if="isInRole('atendimento-unificar')" class="is-pulled-right ">
                            <b-button v-if="!habilitaSelecionarUnificarParaInstalacao"
                             :title="$t('POSVENDAS.UNIFICARATENDIMENTO')"  class="is-warning" 
                            icon-left="merge" size="is-small"  @click="selecionarAtendimentosUnificar('ParaInstalacao')"></b-button>      
                            
                            <b-button v-if="habilitaSelecionarUnificarParaInstalacao" :title="$t('POSVENDAS.CONFIRMARUNIFICACAO')"  class="is-success" 
                            size="is-small"  icon-left="check"
                            @click="confirmarUnificar('ParaInstalacao')"></b-button>  

                            <b-button v-if="habilitaSelecionarUnificarParaInstalacao" :title="$t('POSVENDAS.CANCELARCONFIRMARLIBERACAO')"  class="is-danger" 
                            size="is-small" icon-left="close"
                            @click="cancelarSelecionarUnificar('ParaInstalacao')"></b-button>                                                                           
                        </span>                        
                    </p>   
                    <router-link v-if="isInRole('atendimento-agendamento')"  :to="{ name: 'agenda', params: {tipo: 3,filtrarTodos:true,voltarTela: 'posvenda' } }" class="button is-link is-outlined is-fullwidth">
                            {{$t('ATENDIMENTO.ABRIRCALENDARIO')}}
                    </router-link>                                          
                    <b-datepicker size="is-small" :placeholder="$t('SISTEMA.DATAINICIAL')" @blur="posvendasParaInstalacao()"
                    v-model="dataHoraInicialParaInstalacao" icon="calendar-today"  >
                        <b-button label="Limpar" type="is-warning" icon-left="close"  @click="dataHoraInicialParaInstalacao = null; dataHoraFinalParaInstalacao = null;" />                        
                    </b-datepicker>                    
                    <b-datepicker size="is-small" :placeholder="$t('SISTEMA.DATAFINAL')"  @blur="posvendasParaInstalacao()"
                    v-model="dataHoraFinalParaInstalacao" icon="calendar-today"  >                    
                        <b-button label="Limpar" type="is-warning" icon-left="close"  @click="dataHoraInicialParaInstalacao = null; dataHoraFinalParaInstalacao = nul" />                        
                    </b-datepicker>                    
                    <b-collapse class="panel m-0" animation="slide" :open="false" v-model="collapses[1]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Normal ({{  listaPosVendasParaInstalacao.filter(x=>x.tipo == 1 || x.tipo == 3).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in  listaPosVendasParaInstalacao.filter(x=>x.tipo == 1 || x.tipo == 3)" v-bind:key="index">
                                
                                <td v-if="habilitaSelecionarUnificarParaInstalacao">
                                    <b-checkbox  @input="changedUnificar(item)"
                                    size="is-small" type="is-info"  v-model="item.unificarAtendimento"></b-checkbox >                                    
                                </td>

                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>    
                                        <b-icon v-if="item.dataHoraAgendamentoInstalacao"
                                                        icon="calendar"
                                                        size="is-small"
                                                        type="is-info"                                    
                                                        :title="$t('VENDAS.AGENDAMENTOENTREGAREALIZADO')"></b-icon>                                                                                           
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                             
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span v-for="(prazo, index) in item.prazos" v-bind:key="index">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> 
                                                    {{prazo.prazoNome}} <b> - FALTAM:</b> {{item.prazoRestante[index]}}  DIAS 
                                                </small>                                            
                                            </span>  
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                                                                                                                  
                                            <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}} 
                                            <span v-if="item.dataHoraAgendamentoInstalacao == null && item.dataHoraPrevisaoInstalacao">
                                                <br><b>PREVISÃO INSTALACAO: </b> {{ $moment(item.dataHoraPrevisaoInstalacao).format("DD/MM/YYYY")}}                                                
                                            </span>                                                
                                            <span v-if="item.dataHoraAgendamentoInstalacao">       
                                                <br><b>DATA PARA INSTALAÇÃO: </b>  {{$moment(item.dataHoraAgendamentoInstalacao).format('DD/MM/YYYY')}}                                                
                                            </span> 

                                                                                                                                                           
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>                                    
                                </td>                                
                                <td>


                                    <span v-if="item.dataHoraVistoria ==null">
                                        <div class="is-inline-flex">
                                            <router-link v-if="isInRole('posvendas-parainstalacao')" :to="{ name: 'guia', params: { id: item.guiaId,guiaList: true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                                <b-icon icon="pencil" size="is-small"></b-icon>
                                            </router-link>                                             
                                            <b-button  @click="mostraMensagemAguardandoVistoria()" 
                                            class="is-small has-text-danger" title="Aguardando finalizar vistoria"> 
                                            <b-icon icon="lock-alert-outline" size="is-small"></b-icon>
                                            </b-button>                                                                            
                                            <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                            :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>
                                                                                        
                                            <b-button v-if="isInRole('posvendas-parainstalacao') && item.atraso && item.dataHoraAgendamentoInstalacao == null" @click="confirmarPrevisaoInstalacaoPosVenda(item)" 
                                            class="is-small has-text-danger" title="Alterar Previsão de Instalação?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                        </div>
                                        <br>  
                                        <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>           
                                    </span>                       
                                    <span v-else>
                                        <div class="is-inline-flex">
                                            <router-link v-if="isInRole('posvendas-parainstalacao')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true, paraInstalacao:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                                <b-icon icon="pencil" size="is-small"></b-icon>
                                            </router-link> 
                                            <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                                :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>
                                            <b-button v-if="isInRole('atendimento-vendas') && item.atraso && item.dataHoraAgendamentoInstalacao == null" @click="confirmarPrevisaoInstalacaoPosVenda(item)" 
                                                class="is-small has-text-danger" title="Alterar Previsão de Instalação?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                        </div>  
                                        <br>  
                                        <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>                                                                                             
                                    </span>             
                             
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                        </table>  
                    </b-collapse>  
                    
                    <b-collapse class="panel" animation="slide" :open="false" v-model="collapses[2]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Assistência Normal ({{  listaPosVendasParaInstalacao.filter(x=>x.tipo == 2).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaPosVendasParaInstalacao.filter(x=>x.tipo == 2 )" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>    
                                        <b-icon v-if="item.dataHoraAgendamentoInstalacao"
                                                        icon="calendar"
                                                        size="is-small"
                                                        type="is-info"                                    
                                                        :title="$t('VENDAS.AGENDAMENTOENTREGAREALIZADO')"></b-icon>                                                                                           
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                             
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span v-for="(prazo, index) in item.prazos" v-bind:key="index">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> 
                                                    {{prazo.prazoNome}} <b> - FALTAM:</b> {{item.prazoRestante[index]}}  DIAS 
                                                </small>                                            
                                            </span>  
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                                                                                                                  
                                            <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}} 
                                            <span v-if="item.dataHoraAgendamentoInstalacao">       
                                                <br><b>INSTALAÇÃO: </b>  {{$moment(item.dataHoraAgendamentoInstalacao).format('DD/MM/YYYY')}}                                                
                                            </span> 
                                                                                                                                                           
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>                                    
                                </td>                                
                                <td>


                                    <span v-if="item.dataHoraVistoria ==null">
                                        <div class="is-inline-flex">
                                            <b-button  @click="mostraMensagemAguardandoVistoria()" 
                                            class="is-small has-text-danger" title="Aguardando finalizar vistoria"> 
                                            <b-icon icon="lock-alert-outline" size="is-small"></b-icon>
                                            </b-button>                                                                            
                                                <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                                :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                                                                                                                  
                                        </div>
                                        <br>  
                                        <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>           
                                    </span>                       
                                    <span v-else>
                                        <div class="is-inline-flex">
                                            <router-link v-if="isInRole('posvendas-parainstalacao')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true, paraInstalacao:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                                <b-icon icon="pencil" size="is-small"></b-icon>
                                            </router-link> 
                                                <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                                :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>
                                        </div>  
                                        <br>  
                                        <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>                                                                                             
                                    </span>             
                             
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>  
                    </b-collapse>                      
                    
                </div>
            </div>              
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderEmInstalacao==2 ? orderEmInstalacao= 0 : orderEmInstalacao++; ordenar('EmInstalacao')">
                        <span><b-icon :icon="iconeOrderEmInstalacao"  size="is-small"> </b-icon></span>
                        <span class="is-size-7">{{$t('POSVENDAS.EMINSTALACAO')}} </span>
                        ({{listaPosVendasEmInstalacao.length}})
                        <span v-if="listaPosVendasEmInstalacao.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPosVendasEmInstalacao.filter(x=>x.atraso).length }}</span>                                                  
                        <span v-if="totalAtrasoAssistenciasEmInstalacao>0"  style="display: inline-block;" class="navbar-link is-arrowless blink_me" >
                                            <b-icon   icon="wrench"
                                                        size="is-small"                                                        
                                                        type="is-danger"                                   
                                                        :title="$t('POSVENDAS.ASSISTENCIAATRASADA')"></b-icon>  
                                            <b-tag style="background: none;color: #f14668;"  type="is-danger tag-notify" rounded>{{totalAtrasoAssistenciasEmInstalacao}}</b-tag>
                        </span>    
                        <span v-if="isInRole('atendimento-unificar')" class="is-pulled-right ">
                            <b-button v-if="!habilitaSelecionarUnificarEmInstalacao"
                             :title="$t('POSVENDAS.UNIFICARATENDIMENTO')"  class="is-warning" 
                            icon-left="merge" size="is-small"  @click="selecionarAtendimentosUnificar('EmInstalacao')"></b-button>      
                            
                            <b-button v-if="habilitaSelecionarUnificarEmInstalacao" :title="$t('POSVENDAS.CONFIRMARUNIFICACAO')"  class="is-success" 
                            size="is-small"  icon-left="check"
                            @click="confirmarUnificar('EmInstalacao')"></b-button>  

                            <b-button v-if="habilitaSelecionarUnificarEmInstalacao" :title="$t('POSVENDAS.CANCELARCONFIRMARLIBERACAO')"  class="is-danger" 
                            size="is-small" icon-left="close"
                            @click="cancelarSelecionarUnificar('EmInstalacao')"></b-button>                                                                           
                        </span>

                    </p>
                    
                    <b-collapse class="panel m-0" animation="slide" :open="false" v-model="collapses[3]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Normal ({{  listaPosVendasEmInstalacao.filter(x=>(x.tipo == 1 || x.tipo == 3) && x.dataHoraInstalacaoPausada == null).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaPosVendasEmInstalacao.filter(x=>(x.tipo == 1 || x.tipo == 3) && x.dataHoraInstalacaoPausada == null)" v-bind:key="index">
                                
                                <td v-if="habilitaSelecionarUnificarEmInstalacao">
                                    <b-checkbox  @input="changedUnificar(item)"
                                    size="is-small" type="is-info"  v-model="item.unificarAtendimento"></b-checkbox >                                    
                                </td>

                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>  
                                        <span v-if="item.assistenciasAtrasadas>0" class="navbar-link is-arrowless blink_me" >
                                            <b-icon   icon="wrench"
                                                        size="is-small"                                                        
                                                        type="is-danger"                                   
                                                        :title="$t('POSVENDAS.ASSISTENCIAATRASADA')"></b-icon>  
                                            <b-tag style="background: none;color: #f14668;"  type="is-danger tag-notify" rounded>{{item.assistenciasAtrasadas}}</b-tag>
                                        </span>                                                                                                         
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                             
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span v-for="(prazo, index) in item.prazos" v-bind:key="index">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> 
                                                    {{prazo.prazoNome}} <b> - FALTAM:</b> {{item.prazoRestante[index]}}  DIAS 
                                                </small>                                            
                                            </span>  
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                                                                                                                  
                                            <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}} <br>
                                            <span v-if="item.dataHoraPrazoInstalacao">                                                
                                                <b>PREVISTO: </b>  {{$moment(item.dataHoraPrazoInstalacao).format('DD/MM/YYYY')}}                                                                                                                                                                                                                              
                                            </span>
                                            
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>                                  
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('posvendas-eminstalacao')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true,emVistoriaInstalacao:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>              
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                        
                                    </div>      
                                    <br>   
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>                     
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                        </table> 
                    </b-collapse>  
                    
                    <b-collapse class="panel m-0" animation="slide" :open="false" v-model="collapses[4]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Assistência ({{  listaPosVendasEmInstalacao.filter(x=>x.tipo == 2 && x.dataHoraInstalacaoPausada == null).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaPosVendasEmInstalacao.filter(x=>x.tipo == 2 && x.dataHoraInstalacaoPausada == null)" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon> 
                                        <span v-if="item.assistenciasAtrasadas>0" class="navbar-link is-arrowless blink_me" >
                                            <b-icon   icon="wrench"
                                                        size="is-small"                                                        
                                                        type="is-danger"                                   
                                                        :title="$t('POSVENDAS.ASSISTENCIAATRASADA')"></b-icon>  
                                            <b-tag style="background: none;color: #f14668;"  type="is-danger tag-notify" rounded>{{item.assistenciasAtrasadas}}</b-tag>
                                        </span>                                                                                                          
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                             
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span v-for="(prazo, index) in item.prazos" v-bind:key="index">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> 
                                                    {{prazo.prazoNome}} <b> - FALTAM:</b> {{item.prazoRestante[index]}}  DIAS 
                                                </small>                                            
                                            </span>  
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                                                                                                                  
                                            <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}} <br>
                                            <span v-if="item.dataHoraPrazoInstalacao">                                                
                                                <b>PREVISTO: </b>  {{$moment(item.dataHoraPrazoInstalacao).format('DD/MM/YYYY')}}                                                                                                                                                                                                                              
                                            </span>
                                            
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>                                  
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('posvendas-eminstalacao')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true,emVistoriaInstalacao:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>              
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                        
                                    </div>    
                                    <br>   
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>                       
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                        </table> 
                    </b-collapse>                    
                    
                    <b-collapse class="panel m-0" animation="slide" :open="false" v-model="collapses[5]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Pausado ({{  listaPosVendasEmInstalacao.filter(x=> x.dataHoraInstalacaoPausada != null).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaPosVendasEmInstalacao.filter(x=> x.dataHoraInstalacaoPausada != null)" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>
                                        <span v-if="item.assistenciasAtrasadas>0" class="navbar-link is-arrowless blink_me" >
                                            <b-icon   icon="wrench"
                                                        size="is-small"                                                        
                                                        type="is-danger"                                   
                                                        :title="$t('POSVENDAS.ASSISTENCIAATRASADA')"></b-icon>  
                                            <b-tag style="background: none;color: #f14668;"  type="is-danger tag-notify" rounded>{{item.assistenciasAtrasadas}}</b-tag>
                                        </span>                                                                                                           
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                             
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span v-for="(prazo, index) in item.prazos" v-bind:key="index">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> 
                                                    {{prazo.prazoNome}} <b> - FALTAM:</b> {{item.prazoRestante[index]}}  DIAS 
                                                </small>                                            
                                            </span>  
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                                                                                                                  
                                            <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}} <br>
                                            <span v-if="item.dataHoraInstalacaoPausada">                                                
                                                <b>PREVISTO: </b>  {{$moment(item.dataHoraInstalacaoPausada).format('DD/MM/YYYY')}}                                                                                                                                                                                                                              
                                            </span>
                                            
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>                                  
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('posvendas-eminstalacao')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>              
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button> 
                                        <b-button v-if="isInRole('posvendas-eminstalacao') && item.atraso" @click="confirmarPausaInstalacaoPosVenda(item.id,false)" 
                                        class="is-small has-text-danger" title="Alterar Prazo Instalação Pausada?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                                                                                                       
                                        
                                    </div> 
                                    <br>  
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>                           
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                        </table> 
                    </b-collapse> 
                    
                    
                    <b-collapse class="panel m-0" animation="slide" :open="false" v-model="collapses[6]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Finalizar Efinance ({{  listaPosVendasEmInstalacao.filter(x=>x.dataHoraInstalacaoPausada == null && x.guiaAmbientes.filter(a => a.dataTerminoVistoria !=null).length>0 ).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaPosVendasEmInstalacao.filter(x=>x.dataHoraInstalacaoPausada == null && x.guiaAmbientes.filter(a => a.dataTerminoVistoria !=null).length>0)" v-bind:key="index">
                                
                                <td v-if="habilitaSelecionarUnificarEmInstalacao">
                                    <b-checkbox  @input="changedUnificar(item)"
                                    size="is-small" type="is-info"  v-model="item.unificarAtendimento"></b-checkbox >                                    
                                </td>

                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>  
                                        <span v-if="item.assistenciasAtrasadas>0" class="navbar-link is-arrowless blink_me" >
                                            <b-icon   icon="wrench"
                                                        size="is-small"                                                        
                                                        type="is-danger"                                   
                                                        :title="$t('POSVENDAS.ASSISTENCIAATRASADA')"></b-icon>  
                                            <b-tag style="background: none;color: #f14668;"  type="is-danger tag-notify" rounded>{{item.assistenciasAtrasadas}}</b-tag>
                                        </span>                                                                                                         
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                             
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <span v-for="(prazo, index) in item.prazos" v-bind:key="index">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>PRAZO: </b> 
                                                    {{prazo.prazoNome}} <b> - FALTAM:</b> {{item.prazoRestante[index]}}  DIAS 
                                                </small>                                            
                                            </span>  
                                            <span>                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b>VALOR VENDA: </b> {{item.valorVendaFormatado}} 
                                                </small>                                            
                                            </span>                                                                                                                                  
                                            <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}} <br>
                                            <span v-if="item.dataHoraPrazoInstalacao">                                                
                                                <b>PREVISTO: </b>  {{$moment(item.dataHoraPrazoInstalacao).format('DD/MM/YYYY')}}                                                                                                                                                                                                                              
                                            </span>
                                            
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>                                  
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('posvendas-eminstalacao')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true, emInstalacao:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>              
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                        
                                    </div>      
                                    <br>   
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>                     
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                        </table> 
                    </b-collapse>                      
                </div>
            </div>  
           
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" style="padding-left: 0;padding-right: 5px;" @click="orderInstalacaoIncompleta==2 ? orderInstalacaoIncompleta= 0 : orderInstalacaoIncompleta++; ordenar('InstalacaoIncompleta')">
                        <span><b-icon :icon="iconeOrderInstalacaoIncompleta"  size="is-small"> </b-icon></span>
                        <span class="is-size-7">{{$t('POSVENDAS.INSTALACAOINCOMPLETA')}} </span>
                        ({{listaPosVendasInstalacaoIncompleta.length}})
                        <span v-if="listaPosVendasInstalacaoIncompleta.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPosVendasInstalacaoIncompleta.filter(x=>x.atraso).length }}</span>                                                                          
                        <span v-if="totalAtrasoAssistenciasInstalacaoIncompleta>0" style="display: inline-block;" class="navbar-link is-arrowless blink_me" >
                                            <b-icon   icon="wrench"
                                                        size="is-small"                                                        
                                                        type="is-danger"                                   
                                                        :title="$t('POSVENDAS.ASSISTENCIAATRASADA')"></b-icon>  
                                            <b-tag style="background: none;color: #f14668;"  type="is-danger tag-notify" rounded>{{totalAtrasoAssistenciasInstalacaoIncompleta}}</b-tag>
                        </span>   
                        <span v-if="isInRole('atendimento-unificar')" class="is-pulled-right ">
                            <b-button v-if="!habilitaSelecionarUnificarInstalacaoIncompleta"
                             :title="$t('POSVENDAS.UNIFICARATENDIMENTO')"  class="is-warning" 
                            icon-left="merge" size="is-small"  @click="selecionarAtendimentosUnificar('InstalacaoIncompleta')"></b-button>      
                            
                            <b-button v-if="habilitaSelecionarUnificarInstalacaoIncompleta" :title="$t('POSVENDAS.CONFIRMARUNIFICACAO')"  class="is-success" 
                            size="is-small"  icon-left="check"
                            @click="confirmarUnificar('InstalacaoIncompleta')"></b-button>  

                            <b-button v-if="habilitaSelecionarUnificarInstalacaoIncompleta" :title="$t('POSVENDAS.CANCELARCONFIRMARLIBERACAO')"  class="is-danger" 
                            size="is-small" icon-left="close"
                            @click="cancelarSelecionarUnificar('InstalacaoIncompleta')"></b-button>                                                                           
                        </span>                          
                       <span class="is-pulled-right ">
                            <b-button v-if="isInRole('posvendas-inserircliente-etapa')"  class="is-info" size="is-small" 
                            :title="$t('MODALINSERIRCLIENTENOVOETAPA.NOVOCLIENTE')" icon-left="account-plus"
                            @click="inserirClienteNovoEtapa('instalacaoincompleta')"></b-button>                                                                                                            
                        </span>           
                                                              
                    </p>

                                      
                    <b-collapse class="panel m-0" animation="slide" :open="false" v-model="collapses[7]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Normal ({{  listaPosVendasInstalacaoIncompleta.filter(x=>x.tipo == 1 || x.tipo == 3).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in  listaPosVendasInstalacaoIncompleta.filter(x=>x.tipo == 1 || x.tipo == 3)" v-bind:key="index">

                                <td v-if="habilitaSelecionarUnificarInstalacaoIncompleta">
                                    <b-checkbox  @input="changedUnificar(item)"
                                    size="is-small" type="is-info"  v-model="item.unificarAtendimento"></b-checkbox >                                    
                                </td>
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>  
                                                        
                                        <span v-if="item.assistenciasAtrasadas>0" class="navbar-link is-arrowless blink_me" >
                                            <b-icon   icon="wrench"
                                                        size="is-small"                                                        
                                                        type="is-danger"                                   
                                                        :title="$t('POSVENDAS.ASSISTENCIAATRASADA')"></b-icon>  
                                            <b-tag style="background: none;color: #f14668;"  type="is-danger tag-notify" rounded>{{item.assistenciasAtrasadas}}</b-tag>
                                        </span>                                                         

                                                                  
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">

                                    <b-tooltip v-if="!item.certificado || item.faltaAssistencias "  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>  
                                            <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                                                                                                                    
                                            <span v-if="!item.certificado"><b>Incompleto:</b>Certificado <br></span> 
                                            <span v-if="item.faltaAssistencias"><b>Incompleto:</b>Assistências</span>
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span> 
                                    </b-tooltip>                                                                   
                                    <span v-else class="is-size-7">{{item.clienteNome}} </span> 
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('posvendas-instalacaoincompleta')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>  
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                            
                                    </div> 
                                    <br>    
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>                         
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                        </table>      
                    </b-collapse>  
                    
                    <b-collapse class="panel" animation="slide" :open="false"  v-model="collapses[8]">
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Assistência ({{  listaPosVendasInstalacaoIncompleta.filter(x=>x.tipo == 2).length}})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in  listaPosVendasInstalacaoIncompleta.filter(x=>x.tipo == 2 )" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>                                      
                                        <span v-if="item.assistenciasAtrasadas>0" class="navbar-link is-arrowless blink_me" >
                                            <b-icon   icon="wrench"
                                                        size="is-small"                                                        
                                                        type="is-danger"                                   
                                                        :title="$t('POSVENDAS.ASSISTENCIAATRASADA')"></b-icon>  
                                            <b-tag style="background: none;color: #f14668;"  type="is-danger tag-notify" rounded>{{item.assistenciasAtrasadas}}</b-tag>
                                        </span>                                                                     
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">

                                    <b-tooltip v-if="!item.certificado || item.faltaAssistencias "  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>   
                                            <span v-if="item.codigoContrato>0">
                                                    <b>CONTRATO: </b> {{item.codigoContrato}}
                                                    <br>
                                                </span>                                                                                                                                   
                                            <span v-if="!item.certificado"><b>Incompleto:</b>Certificado <br></span> 
                                            <span v-if="item.faltaAssistencias"><b>Incompleto:</b>Assistências</span>
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span> 
                                    </b-tooltip>                                                                   
                                    <span v-else class="is-size-7">{{item.clienteNome}} </span> 
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('posvendas-instalacaoincompleta')" :to="{ name: 'guia', params: { id: item.guiaId, fluxoVenda:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>  
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.guiaId)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                            
                                    </div>  
                                    <br>    
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>                        
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                        </table>      
                    </b-collapse> 

                </div>
            </div>     
                                     
                                                  
        </div>
            <br>
            <br>


        <div class=" columns has-text-centered" style="background-color: rgb(34 81 99 / 22%); display:block; ">
            <br>
            <h1 class="title">Assistências</h1> 
        </div>
        <div class="p-2 columns" style="background-color: rgb(34 81 99 / 22%); ">            
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2">                        
                        {{$t('POSVENDAS.ASSISTENCIATECNICA')}} 
                        ({{listaPosVendasAssistenciaTecnicas.length}})
                        <span v-if="listaPosVendasAssistenciaTecnicas.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPosVendasAssistenciaTecnicas.filter(x=>x.atraso).length }}</span>                                                                    
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="item.atraso ? 'bg-atraso' : '' " v-for="(item, index) in listaPosVendasAssistenciaTecnicas" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>    
                                        <b-icon v-if="item.dataHoraDevolucaoSupervisor"
                                                        icon="help"
                                                        size="is-small"
                                                        type="is-danger"                                    
                                                        :title="$t('POSVENDAS.DEVOLUCAOSUPERVISOR')"></b-icon>    
                                                                                                             
                                    </span>     
                                    <span v-if="item.custoNome=='CUSTO FABRICA' && item.dataHoraAprovacaoFabrica==null"  
                                        class="cp" @click="aprovacaoCustoFabrica(item.id)">

                                            <b-icon v-if="item.dataHoraDevolucaoSupervisor ==null" icon="timer-sand"
                                                            size="is-small"
                                                            type="is-black"                                    
                                                            :title="$t('POSVENDAS.AGUARDANDOFABRICAAPROVAR')"></b-icon> 
                                            <b-icon v-else icon="timer-sand"
                                                            size="is-small"
                                                            type="is-black"                                    
                                                            :title="$t('POSVENDAS.AGUARDANDOSUPERVISORRESOLVER')"></b-icon>                                                             
                                    </span>                                                                     
                                  
                                </td>                                
                                <td class="pl-0 pr-0">
                                    
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>                                            
                                            <b>TÍTULO:</b> <span v-html="tratamentoQuebraLinha(item.titulo)"></span><br>
                                            <b>DESCRIÇÃO:</b> <span v-html="tratamentoQuebraLinha(item.observacao)"></span><br>
                                            <b>CONFERENTE:</b>  {{item.funcionarioConferenteNome}}                                              
                                        </template>                                             
                                        <span class="is-size-7">{{item.clienteNome}} </span> 
                                    </b-tooltip>                                                                       
                                </td>                                
                                <td>

                                    <div class="is-inline-flex">
                                        <b-button v-if="isInRole('posvendas-assistenciatecnicas')"  
                                    :title="$t('SISTEMA.EDITAR')" class="is-small is-primary" @click="incluirAssistencia(item)" icon-left="pencil"></b-button>                                                                                                           
                                    <span  v-if="item.custoNome=='CUSTO FABRICA' && item.dataHoraAprovacaoFabrica==null">
                                            
                                           <b-button  @click="mostraMensagemAguardandoAprovacaoFabrica(item)" 
                                            class="is-small has-text-danger" :title="$t('POSVENDAS.AGUARDANDOFABRICAAPROVAR')"> 
                                            <b-icon icon="lock-alert-outline" size="is-small"></b-icon>
                                            </b-button>                                                                                     
                                    </span>                                        

                                    </div>       

                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>                       
                    
                </div>
            </div> 
                                    
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2">
                        {{$t('POSVENDAS.ENVIARASTECFABRICA')}} 
                        ({{listaPosVendasEnviarAstecFabrica.length}})
                        <span v-if="listaPosVendasEnviarAstecFabrica.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPosVendasEnviarAstecFabrica.filter(x=>x.atraso).length }}</span>                                                                    
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="item.atraso ? 'bg-atraso' : '' " v-for="(item, index) in listaPosVendasEnviarAstecFabrica" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>                                      
                                    </span> 
                                </td>                                                                
                                <td class="pl-0 pr-0">
                                    
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>                                            
                                            <b>TÍTULO:</b> <span v-html="tratamentoQuebraLinha(item.titulo)"></span><br>
                                            <b>DESCRIÇÃO:</b> <span v-html="tratamentoQuebraLinha(item.observacao)"></span><br>
                                            <b>CONFERENTE:</b>  {{item.funcionarioConferenteNome}}                                              
                                        </template>                                             
                                        <span class="is-size-7">{{item.clienteNome}} </span> 
                                    </b-tooltip>                                                                       
                                </td>                                                                   
                                <td>
                                    <b-button v-if="isInRole('posvendas-enviarastecfabrica')"  
                                    :title="$t('SISTEMA.EDITAR')" class="is-small is-primary" @click="incluirAssistencia(item)" icon-left="pencil"></b-button>                                                                                                   
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>                      
                    
                </div>
            </div>            
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2">
                        {{$t('POSVENDAS.FINALIZAREFINANCE')}} 
                        ({{listaPosVendasFinalizarEFinance.length}})                        
                        <span v-if="listaPosVendasFinalizarEFinance.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPosVendasFinalizarEFinance.filter(x=>x.atraso).length }}</span>                                                                    
                    </p>

                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="item.atraso ? 'bg-atraso' : '' " v-for="(item, index) in listaPosVendasFinalizarEFinance" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>                                      
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">
                                    
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>                                            
                                            <b>TÍTULO:</b> <span v-html="tratamentoQuebraLinha(item.titulo)"></span><br>
                                            <b>DESCRIÇÃO:</b> <span v-html="tratamentoQuebraLinha(item.observacao)"></span><br>
                                            <b>CONFERENTE:</b>  {{item.funcionarioConferenteNome}}                                              
                                        </template>                                             
                                        <span class="is-size-7">{{item.clienteNome}} </span> 
                                    </b-tooltip>                                                                       
                                </td>                                 
                                <td>      
                                    <b-button v-if="isInRole('posvendas-finalizarefinance')"  
                                    :title="$t('SISTEMA.EDITAR')" class="is-small is-primary" @click="incluirAssistencia(item)" icon-left="pencil"></b-button>                                                                                                                                                                      
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>                      
                    
                </div>
            </div>  

            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2">
                        {{$t('POSVENDAS.FINALIZARFINANCEIRO')}} 
                        ({{listaPosVendasFinalizarFinanceiro.length}})       
                        <span v-if="listaPosVendasFinalizarFinanceiro.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPosVendasFinalizarFinanceiro.filter(x=>x.atraso).length }}</span>                                                                                                        
                    </p>

                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr :class="item.atraso ? 'bg-atraso' : '' " v-for="(item, index) in listaPosVendasFinalizarFinanceiro" v-bind:key="index">
                                
                                <td>
                                    <span>{{item.clienteCodigoExterno}}
                                        <b-icon v-if="item.vendaVinculadaId > 0"  icon="link"
                                                        size="is-small"                                    
                                                        :title="$t('GUIALIST.ATENDIMENTOVINCULADO')"
                                                        type="is-link"></b-icon>                                          
                                        <b-icon v-if="item.tipo == 2"
                                                        icon="cog"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.ASSISTENCIA')"></b-icon>      
                                        <b-icon v-if="item.tipo == 3"
                                                        icon="tools"
                                                        size="is-small"                                    
                                                        :title="$t('VENDAS.MOSTRUARIO')"></b-icon>                                      
                                    </span> 
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>                                            
                                            <b>TÍTULO:</b> <span v-html="tratamentoQuebraLinha(item.titulo)"></span><br>
                                            <b>DESCRIÇÃO:</b> <span v-html="tratamentoQuebraLinha(item.observacao)"></span><br>
                                            <b>CONFERENTE:</b>  {{item.funcionarioConferenteNome}}                                              
                                        </template>                                             
                                        <span class="is-size-7">{{item.clienteNome}} </span> 
                                    </b-tooltip>                                 
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <b-button v-if="isInRole('posvendas-finalizarefinanceiro')"  
                                        :title="$t('SISTEMA.EDITAR')" class="is-small is-primary" @click="incluirAssistencia(item)" icon-left="pencil"></b-button>                                                                                                                                                                                                                                                                 
                                    </div>                 
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>                      
                    
                </div>
            </div>              
            <br>         
        </div>                      


     
   
                                 
    </section>
</template>


<style scoped>
.has-background-dark {
    background-color: #363636d6 !important;
}

</style>

<script>
    import modalCadastroAssistenciaPosVenda from '@/components/posvenda/modalCadastroAssistenciaPosVenda'    
    import modalLiberarEntregaPosVenda from '@/components/posvenda/modalLiberarEntregaPosVenda'    
    import modalInserirClienteNovoEtapa from '@/components/posvenda/modalInserirClienteNovoEtapa'      
    import modalPrevisaoInstalacaoPosVenda from '@/components/posvenda/modalPrevisaoInstalacaoPosVenda.vue'   
    import { mapState, mapGetters } from 'vuex'    
    import modalObservacaoGuia  from '@/components/atendimento/modalObservacaoGuia'
    import modalInstalacaoPausadaPosVenda from '@/components/posvenda/modalInstalacaoPausadaPosVenda.vue'    
    import modalAlteracaoPrazoObraNaoConforme from '@/components/posvenda/modalAlteracaoPrazoObraNaoConforme.vue'    
    
    export default {
        data() {
            return {        
                agendaHoje: [],  
                listaPosVendasObrasPlantas: [],
                listaPosVendasPlantasInstalacao:[],
                listaPosVendasVistoria:[],
                listaPosVendasInstalacaoFutura: [],                         
                listaPosVendasEntrega: [],
                listaPosVendasParaInstalacao: [],
                listaPosVendasEmInstalacao: [],
                totalAtrasoAssistenciasEmInstalacao:0,
                listaPosVendasAssistenciaTecnicas: [],
                listaPosVendasEnviarAstecFabrica: [],
                listaPosVendasFinalizarEFinance: [],
                listaPosVendasFinalizarFinanceiro: [],
                listaPosVendasInstalacaoIncompleta: [],
                totalAtrasoAssistenciasInstalacaoIncompleta:0,
                
                isLoading: false,
                habilitaSelecionar:false,
                entregasSelecionados: [], 
                habilitaSelecionarUnificarEntrega:false,
                habilitaSelecionarUnificarParaInstalacao: false,
                habilitaSelecionarUnificarEmInstalacao: false,
                habilitaSelecionarUnificarInstalacaoIncompleta: false,                
                atendimentoSelecionadosUnificar: [],
                dataHoraInicialParaInstalacao:null,
                dataHoraFinalParaInstalacao: null,
                dataHoraInicialEntrega:null,
                dataHoraFinalEntrega:null,

                orderObrasPlantas: 0,
                iconeOrderObrasPlantas:'',                
                orderPlantasTecnicas: 0,
                iconeOrderPlantasTecnicas:'',                
                orderEntrega: 0,
                iconeOrderEntrega:'',                                                
                orderParaInstalacao: 0,
                iconeOrderParaInstalacao:'',                
                orderEmInstalacao: 0,
                iconeOrderEmInstalacao:'',                
                orderInstalacaoIncompleta: 0,
                iconeOrderInstalacaoIncompleta:'',     
                
                collapses: [false,false, false, false, false, false, false, false,false],
                queryCliente: this.$route.params.queryCliente ?? null,                 
                                                                       
            }
        },
        components: {  
                                  
        },
        computed: {
            ...mapState([
                'unidadeId',
                'config',
                'usuario'                
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {            
            unidadeId: function(novaUnidade) {                 
                this.loadAsyncData()                                                
            },
        },         
        methods: {
            verificaPesquisarCliente(){
                
                if(this.queryCliente){
                    this.isLoading = true;
                    setTimeout(() => { this.pesquisarCliente(this.queryCliente)},5000);                                    
                }

            },            
            pesquisarCliente(queryCliente){
                this.isLoading = false;                             
                this.collapsesOpenAll();                    
                setTimeout(() => { this.barraRolagemCliente(this.queryCliente)},1000);                     
             
            },
            barraRolagemCliente(queryCliente){
                const searchElements = document.querySelectorAll(".highlight"); // Seleciona os elementos com a classe 'searchable'


                let found = false;

                searchElements.forEach(element => {
                    const regex = new RegExp(`(${queryCliente})`, "gi");
                    element.innerHTML = element.innerHTML.replace(regex, `<mark>$1</mark>`);

                    // Rola até o primeiro destaque encontrado
                    if (!found) {
                    const firstHighlight = element.querySelector("mark");
                    if (firstHighlight) {
                        firstHighlight.scrollIntoView({ behavior: "smooth", block: "center" });
                        found = true; // Garante que rola apenas para o primeiro match
                    }
                    }
                });   
            },
            collapsesOpenAll() {
                this.collapses = this.collapses.map(() => true);
                this.$forceUpdate();
            },
            collapsesCloseAll() {
                this.collapses = this.collapses.map(() => false);
                this.$forceUpdate();
            },             
            aprovacaoCustoFabrica(assistenciaId){

                if(!this.isInRole('posvenda-assistencia-fabrica'))
                    return;

                this.salvandoDados=true;
                const params = [];                                                                                                                      
                params.push(`id=${assistenciaId}`);  

                this.$buefy.dialog.confirm({
                title: 'Confirmar',
                message: this.$t('POSVENDAS.CONFIRMARFABRICAAPROVAR'),
                type: 'is-success',
                hasIcon: true,
                cancelText: 'Não',
                confirmText: 'Sim',
                onConfirm: () => {
                                                                                                                 
                                this.$http.get(`/api/assistencia/ConfirmarAprovacaoFabrica?${params.join('&')}`)                        
                                .then(res => res.data)
                                .then(data => {                                                
                                    this.salvandoDados=false;
                                }).finally(() => {                                
                                    this.salvandoDados = false;    
                                    this.$buefy.toast.open({
                                        duration: 10000,
                                        message: "Assistência atualizada com sucesso",
                                        type: 'is-success',
                                        queue: false
                                    });                                                                                                 
                                    this.posvendasAssistenciaTecnicas();                          
                                    
                                }).catch((error) => {    

                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: "Ocorreu um erro na aprovação da assistênciat!",
                                        type: 'is-danger',
                                        queue: false
                                    });                                                    
                                    throw error;
                                });                               
      
                    }
                });                


            },                   
            confirmarAlteracaoPrazoObraNaoConforme(item){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlteracaoPrazoObraNaoConforme,
                    props: {
                        posvenda: item                        
                    },
                    events: {
                        loadData: this.loadAsyncData
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },               
            retornaEstiloLinha(item){

                if(item.atraso){
                    return 'bg-atraso'
                }else if(item.inside){
                    return 'has-background-dark has-text-white';
                }else{
                    return '';
                }
            },                
            confirmarPausaInstalacaoPosVenda(posvendaId,liberar){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalInstalacaoPausadaPosVenda,
                    props: {
                        id: posvendaId,
                        liberar:liberar                       
                    },
                    events: {
                        loadData: this.loadAsyncData                    
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },               
            verificaOrdenarIcone(item){                
                if(item==0){
                    return '';
                }else if(item==1){
                    return 'sort-alphabetical-ascending';
                }else if(item==2){
                    return 'sort-alphabetical-descending';
                }else{
                    return '';
                }
            },    
            ordenar(etapa){
  
                if(etapa == 'ObrasPlantas'){
                    this.iconeOrderObrasPlantas = this.verificaOrdenarIcone(this.orderObrasPlantas);   
                    this.posvendasObrasPlantas();            
                } else if(etapa == 'PlantasTecnicas'){
                    this.iconeOrderPlantasTecnicas = this.verificaOrdenarIcone(this.orderPlantasTecnicas);   
                    this.posvendasPlantasTecnicas();            
                }else if(etapa == 'Entrega'){                    
                    this.iconeOrderEntrega = this.verificaOrdenarIcone(this.orderEntrega);   
                    this.posvendasEntrega();                 
                } else if(etapa == 'ParaInstalacao'){
                    this.iconeOrderParaInstalacao = this.verificaOrdenarIcone(this.orderParaInstalacao);   
                    this.posvendasParaInstalacao();                 
                } else if(etapa == 'EmInstalacao'){
                    this.iconeOrderEmInstalacao = this.verificaOrdenarIcone(this.orderEmInstalacao);   
                    this.posvendasEmInstalacao();            
                }else if(etapa == 'InstalacaoIncompleta'){                    
                    this.iconeOrderInstalacaoIncompleta = this.verificaOrdenarIcone(this.orderInstalacaoIncompleta);   
                    this.posvendasInstalacaoIncompleta();                 
                }
            },             
            confirmarPrevisaoInstalacaoPosVenda(posvenda){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalPrevisaoInstalacaoPosVenda,
                    props: {
                        id: posvenda.id                        
                    },
                    events: {
                        loadData: this.posvendasParaInstalacao                    
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },   

            recarregarposvendasInstalacaoFutura(){
                this.posvendasInstalacaoFutura();                
                this.posvendasInstalacaoIncompleta();   
            },
            inserirClienteNovoEtapa(tipo){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalInserirClienteNovoEtapa,
                    props: {
                        tipoEtapa: tipo
                    },                    
                    events: {
                        recarregarposvendasInstalacaoFutura: this.recarregarposvendasInstalacaoFutura   
                    },                    
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            confirmarInclusaoObservacao(guiaId) {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalObservacaoGuia,
                    props: {
                        guia: {id: guiaId}                                              
                    },
                    events: {
                        loadData: this.loadGuia
                    },                    
                    hasModalCard: true,
                    trapFocus: true
                })
            },            

            changedUnificar(item){

                if(this.atendimentoSelecionadosUnificar.length>0 && item.unificarAtendimento ){

                    if(this.atendimentoSelecionadosUnificar.filter(x=>x.clienteCodigoExterno == item.clienteCodigoExterno).length==0 ){
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('POSVENDAS.ATENDIMENTOSMESMOCONTRATO'),
                            type: 'is-warning',
                            hasIcon: true                                          
                        });  
                        setTimeout(() => { item.unificarAtendimento = false;},500);                   
                        return;                           
                    }

                }

                if(item.unificarAtendimento){
                    this.atendimentoSelecionadosUnificar.push(item);
                }else{
                    this.atendimentoSelecionadosUnificar = this.atendimentoSelecionadosUnificar.filter(x=>x.guiaId != item.guiaId);
                }
                    
            },            
            habilitarSelecionarUnificar(etapa,habilitar){
                if(etapa=="Entrega"){
                    this.habilitaSelecionarUnificarEntrega = habilitar;
                }                   
                if(etapa=="ParaInstalacao"){
                    this.habilitaSelecionarUnificarParaInstalacao = habilitar;
                }                   
                if(etapa=="EmInstalacao"){
                    this.habilitaSelecionarUnificarEmInstalacao = habilitar;
                }
                if(etapa=="InstalacaoIncompleta"){
                    this.habilitaSelecionarUnificarInstalacaoIncompleta = habilitar;
                }                

                             
            },
            selecionarAtendimentosUnificar(etapa){                
                this.habilitarSelecionarUnificar(etapa,true);
                this.atendimentoSelecionadosUnificar = []
            },  
            cancelarSelecionarUnificar(etapa){                
                this.habilitarSelecionarUnificar(etapa,false);
                this.atendimentoSelecionadosUnificar = [];                
                this.listaPosVendasEntrega.forEach((item) => item.unificarAtendimento =false );
                this.listaPosVendasParaInstalacao.forEach((item) => item.unificarAtendimento =false );
                this.listaPosVendasEmInstalacao.forEach((item) => item.unificarAtendimento =false );
                this.listaPosVendasInstalacaoIncompleta.forEach((item) => item.unificarAtendimento =false );
            },          
            confirmarUnificar(etapa){

                if(this.atendimentoSelecionadosUnificar.length<=1){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('POSVENDAS.MENSAGEMUNIFICARATENDIMENTOS'),
                        type: 'is-warning',
                        hasIcon: true
                    });  
                    
                    return false;
                }

                this.$buefy.dialog.confirm({
                title: 'Confirmar',
                message: this.$t('POSVENDAS.MENSAGEMCONFIRMARUNIFICARATENDIMENTOS'),
                type: 'is-warning',
                hasIcon: true,
                cancelText: 'Não',
                confirmText: 'Sim',
                onConfirm: () => {

                    try {                            
                            this.$http.post(`/api/posvenda/ConfirmarUnificarAtendimentos`,this.atendimentoSelecionadosUnificar)
                            .then(res => res.data)
                            .then(data => {
                                return true;                                 
                            }).finally(() => {                                
                                this.posvendasVistoria();                                 
                                this.posvendasEntrega(); 
                                this.posvendasParaInstalacao(); 
                                this.posvendasEmInstalacao();                                                                                                                                                
                                this.posvendasInstalacaoIncompleta();                                                                 
                                this.habilitarSelecionarUnificar(etapa,false);                             
                            });                            

                            
                        } catch (e) {                                                  
                            console.error(e);
                        }

                    }
                });

                
            },     
            
            

            changed(item){

                if(item.dataHoraAgendamentoEntrega ==null){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('POSVENDAS.AGENDAMENTOENTREGAOBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true                                          
                    });  
                    setTimeout(() => { item.liberarEntrega = false;},500);                   
                    return;                           
                }

                if(item.liberarEntrega){
                    this.entregasSelecionados.push(item);
                }else{
                    this.entregasSelecionados = this.entregasSelecionados.filter(x=>x.guiaId != item.guiaId);
                }
                    
            },            

            confirmarEntregas(){

                if(this.entregasSelecionados.length<=0){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('POSVENDAS.MENSAGEMLIBERARENTREGASELECIONE'),
                        type: 'is-warning',
                        hasIcon: true
                    });  
                    
                    return false;
                }


                this.$buefy.modal.open({
                    parent: this,
                    component: modalLiberarEntregaPosVenda,
                    props: {
                        entregasSelecionados: this.entregasSelecionados,
                    },
                    events: {
                        recarregarEntregas: this.recarregarEntregas                        
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen:true
                })
            },

            selecionarEntregas(){
                this.habilitaSelecionar = true;
                this.entregasSelecionados = []
            },            
            cancelarSelecionarEntregas(){
                this.habilitaSelecionar = false;
                this.entregasSelecionados = [];
                this.listaPosVendasEntrega.forEach((item) => item.liberarEntrega =false );
            },
            recarregarEntregas(){
                this.cancelarSelecionarEntregas();
                this.posvendasEntrega(); 
                this.posvendasParaInstalacao(); 
                this.posvendasEmInstalacao(); 
            },            
            tratamentoQuebraLinha(texto){                
                return (texto) ? texto.replaceAll('\\n', '<br/>') : '';
            }, 
            incluirAssistencia(item){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalCadastroAssistenciaPosVenda,
                    props: {
                        id: item.id,
                        posvendaid: item.posVendaId,
                        clienteCodigoExterno: item.clienteCodigoExterno,
                        guiaId: item.guiaId                       
                    },
                    events: {
                        carregarAssistencias: this.carregarAssistencias                        
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen:true
                })
            },     
            mostraMensagemAguardandoAprovacaoFabrica(item){

                if(item.dataHoraDevolucaoSupervisor ==null){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('POSVENDAS.AGUARDANDOFABRICAAPROVAR'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                }else{

                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('POSVENDAS.AGUARDANDOSUPERVISORRESOLVER'),
                        type: 'is-warning',
                        hasIcon: true
                    });                    

                }
                

            },                       
            mostraMensagemAguardandoVistoria(){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('POSVENDAS.MENSAGEMAGUARDANDOVISTORIA'),
                        type: 'is-warning',
                        hasIcon: true
                    });
            },                             
            tipoAgendamentoStyle(evento) {
                return 'color: #' + evento.tipoAgendamentoCor + '!important'
            },                     
            carregarAgenda() {      

                const tipo = 3;
                const params = [                    
                    `unidadeId=${this.unidadeId}`,                                                      
                    `tipo=${tipo}`,
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/AgendaHojePosVenda?${params.join('&')}`)                
                    .then(res => {
                        this.agendaHoje = res.data
                    });
            },         

            async loadAsyncData() {    

                await this.carregarAgenda(); 
                await this.posvendasObrasPlantas(); 
                await this.posvendasPlantasTecnicas();                                          
                await this.posvendasVistoria(); 
                await this.posvendasInstalacaoFutura(); 
                await this.posvendasEntrega(); 
                await this.posvendasParaInstalacao(); 
                await this.posvendasEmInstalacao(); 
                await this.posvendasAssistenciaTecnicas();
                await this.posvendasEnviarAstecFabrica();                
                await this.posvendasFinalizarEfinance(); 
                await this.posvendasFinalizarFinanceiro();                 
                await this.posvendasInstalacaoIncompleta(); 
                                            
            },

            carregarAssistencias(){ 
                this.posvendasAssistenciaTecnicas();
                this.posvendasEnviarAstecFabrica();                
                this.posvendasFinalizarEfinance(); 
                this.posvendasFinalizarFinanceiro();                                 
            },

            posvendasPlantasTecnicas(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderPlantasTecnicas}`                                                                                                                                                                                                                                                                                                                                                                                                                       
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/PlantasInstalacaoPosVenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPosVendasPlantasInstalacao = data;
                    })
                    .catch((error) => {
                        this.listaPosVendasPlantasInstalacao = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            }, 
            posvendasObrasPlantas(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderObrasPlantas}`                                                         
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/ObrasPlantasPosVenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPosVendasObrasPlantas = data;
                    })
                    .catch((error) => {
                        this.listaPosVendasObrasPlantas = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },   
            posvendasVistoria(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/VistoriaPosVenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPosVendasVistoria = data;
                    })
                    .catch((error) => {
                        this.listaPosVendasVistoria = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },             
            posvendasInstalacaoFutura(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/InstalacaoFuturaPosVenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPosVendasInstalacaoFutura = data;
                    })
                    .catch((error) => {
                        this.listaPosVendasInstalacaoFutura = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            }, 
            posvendasEntrega(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderEntrega}`                                                                                                                                   
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`); 
                
                if(this.dataHoraInicialEntrega !== null && this.dataHoraFinalEntrega !== null  ){
                    params.push(`dataHoraInicial=${this.$moment(this.dataHoraInicialEntrega).format('YYYY-MM-DDTHH:mm:ss')}`);                
                    params.push(`dataHoraFinal=${this.$moment(this.dataHoraFinalEntrega).format('YYYY-MM-DDTHH:mm:ss')}`);                
                }                    

                this.$http.get(`/api/dashboard/EntregaPosVenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPosVendasEntrega = data;
                    })
                    .catch((error) => {
                        this.listaPosVendasEntrega = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            }, 

            
            posvendasParaInstalacao(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderParaInstalacao}`                                                                                                                                                                                                             
                ];

                if(this.dataHoraInicialParaInstalacao !== null && this.dataHoraFinalParaInstalacao !== null  ){
                    params.push(`dataHoraInicial=${this.$moment(this.dataHoraInicialParaInstalacao).format('YYYY-MM-DDTHH:mm:ss')}`);                
                    params.push(`dataHoraFinal=${this.$moment(this.dataHoraFinalParaInstalacao).format('YYYY-MM-DDTHH:mm:ss')}`);                
                }
                
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/ParaInstalacaoPosVenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPosVendasParaInstalacao = data;
                    })
                    .catch((error) => {
                        this.listaPosVendasParaInstalacao = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            }, 
            posvendasEmInstalacao(){

                this.totalAtrasoAssistenciasEmInstalacao = 0;
                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderEmInstalacao}`                                                                                                                                                                                                                                                                   
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/EmInstalacaoPosVenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPosVendasEmInstalacao = data;
                    })
                    .catch((error) => {
                        this.listaPosVendasEmInstalacao = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false;
                        this.listaPosVendasEmInstalacao.forEach((item) => this.totalAtrasoAssistenciasEmInstalacao += item.assistenciasAtrasadas );
                    })                

            }, 
            posvendasAssistenciaTecnicas(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`                    
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/AssistenciaTecnicasPosVenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPosVendasAssistenciaTecnicas = data;
                    })
                    .catch((error) => {
                        this.listaPosVendasAssistenciaTecnicas = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },
            posvendasEnviarAstecFabrica(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/EnviarAstecFabricaPosVenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPosVendasEnviarAstecFabrica = data;
                    })
                    .catch((error) => {
                        this.listaPosVendasEnviarAstecFabrica = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },              
            posvendasFinalizarEfinance(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/FinalizarEfinancePosVenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPosVendasFinalizarEFinance = data;
                    })
                    .catch((error) => {
                        this.listaPosVendasFinalizarEFinance = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            }, 
            posvendasFinalizarFinanceiro(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/FinalizarFinanceiroPosVenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPosVendasFinalizarFinanceiro = data;
                    })
                    .catch((error) => {
                        this.listaPosVendasFinalizarFinanceiro = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },                
            posvendasInstalacaoIncompleta(){

                this.totalAtrasoAssistenciasInstalacaoIncompleta=0;
                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderInstalacaoIncompleta}`                                                                                                                                                                                                                                                                                                                                             
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/InstalacaoIncompletaPosVenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPosVendasInstalacaoIncompleta = data;
                    })
                    .catch((error) => {
                        this.listaPosVendasInstalacaoIncompleta = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false;
                        this.listaPosVendasInstalacaoIncompleta.forEach((item) => this.totalAtrasoAssistenciasInstalacaoIncompleta += item.assistenciasAtrasadas );
                    })                

            },                                                                                     
                                     
            isVendedor(){                
                return this.usuario.nivel == 'Vendedor' || this.usuario.nivel.includes('Vendedor');
            },        
            isConferente(){                
                return this.usuario.nivel == 'Conferente';
            },       
            loadTotalNotificacoesFuncionario() {
                
                this.$http.get('/api/notificacao/retornaTotalNotificacoesFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                        this.$store.commit('componentes/SET_TOTAL_NOTIFICACAO',data)
                    })
                    .catch((error) => {                        
                        throw error
                    });

                this.$http.get('/api/notificacao/RetornaTotalMensagensFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                            this.$store.commit('componentes/SET_TOTAL_MENSAGENS',data)
                        
                    })
                    .catch((error) => {                        
                        throw error
                    });                      
                
            },                  
                            
        },
        mounted() {            
            this.loadAsyncData(); 
            this.loadTotalNotificacoesFuncionario();     
            this.verificaPesquisarCliente();            
        }
    }
</script>