<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALLISTARAGENDAMENTOSCLIENTE.LISTARAGENDAMENTOS')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">

                  
            <div class="panel">            
                <div class="panel-block"> 
                    <table  v-if="agendamentos !=null && agendamentos.length>0"   class="table  is-fullwidth is-striped">
                        <thead>
                            <tr>                                
                                <th>Data</th>
                                <th>Responsável</th>
                                <th>Observação</th>
                                
                            </tr>                                                
                        </thead>
                        <tbody>                                                
                            <tr v-for="(agendamento, index) in agendamentos" :key="index">
                                
                                <td>
                                    <span v-if="(agendamento.agendamentoAutomatico==false)" style="display:flex" >                                        
                                        <b-icon :style="tipoAgendamentoStyle(agendamento)"  size="is-small" icon="square-rounded"></b-icon>                                                                                    
                                        <p> {{agendamento.tipoAgendamentoNome}} - {{ agendamento.dataHora }}</p>
                                    </span>
                                </td>                                
                                <td>{{agendamento.funcionarioResponsavelNome}} </td>                            
                                <td>{{agendamento.observacao}} </td>
                            </tr>
                        </tbody>                        
                    </table>  
                </div>


            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button @click="$emit('close');  ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            agendamentos: Array,               
        },
        data() {
            return {                                              
                
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
              
        }, 
        methods:{
            tipoAgendamentoStyle(evento) {
                return 'color: #' + evento.tipoAgendamentoCor + '!important'
            },  
        }       
    }
</script>