<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALLISTARASSISTENCIAS.ASSISTENCIAS')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('SISTEMA.ATENCAO')}}</h5>                
                <p>{{$t('MODALLISTARASSISTENCIAS.AVISO')}}</p>
            </b-notification>            
            <div class="panel">

                <p class="panel-block">
                    <b-field :label="$t('MODALLISTARASSISTENCIAS.AGENDAMENTO')">
                    </b-field>
                </p>
                <div class="panel-block">                    
                    <b-datetimepicker ref="data"                                        
                                    :placeholder="$t('AGENDAMENTO.SELECIONARHORA')"
                                    v-model="prazo"
                                    icon="calendar-today"       
                                    :min-datetime="new Date()"                             
                                    :timepicker="{ enableSeconds: false, hourFormat: '24' }"
                                    horizontal-time-picker>
                    </b-datetimepicker>                                                                         
                </div>                
                 
                <p  class="panel-block">
                    <b-field :label="$t('MODALLISTARASSISTENCIAS.ASSISTENCIAS')">
                    </b-field>
                </p>                
                <div class="panel-block"> 
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>              
                                <th>
                                    <b-checkbox  @input="changedAll()"
                                                    size="is-small" type="is-info"  v-model="selecionadoTodos"></b-checkbox >                                                                                                                        
                                </th>                                                                      
                                <th class="is-size-7">Título</th>
                                <th class="is-size-7">Descrição</th>                                                    
                                <th class="is-size-7">Agendado? </th>                                                    
                                <th class="is-size-7">Elists</th>                                                   
                  
                            </tr>                                                
                        </thead>
                        <tbody> 
                            <tr  :class="item.atraso ? 'bg-atraso' : '' " v-for="(item, index) in listaAssistencias.filter(x=>x.dataHoraCancelado ==null && x.dataHoraConcluido ==null)" :key="index">                                                    
                                <td>
                                        <b-checkbox  @input="changed(item)"
                                        size="is-small" type="is-info"  v-model="item.selecionado"></b-checkbox >                                    
                                </td>                                   
                                <td class="is-size-7">  
                                    <span>                                                                                                            
                                        <b>CUSTO DE: </b> {{item.custo}} 
                                    </span> 
                                    <br>                  
                                    <span>                                                
                                        <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}}                                                             
                                    </span>                                                                                                               
                                    <br>     
                                    {{item.titulo}}   
                                        
                                                                                                                                                                
                                </td>
                                <td class="is-size-7"><span v-html="tratamentoQuebraLinha(item.observacao)"></span> </td>                                                    
                                <td class="is-size-7">
                                    <span v-if="item.dataHoraAgendamento !=null">
                                            {{item.dataHoraAgendamento}} 
                                                                        
                                        </span> 
                                        <span v-else>a definir 
                                                                     
                                        </span>                                                          
                                </td>                                                    
                                <td class="is-size-7">{{item.eList}} </td>                                                    
            
                            </tr>
                        </tbody>
                    </table>
                </div>
                      
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close');  ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            posVendaId: Number
        },
        data() {
            return {                                              
                salvandoDados :false,                
                listaAssistencias:[],
                assistenciaSelecionado:[],
                selecionadoTodos:false,
                prazo:null                                  
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {                        
            this.carregarAssistencias();
        },    
        methods: {
            tratamentoQuebraLinha(texto){                
                return (texto) ? texto.replaceAll('\\n', '<br/>') : '';
            },                         
            changedAll(){

                if(this.selecionadoTodos){

                    this.listaAssistencias.forEach((item) => {
                        item.selecionado=true;
                        this.changed(item);
                    });                    

                }else{

                    this.listaAssistencias.forEach((item) => {
                        item.selecionado=false;
                        this.changed(item);
                    });                        

                }

            },               
            changed(assistencia){

                if(assistencia.selecionado){
                    this.assistenciaSelecionado.push(assistencia.id);
                }else{
                    this.assistenciaSelecionado = this.assistenciaSelecionado.filter(x=>x.id != assistencia.id);
                }
                    
            },                  
            carregarAssistencias(){
                this.isLoading=true;
                this.$http.get('/api/assistencia/retornarPorPosVendaId?id=' + this.posVendaId)
                        .then(res => res.data)
                        .then(data => {

                            if (data != null && data.length>0 ) {
                                
                                this.listaAssistencias = data?.map( (d) => {
                                        return {
                                            id: d.id,                                        
                                            clienteId: d.clienteId,                                        
                                            titulo: d.titulo,
                                            observacao: d.observacao,
                                            dataHoraCadastro: moment(d.dataHoraCadastro).format('DD/MM/YYYY HH:mm:ss'),
                                            funcionarioConferenteNome: d.funcionarioConferenteNome,                                            
                                            custo: d.custoNome,
                                            eList: d.eList,
                                            faturado: d.faturado,
                                            dataHoraAssistenciaTecnica: (d.dataHoraAssistenciaTecnica) ? moment(d.dataHoraAssistenciaTecnica).format('DD/MM/YYYY HH:mm:ss') : null, 
                                            dataHoraEnviarFabrica: (d.dataHoraEnviarFabrica) ? moment(d.dataHoraEnviarFabrica).format('DD/MM/YYYY HH:mm:ss') : null, 
                                            dataHoraFinalizarEfinance: (d.dataHoraFinalizarEfinance) ? moment(d.dataHoraFinalizarEfinance).format('DD/MM/YYYY HH:mm:ss') : null, 
                                            dataHoraConcluido: (d.dataHoraConcluido) ? moment(d.dataHoraConcluido).format('DD/MM/YYYY HH:mm:ss') : null, 
                                            dataHoraCancelado: (d.dataHoraCancelado) ? moment(d.dataHoraCancelado).format('DD/MM/YYYY HH:mm:ss') : null, 
                                            dataHoraPrazo: (d.dataHoraPrazo) ? moment(d.dataHoraPrazo).format('DD/MM/YYYY') : null,
                                            dataHoraLiberacaoEntrega: (d.dataHoraLiberacaoEntrega) ? moment(d.dataHoraLiberacaoEntrega).format('DD/MM/YYYY') : null,   
                                            dataHoraAgendamento: (d.dataHoraAgendamento) ? moment(d.dataHoraAgendamento).format('DD/MM/YYYY') : null,                                               
                                            codigoLote: d.codigoLote,                                         
                                            posics: d.posics,
                                            atraso: d.atraso                                             
                                        }
                                });                                                                                   
                            }                            

                        }).finally(() => {
                            this.isLoading = false;                             
                        });                
            },  

            atualizarAssistencias(){    
                    this.salvandoDados=true;

                    this.$http.post(`/api/Assistencia/DataHoraAgendamentoAssistencia?prazo=${moment(this.prazo).format('YYYY-MM-DDTHH:mm:ss')}`,this.assistenciaSelecionado)
                    .then((res) => {
                        this.salvandoDados=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;                         
                        this.$emit('loadData');                                                          
                        this.$emit('close');                                                          
                    });  
                
            },                  
            async confirmar() {
                              
                if(this.assistenciaSelecionado.length==0 || this.prazo == null){
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('MODALLISTARASSISTENCIAS.MENSAGEMSELECIONEASSISTENCIA'),
                            type: 'is-warning',
                            hasIcon: true
                        }); 
                        return false;                              
                }else{                    
                    this.atualizarAssistencias();                                
                }
                
      
                
            },
            

        }
    }
</script>