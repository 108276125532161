<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <div class="columns">
            <div class="column">
                <div  class="panel">
                    <p  class="panel-heading background-2">
                        {{$t('ATENDIMENTO.INCLUIRGUIA')}}
                    </p>          
                    <p class="panel-block is-justified-between">
                        {{$t('ATENDIMENTO.NOMECLIENTE')}}               
                    </p>
                    <form @submit.prevent="procurarCliente" method="get" class="panel-block">
                        <b-autocomplete field="nome"
                                        v-model="cliente"
                                        :placeholder="$t('ATENDIMENTO.PROCURARCLIENTE')"
                                        :data="listaClienteAutocomplete"
                                        :loading="isFetching"
                                        @keydown.native.enter.prevent="procurarCliente()"
                                        @typing="procurarClienteAutocomplete"
                                        @select="option => {selected = option; this.procurarCliente()}">
                            <template slot-scope="props">
                                <div class="media">
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.codigoExterno }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                        <br>
                                        <small>
                                            <b>{{ props.option.dataDeNascimentoIdade }}</b>                                            
                                        </small>
                                    </div>
                                </div>
                            </template>
                        </b-autocomplete>
                        <b-button native-type="submit" icon-left="magnify"></b-button>
                        <b-button  v-if="isInRole('integracao-clientes-elist')" type="button" class="is-info" @click="atualizarClientes()" icon-left="account-arrow-left" title="Atualizar clientes do EList"></b-button>
                    </form>
                    <div class="panel-block" v-if="isInRole('guia-listar')">
                        <router-link :to="{ name: 'guia-list'}" class="button is-link is-outlined is-fullwidth is-small">
                            {{$t('ATENDIMENTO.LISTARATENDIMENTOS')}}
                        </router-link>
                        <router-link :to="{ name: 'guia-list', query: { tipo:'negociacao' } }" class="button is-primary is-outlined is-fullwidth is-small">
                            {{$t('ATENDIMENTO.VENDASPARAEFETIVAR')}}
                            <span class="ml-1 tag is-primary">{{ listaProjetosPendenteEfetivar.length}}</span>  
                            <span v-if="listaProjetosPendenteEfetivar.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaProjetosPendenteEfetivar.filter(x=>x.atraso).length }}</span>                                                  
                        </router-link>                        
                    </div>
                </div>
            </div>            
            <div class="column is-one-third">
                <div v-if="isInRole('agenda-consultar')" class="panel">
                    <p class="panel-heading background-2">
                        {{$t('SISTEMA.AGENDA')}}
                    </p>
                    <p v-if="agendaHoje.length > 0" class="panel-block">
                         {{$t('ATENDIMENTO.CLIENTESAGENDADOS')}}
                    </p>
                    <p v-if="agendaHoje.length == 0" class="panel-block">
                         {{$t('ATENDIMENTO.NENHUMCLIENTEAGENDADO')}}
                    </p>
                    <table class="table is-fullwidth is-striped is-hoverable m-0">     
                        <tbody>
                            <tr v-for="(item, index) in agendaHoje" v-bind:key="index">
                                <td> 
                                    <span class="is-size-7 is-flex">
                                        <b-icon :style="tipoAgendamentoStyle(item)"  size="is-small" icon="square-rounded"></b-icon>
                                        <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                            <template v-slot:content>
                                                <b>Responsável: </b> {{item.funcionarioResponsavelNome}}
                                            </template>                                             
                                            {{item.tipoAgendamentoNome}} - {{ tratarNomeCliente(item) }}
                                        </b-tooltip>
                                    </span>                                    
                                </td>
                                <td> <span class="tag is-info">{{item.dataHora | moment('HH:mm')}}</span> </td>
                                <td> 
                                    <router-link v-if="item.guiaId && item.guiaId >0" :to="{ name: 'guia', params: { id: item.guiaId}}" 
                                    class="button is-small is-primary" title="Abrir Atendimento">
                                        <b-icon icon="pencil" size="is-small"></b-icon>
                                    </router-link>                                                                                                                
                                </td>
                            </tr>
                        </tbody>
                    </table>
     
                    <div class="panel-block"  v-if="isInRole('atendimento-agendamento')" >
                        <router-link :to="{ name: 'agenda', params: { tipo: 1,voltarTela: 'atendimento' } }" class="button is-link is-outlined is-fullwidth">
                            {{$t('ATENDIMENTO.ABRIRCALENDARIO')}}
                        </router-link>
                        <router-link :to="{ name: 'agenda-relacao', query: { unidadeId: unidadeId } }" class="button is-link is-outlined is-fullwidth">
                            {{$t('ATENDIMENTO.AGENDAS')}}
                        </router-link>                        
                    </div>
                </div>
            </div>

        </div>

        <div class="columns">
           
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderPrioridadeADefinir==2 ? orderPrioridadeADefinir= 0 : orderPrioridadeADefinir++; ordenar('prioridadeADefinir')">
                        <span><b-icon :icon="iconeOrderPrioridadeADefinir"  size="is-small"> </b-icon></span>
                        {{$t('ATENDIMENTO.DEFINIRPRIORIDADE')}}   
                        ({{ listaPrioridadesDefinir.length}})
                        <span v-if="listaPrioridadesDefinir.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaPrioridadesDefinir.filter(x=>x.atraso).length }}</span>                          
                    </p>

                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr  :class="retornaEstiloLinha(item)" v-for="(item, index) in listaPrioridadesDefinir" v-bind:key="index">
                                
                                <td>
                                    <span>
                                        {{item.clienteCodigoExterno}} 
                                        <b-icon v-if="item.dataHoraVenda !=null || item.dataHoraEfetivacao !=null "
                                                        icon="currency-usd"                                                                                       
                                                        :title="$t('GUIALIST.ATENDIMENTOVENDAEFETIVADO')"
                                                        type="is-success"></b-icon>    
                                    </span>
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                            <br>
                                            <span v-if="item.temperatura>0">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b> TEMPERATURA: </b> {{item.temperaturaNome}} 
                                                 </small>                                            
                                            </span>
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                    </b-tooltip>
                                </td>                                
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('atendimento-vendas')" :to="{ name: 'guia', params: { id: item.id}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>          
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.id)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                  
                                    </div>
                                    <br>                                
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                                </td>                                
                            </tr>  
                        </tbody>                                                 
                    </table>
                    
                </div>
            </div>

            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderprojetosEmEspera==2 ? orderprojetosEmEspera= 0 : orderprojetosEmEspera++; ordenar('projetosEmEspera')">
                        <span><b-icon :icon="iconeOrderprojetosEmEspera"  size="is-small"> </b-icon></span>
                        {{$t('ATENDIMENTO.AUTORIZARPROJETO')}}  
                        <span v-if="!isVendedor()"> ({{listaProjetosEspera?.length}}) </span>
                        <span v-else> ( {{listaProjetosEspera?.length}} / {{totalProjetosEspera}} ) </span>
                        <span v-if="listaProjetosEspera.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaProjetosEspera.filter(x=>x.atraso).length }}</span>                          
                    </p>

                    <b-collapse class="panel m-0" animation="slide"  v-model="collapses[0]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Novo ({{ listaProjetosEspera.filter(x=>!x.possuiAlteracao).length  }})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth">
                        <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaProjetosEspera.filter(x=>!x.possuiAlteracao)" v-bind:key="index">
                            <td>
                                {{item.clienteCodigoExterno}}                              
                                <div class="is-inline-flex">
                                    <b-icon v-if="item.dataHoraVenda !=null || item.dataHoraEfetivacao !=null "
                                                    icon="currency-usd"                                                                                    
                                                    :title="$t('GUIALIST.ATENDIMENTOVENDAEFETIVADO')"
                                                    type="is-success"></b-icon>                                        
                                    <span class="tag is-light ml-1">
                                        <b v-if="item.dataHoraVendedor == null" :title="$t('ATENDIMENTO.PRAZOVENDEDORPENDENTE')" class="has-text-danger">!</b> 
                                        <b v-else  :title="$t('ATENDIMENTO.PRAZOVENDEDOROK')" @click="alterarconfirmacaoVendedor(item)"  class="cp has-text-success">&radic;</b> 
                                    </span>
                                </div>                            
                                
                            </td>                                
                            <td class="pl-0 pr-0">
                                <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                    <template v-slot:content>
                                        <span>
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}                                        
                                            <span v-if="item.dataHoraApresentacao ==null">                                                
                                                <br> <small style="margin-top: -5px !important;display: block; font-size: 0.7em;"><b>PRAZO: </b> {{item.prazoVendedorEmDias}} dias<b> - FALTAM:</b> {{item.prazoRestante}}  DIAS </small>                                            
                                            </span>
                                            
                                            <span v-if="item.temperaturaNome!='NENHUM'">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b> TEMPERATURA: </b> {{item.temperaturaNome}} 
                                                 </small>                                            
                                            </span>                                            
                                        </span> 
                                        <span v-if="item.dataHoraApresentacao">       
                                            <br> <b>PRAZO APRESENTAÇÃO: </b>  {{$moment(item.dataHoraApresentacao).format('DD/MM/YYYY')}}                                                
                                        </span>
                                        <span v-if="item.clienteArquiteto" style="display: block; ">       
                                            <b>ARQUITETO: </b>  {{item.clienteArquiteto}}                                                
                                        </span>   
                                        <span v-if="item.funcionarioProjetistaNome"  style="display: block; ">  
                                            <b>PROJETISTA: </b>  {{item.funcionarioProjetistaNome}}  
                                        </span>                                        
                                    </template>                                             
                                    <span class="is-size-7 highlight">{{item.clienteNome}}</span>
                                </b-tooltip>
                            </td>
                            <td>
                                <div class="is-inline-flex">
                                    <router-link v-if="isInRole('atendimento-gerenciacomercial')" :to="{ name: 'guia', params: { id: item.id}}" class="button is-small is-primary" title="Abrir Atendimento">
                                        <b-icon icon="pencil" size="is-small"></b-icon>
                                    </router-link>                 
                                    <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.id)" 
                                    :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                         
                                    <b-button v-if="item.dataHoraVendedor == null && isInRole('atendimento-vendas')" @click="confirmarProjetoEntregueVendedor(item)" 
                                    class="is-small has-text-success" title="Confirmar Projeto?" ><b>&radic;</b></b-button>                                                                                                                                                            

                                    <b-button v-if="item.dataHoraVendedor == null && isInRole('atendimento-vendas') && item.atraso" @click="confirmarAlteracaoProjetoVendedor(item.id)" 
                                    class="is-small has-text-danger" title="Alterar Prazo do Vendedor?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                

                                    <b-button v-if="item.dataHoraVendedor != null && isInRole('atendimento-vendas')" @click="confirmarAlteracaoPrazoApresentacao(item)" 
                                    class="is-small has-text-danger" title="Alterar Prazo de Apresentação?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                

                                </div>
                                <br>                                
                                <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                                
                            </td>
                        </tr>
                        </table>                         

                    </b-collapse>  
                    
                    <b-collapse class="panel" animation="slide"  v-model="collapses[1]" >
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"                                
                                :aria-expanded="props.open">
                                <p class="card-header-title">
                                    Alteração ({{ listaProjetosEspera.filter(x=>x.possuiAlteracao).length  }})
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>

                        <table class="table is-fullwidth">
                            <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaProjetosEspera.filter(x=>x.possuiAlteracao)" v-bind:key="index">
                                <td>
                                    {{item.clienteCodigoExterno}}                              
                                    <div class="is-inline-flex">
                                        <b-icon v-if="item.dataHoraVenda !=null || item.dataHoraEfetivacao !=null "
                                                        icon="currency-usd"                                                                                           
                                                        :title="$t('GUIALIST.ATENDIMENTOVENDAEFETIVADO')"
                                                        type="is-success"></b-icon>                                        
                                        <span class="tag is-light ml-1">
                                            <b v-if="item.dataHoraVendedor == null" :title="$t('ATENDIMENTO.PRAZOVENDEDORPENDENTE')" class="has-text-danger">!</b> 
                                            <b v-else  :title="$t('ATENDIMENTO.PRAZOVENDEDOROK')"  @click="alterarconfirmacaoVendedor(item)"  class="cp has-text-success">&radic;</b> 
                                        </span>
                                    </div>                            
                                    
                                </td>                                
                                <td class="pl-0 pr-0">
                                    <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                        <template v-slot:content>
                                        <span>
                                            <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}                                        
                                            <span v-if="item.dataHoraApresentacao ==null">                                                
                                                <br> <small style="margin-top: -5px !important;display: block; font-size: 0.7em;"><b>PRAZO: </b> {{item.prazoVendedorEmDias}} dias<b> - FALTAM:</b> {{item.prazoRestante}}  DIAS </small>                                            
                                            </span>
                                            
                                            <span v-if="item.temperatura>0">                                                
                                                <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                    <b> TEMPERATURA: </b> {{item.temperaturaNome}} 
                                                 </small>                                            
                                            </span>                                            
                                        </span> 
                                        <span v-if="item.dataHoraApresentacao">       
                                            <br> <b>PRAZO APRESENTAÇÃO: </b>  {{$moment(item.dataHoraApresentacao).format('DD/MM/YYYY')}}                                                
                                        </span>
                                        <span v-if="item.clienteArquiteto" style="display: block; ">       
                                            <b>ARQUITETO: </b>  {{item.clienteArquiteto}}                                                
                                        </span>   
                                        <span v-if="item.funcionarioProjetistaNome"  style="display: block; ">  
                                            <b>PROJETISTA: </b>  {{item.funcionarioProjetistaNome}}  
                                        </span>                                                     
                                        </template>                                             
                                        <span class="is-size-7 highlight">{{item.clienteNome}}</span>
                                            
                                    </b-tooltip>
                                </td>
                                <td>
                                    <div class="is-inline-flex">
                                        <router-link v-if="isInRole('atendimento-gerenciacomercial')" :to="{ name: 'guia', params: { id: item.id}}" class="button is-small is-primary" title="Abrir Atendimento">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>                 
                                        <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.id)" 
                                        :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                         
                                        <b-button v-if="item.dataHoraVendedor == null && isInRole('atendimento-vendas')" @click="confirmarProjetoEntregueVendedor(item)" 
                                        class="is-small has-text-success" title="Confirmar Projeto?" ><b>&radic;</b></b-button>                                                                                                                                                            

                                        <b-button v-if="item.dataHoraVendedor == null && isInRole('atendimento-vendas') && item.atraso" @click="confirmarAlteracaoProjetoVendedor(item.id)" 
                                        class="is-small has-text-danger" title="Alterar Prazo do Vendedor?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                

                                        <b-button v-if="item.dataHoraVendedor != null && isInRole('atendimento-vendas')" @click="confirmarAlteracaoPrazoApresentacao(item)" 
                                        class="is-small has-text-danger" title="Alterar Prazo de Apresentação?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                        

                                    </div>
                                    <br>
                                    <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>

                                </td>
                            </tr>
                        </table>                         
                    </b-collapse>                        


                                
                </div>
            </div>
            
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2 cp" @click="orderprojetosEmAndamento==2 ? orderprojetosEmAndamento= 0 : orderprojetosEmAndamento++; ordenar('projetosEmAndamento')">
                        <span><b-icon :icon="iconeOrderprojetosEmAndamento"  size="is-small"> </b-icon></span>
                        {{$t('ATENDIMENTO.PROJETOANDAMENTO')}}
                        <span v-if="!isProjetista()"> ({{listaProjetosEmAndamento?.length}}) </span>
                        <span v-else> ( {{listaProjetosEmAndamento?.length}} / {{totalProjetosEmAndamento}} ) </span>
                        <span v-if="listaProjetosEmAndamento.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaProjetosEmAndamento.filter(x=>x.atraso).length }}</span>                                                  
                    </p>

                    <table class="table is-fullwidth">
                        <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaProjetosEmAndamento" v-bind:key="index">
                            <td>
                                {{item.clienteCodigoExterno}}
                                         
                            </td>                                
                            <td class="pl-0 pr-0">
                                <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                    <template v-slot:content>
                                        <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}<br>
                                        
                                        <span>
                                            <b>PROJETISTA: </b> {{item.funcionarioProjetistaNome}}
                                            <br><small style="margin-top: -5px !important;display: block; font-size: 0.7em;"><b>PRAZO:</b>  {{item.prazoProjetoEmDias}} dias<b> - FALTAM:</b>  {{item.prazoRestante}} DIAS </small>
                                            <br>
                                                <span v-if="item.temperatura>0">                                                
                                                    <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                        <b> TEMPERATURA: </b> {{item.temperaturaNome}} 
                                                    </small>                                            
                                                </span>                                            
                                        </span> 
                                        <span v-if="item.clienteArquiteto">       
                                            <b>ARQUITETO: </b>  {{item.clienteArquiteto}}                                                
                                        </span>                                                     
                                        
                                    </template>                                             
                                    <span class="is-size-7 highlight">{{item.clienteNome}}</span>
                                </b-tooltip>
                            </td>
                            <td  v-if="!isProjetista()">
                                <div class="is-inline-flex">
                                    <router-link v-if="isInRole('atendimento-projetos') "   :to="{ name: 'guia', params: { id: item.id}}" class="button is-small is-primary" title="Abrir Atendimento">
                                        <b-icon icon="pencil" size="is-small"></b-icon>
                                    </router-link>  
                                    <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.id)" 
                                    :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                          
                                    <b-button v-if="isInRole('atendimento-projetos') && item.atraso" @click="confirmarAlteracaoProjetoProjetista(item.id)" 
                                    class="is-small has-text-danger" title="Alterar Prazo do Projetista?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                                                
                                </div>
                                <br>
                                <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                            </td>
                            <td  v-else>
                                <div class="is-inline-flex">
                                    <router-link v-if="item.funcionarioProjetistaId == usuario.funcionarioId"  :to="{ name: 'guia', params: { id: item.id}}" class="button is-small is-primary" title="Abrir Atendimento">
                                        <b-icon icon="pencil" size="is-small"></b-icon>
                                    </router-link>   
                                    <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.id)" 
                                    :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                                                         
                                    <b-button v-if="item.funcionarioProjetistaId == usuario.funcionarioId && isInRole('atendimento-projetos') && item.atraso" @click="confirmarAlteracaoProjetoProjetista(item.id)" 
                                    class="is-small has-text-danger" title="Alterar Prazo do Projetista?"> <b-icon icon="clock-alert" size="is-small"></b-icon></b-button>                                                                                                                                                                                                                                
                                </div>
                                <br>
                                <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                            </td>                            
                        </tr>
                    </table>                                                                                       
                </div>
            </div>
            
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2  cp" @click="orderAgendarVisita==2 ? orderAgendarVisita= 0 : orderAgendarVisita++; ordenar('agendarVisita')">
                        <span><b-icon :icon="iconeOrderAgendarVisita"  size="is-small"> </b-icon></span>
                        {{$t('ATENDIMENTO.AGENDAMENTOCLIENTE')}}
                        ({{ listaProjetosAgendarVisita.length}})
                        <span v-if="listaProjetosAgendarVisita.filter(x=>x.atraso).length>0" class="ml-1 tag is-danger blink_me" title="Prazo vencido">{{ listaProjetosAgendarVisita.filter(x=>x.atraso).length }}</span>                          
                    </p> 
                    <table class="table is-fullwidth">
                        <tr :class="retornaEstiloLinha(item)" v-for="(item, index) in listaProjetosAgendarVisita" v-bind:key="index">
                            <td>
                                <span>{{item.clienteCodigoExterno}}</span>
                            </td>                                
                            <td class="pl-0 pr-0">
                                <b-tooltip  size="is-small" position="is-top" type="is-light"  > 
                                    <template v-slot:content>
                                        <b>VENDEDOR: </b> {{item.funcionarioComercialNome}}
                                        <br>
                                        <span v-if="item.temperatura>0">                                                
                                            <small style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                <b> TEMPERATURA: </b> {{item.temperaturaNome}} 
                                            </small>                                            
                                        </span>                                        
                                        <br>
                                        <b>PROJETISTA: </b> {{item.funcionarioProjetistaNome}}
                                        <span v-if="item.clienteArquiteto">       
                                            <br> <b>ARQUITETO: </b>  {{item.clienteArquiteto}}                                                
                                        </span>                                                     
                                    </template>                                             
                                    <span class="is-size-7 highlight">{{item.clienteNome}} </span>
                                </b-tooltip>
                            </td>
                            <td>
                                <div class="is-inline-flex">
                                    <router-link v-if="isInRole('atendimento-agendamento')"   :to="{ name: 'guia', params: { id: item.id}}" class="button is-small is-primary" title="Abrir Atendimento">
                                        <b-icon icon="pencil" size="is-small"></b-icon>
                                    </router-link>  
                                    <b-button  class="is-small"  @click="confirmarInclusaoObservacao( item.id)" 
                                    :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')" icon-left="comment"></b-button>                                                                                                             
                                </div>
                                <br>
                                <span v-if="item.inside" class="is-size-7"><b><i>INSIDE</i></b> </span>
                            </td>
                        </tr>
                    </table> 

                </div>
            </div>            

        </div>
                                 
    </section>
</template>


<style scoped>
.has-background-dark {
    background-color: #363636d6 !important;
}



</style>



<script>
    import { mapState, mapGetters } from 'vuex'
    import debounce from 'lodash/debounce'
    import historicoAlteracoes from "@/components/historicoAlteracoes.vue";    
    import modalAlteracaoPrazoVendedor from '@/components/atendimento/modalAlteracaoPrazoVendedor.vue'
    import modalAlteracaoPrazoApresentacao from '@/components/atendimento/modalAlteracaoPrazoApresentacao.vue'
    import modalConfirmarProjetoVendedor from '@/components/atendimento/modalConfirmarProjetoVendedor.vue'
    import modalJustificarPrazoAgendaGuia from '@/components/atendimento/modalJustificarPrazoAgendaGuia.vue'
    import modalAlteracaoPrazoProjetista from '@/components/atendimento/modalAlteracaoPrazoProjetista.vue'
    import modalObservacaoGuia  from '@/components/atendimento/modalObservacaoGuia'
    import modalIntegracaoEFinance  from '@/components/atendimento/modalIntegracaoEFinance.vue'
    import modalRemoveConfirmarProjetoVendedor  from '@/components/atendimento/modalRemoveConfirmarProjetoVendedor.vue'
            

    export default {
        data() {
            return {                                
                agendaHoje: [],
                listaClienteAutocomplete: [],
                cliente: null,                
                selected: null,
                listaPrioridadesDefinir: [],
                listaProjetosEspera: [],
                totalProjetosEspera: 0,
                listaProjetosEmAndamento: [],
                totalProjetosEmAndamento: 0,
                listaProjetosAgendarVisita: [],
                listaProjetosPendenteEfetivar: [],
                isLoading: false, 
                isFetching: false, 


                orderPrioridadeADefinir: 0,
                iconeOrderPrioridadeADefinir:'',
                orderprojetosEmEspera: 0,
                iconeOrderprojetosEmEspera:'',                                
                orderprojetosEmAndamento: 0,
                iconeOrderprojetosEmAndamento:'',
                orderAgendarVisita: 0,
                iconeOrderAgendarVisita:'',  
                
                collapses: [false, false],
                queryCliente: this.$route.params.queryCliente ?? null,  
                                              
            }
        },
        components: {  
                                  
        },
        computed: {
            ...mapState([
                'unidadeId',
                'config',
                'usuario'                
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {            
            unidadeId: function(novaUnidade) {                 
                this.loadAsyncData()                                                
            },
        },         
        methods: {
            tratarNomeCliente(item){

                if(this.isVendedor() && item.tipoAgendamentoRestricaoVendedor && item.funcionarioResponsavelId != this.usuario.funcionarioId){
                    return 'Reservado';
                }

                return item.clienteNome ?? item.nome;

            },
            verificaPesquisarCliente(){
                
                if(this.queryCliente){
                    this.isLoading = true;
                    setTimeout(() => { this.pesquisarCliente(this.queryCliente)},5000);                                    
                }

            },            
            pesquisarCliente(queryCliente){
                this.isLoading = false;                             
                this.collapsesOpenAll();                    
                setTimeout(() => { this.barraRolagemCliente(this.queryCliente)},1000);                     
             
            },
            barraRolagemCliente(queryCliente){
                const searchElements = document.querySelectorAll(".highlight"); // Seleciona os elementos com a classe 'searchable'


                let found = false;

                searchElements.forEach(element => {
                    const regex = new RegExp(`(${queryCliente})`, "gi");
                    element.innerHTML = element.innerHTML.replace(regex, `<mark>$1</mark>`);

                    // Rola até o primeiro destaque encontrado
                    if (!found) {
                    const firstHighlight = element.querySelector("mark");
                    if (firstHighlight) {
                        firstHighlight.scrollIntoView({ behavior: "smooth", block: "center" });
                        found = true; // Garante que rola apenas para o primeiro match
                    }
                    }
                });   
            },
            collapsesOpenAll() {
                this.collapses = this.collapses.map(() => true);
                this.$forceUpdate();
            },
            collapsesCloseAll() {
                this.collapses = this.collapses.map(() => false);
                this.$forceUpdate();
            },            
            alterarconfirmacaoVendedor(guia){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalRemoveConfirmarProjetoVendedor,
                    props: {
                        id: guia.id                        
                    },
                    events: {
                        loadData: this.guiasProjetosEspera
                    },
                    hasModalCard: false,
                    trapFocus: true
                }) 
            },            
            retornaEstiloLinha(item){

                let temperatura="";

                if (item.temperatura == 1){
                    temperatura = " frio";
                }else if (item.temperatura == 2){
                    temperatura = " morno";
                }else if (item.temperatura == 3){
                    temperatura = " quente";
                }


                if(item.atraso){
                    return 'bg-atraso' + temperatura;
                }else if(item.inside){
                    return 'has-background-dark has-text-white' + temperatura;
                }else{
                    return '' + temperatura;
                }
            },
            verificaOrdenarIcone(item){                
                if(item==0){
                    return '';
                }else if(item==1){
                    return 'sort-alphabetical-ascending';
                }else if(item==2){
                    return 'sort-alphabetical-descending';
                }else{
                    return '';
                }
            },
            ordenar(etapa){
                if(etapa == 'prioridadeADefinir'){
                    this.iconeOrderPrioridadeADefinir = this.verificaOrdenarIcone(this.orderPrioridadeADefinir);   
                    this.guiasPrioridadesDefinir();            
                } else if(etapa == 'projetosEmEspera'){
                    this.iconeOrderprojetosEmEspera = this.verificaOrdenarIcone(this.orderprojetosEmEspera);   
                    this.guiasProjetosEspera();            
                }else if(etapa == 'projetosEmAndamento'){                    
                    this.iconeOrderprojetosEmAndamento = this.verificaOrdenarIcone(this.orderprojetosEmAndamento);   
                    this.guiasProjetosEmAndamento();                 
                } else if(etapa == 'agendarVisita'){
                    this.iconeOrderAgendarVisita = this.verificaOrdenarIcone(this.orderAgendarVisita);   
                    this.guiasProjetosAgendarVisita();                 
                } 
            },

            atualizarClientes(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalIntegracaoEFinance,
                    events: {
                        loadData: this.loadAsyncData
                    },                           
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen:true
                })
            },
            confirmarInclusaoObservacao(guiaId) {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalObservacaoGuia,
                    props: {
                        guia: {id: guiaId}                                              
                    },
                    events: {
                        loadData: this.loadGuia
                    },                    
                    hasModalCard: true,
                    trapFocus: true
                })
            },              
            confirmarProjetoEntregueVendedor(item){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalConfirmarProjetoVendedor,
                    props: {
                        id: item.id,
                        alteracaoProjeto: item.possuiAlteracao                        
                    },
                    events: {
                        loadData: this.guiasProjetosEspera
                    },
                    hasModalCard: true,
                    trapFocus: true
                });                
            }, 
            confirmarAlteracaoPrazoApresentacao(item){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlteracaoPrazoApresentacao,
                    props: {
                        guia: item                        
                    },
                    events: {
                        loadData: this.loadAsyncData
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },            
            confirmarAlteracaoProjetoVendedor(guiaId){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlteracaoPrazoVendedor,
                    props: {
                        id: guiaId,                        
                    },
                    events: {
                        loadData: this.loadAsyncData
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },  
            confirmarJustificarPrazoAgendaGuia(guiaId){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalJustificarPrazoAgendaGuia,
                    props: {
                        id: guiaId                        
                    },
                    events: {
                        loadData: this.loadAsyncData
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },                
            confirmarAlteracaoProjetoProjetista(guiaId){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlteracaoPrazoProjetista,
                    props: {
                        id: guiaId                        
                    },
                    events: {
                        loadData: this.loadAsyncData
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },                                   
            tipoAgendamentoStyle(evento) {
                return 'color: #' + evento.tipoAgendamentoCor + '!important'
            },
            exibirHistorico(id) {
                this.$buefy.modal.open({
                    parent: this,
                    component: historicoAlteracoes,
                    props: {
                        tabela: 'Guia',
                        chave: id,
                        toggle: true,                        
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },             
            async loadAsyncData() {    
                          
                await this.carregarAgenda();  
                await this.guiasPrioridadesDefinir(); 
                await this.guiasProjetosEspera(); 
                await this.guiasProjetosEmAndamento();
                await this.guiasProjetosAgendarVisita();
                await this.guiasProjetosEmNegociacao();  
                
                if(this.isVendedor())
                    this.guiasProjetosEsperaTotal();

                if(this.isProjetista())
                    this.guiasProjetosEmAndamentoTotal();                
                                                 
            },

            isVendedor(){                
                return this.usuario.nivel == 'Vendedor' || this.usuario.nivel.includes('Vendedor');
            },        
            isProjetista(){                
                return this.usuario.nivel == 'Projetista';
            },            
            guiasProjetosEmNegociacao(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,                                                      
                    `dashboard=true`
                ];

                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/guiasProjetosEmNegociacao?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaProjetosPendenteEfetivar = data;
                    })
                    .catch((error) => {
                        this.listaProjetosPendenteEfetivar = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },  
            guiasProjetosAgendarVisita(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderAgendarVisita}`                                                       
                ];

                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/guiasProjetosAgendarVisita?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaProjetosAgendarVisita = data;
                    })
                    .catch((error) => {
                        this.listaProjetosAgendarVisita = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },             
        
            guiasProjetosEmAndamento(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderprojetosEmAndamento}`                                                                        
                ];

                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`); 
                
                if(this.usuario.nivel == 'Projetista' )
                    params.push(`projetistaId=${this.usuario.funcionarioId}`);                                

                this.$http.get(`/api/dashboard/guiasProjetosAndamento?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaProjetosEmAndamento = data;
                    })
                    .catch((error) => {
                        this.listaProjetosEmAndamento = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            }, 
            guiasProjetosEmAndamentoTotal(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];

                this.$http.get(`/api/dashboard/guiasProjetosAndamento?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.totalProjetosEmAndamento = data.length;
                    })
                    .catch((error) => {                        
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },            
            guiasProjetosEspera(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,                                                      
                    `order=${this.orderprojetosEmEspera}`                                                       
                ];

                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/guiasProjetosEspera?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaProjetosEspera = data;
                    })
                    .catch((error) => {
                        this.listaProjetosEspera = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },  
            
            guiasProjetosEsperaTotal(){
                
                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];

                this.$http.get(`/api/dashboard/guiasProjetosEspera?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.totalProjetosEspera = data.length;
                    })
                    .catch((error) => {                        
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })     
                
            },

            guiasPrioridadesDefinir(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`,
                    `order=${this.orderPrioridadeADefinir}`                                                      
                ];
                
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                

                this.$http.get(`/api/dashboard/guiasPrioridadesDefinir?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaPrioridadesDefinir = data;
                    })
                    .catch((error) => {
                        this.listaPrioridadesDefinir = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })                

            },
            procurarCliente() {
                if(this.selected && this.selected.id){
                    const params = { id: this.selected.id, criarGuia: true };
                    this.$router.push({ name: 'cliente', params })
                }
                else {
                    const params = { procurar: this.cliente, criarGuia: true};
                    this.$router.push({ name: 'clientes', params })
                }
            },
            procurarClienteAutocomplete: debounce(function (name) {
                if (!name.length) {
                    this.listaClienteAutocomplete = []
                    return
                }
                this.isFetching = true

                const params = [
                    `procurar=${name}`,
                    `unidadeId=${this.unidadeId}`,    
                    `page=1`                                      
                ].join('&')

                this.$http.get(`/api/manutencao/clientes?${params}`)
                    .then(({ data }) => {
                        this.listaClienteAutocomplete = data.lista
                    })
                    .catch((error) => {
                        this.listaClienteAutocomplete = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),            
            carregarAgenda() {      

                const tipo = 1 ;
                const params = [                    
                    `unidadeId=${this.unidadeId}`,                                                      
                    `tipo=${tipo}`,
                ];
                
                /*
                if(this.isVendedor())
                    params.push(`comercialId=${this.usuario.funcionarioId}`);                
                */
                this.$http.get(`/api/dashboard/AgendaHoje?${params.join('&')}`)                
                    .then(res => {
                        this.agendaHoje = res.data
                    });
            },
            criarGuiaAgendamento(item){
                if(item.guiaId){
                    this.$router.push({ name: 'guia-list', query: { clienteId: item.clienteId } })
                }
                else {
                    this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('ATENDIMENTO.MENSAGEMSEMGUIAATENDIMENTO'),
                            type: 'is-warning',
                            queue: false
                        })
                }
            },
            loadTotalNotificacoesFuncionario() {
                
                this.$http.get('/api/notificacao/retornaTotalNotificacoesFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                        this.$store.commit('componentes/SET_TOTAL_NOTIFICACAO',data)
                    })
                    .catch((error) => {                        
                        throw error
                    });

                this.$http.get('/api/notificacao/RetornaTotalMensagensFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                            this.$store.commit('componentes/SET_TOTAL_MENSAGENS',data)
                        
                    })
                    .catch((error) => {                        
                        throw error
                    });                      
                
            },                 
        },
        mounted() {            
            this.loadAsyncData();    
            this.loadTotalNotificacoesFuncionario();           
            this.verificaPesquisarCliente();                                                           
        }
    }
</script>