<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-info">
            <p class="modal-card-title">{{$t('GUIAATENDIMENTO.RESUMOVENDA')}}
                <b-button  type="button" class="is-info ml-2" @click="listarContratos()" icon-left="file-document-edit" title="Buscar Contrato">Buscar Contrato</b-button>
            </p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="box ">          

                        <div class="columns">
                            <div class="column is-6">
                                <searchIdName :id.sync="guia.clienteCodigoExterno"  :codigoExterno="true"
                                :trazDesativado="true" :label="$t('SISTEMA.CLIENTE')" table="Cliente" :camposAdicionais="'&unidadeId='+unidadeId" :leitura="true">
                                </searchIdName>                                  
                            </div>

                            <div class="column is-6">
                                    <searchIdName  :id.sync="guia.funcionarioComercialId" :trazDesativado="false" 
                                    :label="$t('GUIAATENDIMENTO.VENDEDOR')" table="FuncionarioComercial"
                                    :camposAdicionais="'&unidadeId='+unidadeId" :leitura="camposApenasLeitura()">
                                    </searchIdName>                                                            
                            </div>
                     
                        </div>  
                                                
                        <div class="columns">
                            <div class="column is-6">
                                    <searchIdName  :id.sync="guia.funcionarioProjetistaId" :trazDesativado="false" 
                                    :label="$t('GUIAATENDIMENTO.PROJETISTA')" table="FuncionarioProjetista" :leitura="camposApenasLeitura()">
                                    </searchIdName>      
                                                              
                            </div>

                            <div class="column is-6">
  
                                    <searchIdName  :id.sync="guia.arquitetoId" :trazDesativado="false" 
                                    :label="$t('CLIENTECADASTRO.ARQUITETO')" table="Arquiteto"
                                    :camposAdicionais="'&unidadeId='+unidadeId" :leitura="camposApenasLeitura()">
                                    </searchIdName>                                                                                             
                            </div>
                     
                        </div>                                                         

                        <div class="columns">
                            <div class="column is-12">                                                
                                <table  v-if="ambientes !=null && ambientes.length>0"   class="table  is-fullwidth is-striped">
                                    <thead>
                                        <tr>

                                            <th>Opção</th>
                                            <th>Ambiente</th> 
                                            <th>Tipo</th>
                                            <th>Situação</th>
                                            <th>Valor</th>                                                   
                                        </tr>                                                
                                    </thead>
                                    <tbody>                                                
                                        <tr v-for="(ambiente, index) in ambientes" :key="index">
                                            
                             
                                            <td>{{ ambiente.opcao  }}</td>
                                            <td>{{ ambiente.descricao }}</td>                                                    
                                            <td>{{ ambiente.tipoDescricao }}</td>                            
                                            <td>{{ ambiente.tipoSituacao }}</td> 
                                            <td>R$ {{ ambiente.valor.toString().replace('.',',') }}</td>                                                                                                                                                              
                
                                        </tr>
                                    </tbody>                        
                                </table> 
                            </div>
                        </div> 

                        <div class="columns">   
                            <div class="column is-2">
                                <b-field :label="$t('MODALDOCUMENTOVENDA.VALORPRAZO')"> 
                                    <button class="button is-light" type="button">R$</button>         
                                    <b-input @blur="formataValorVenda()" step=".00000001" :readonly="true"  type="number" v-model="model.valorVenda"> </b-input>
                                </b-field>
                            </div>
                            <div class="column is-2">
                                <b-field :label="$t('MODALDOCUMENTOVENDA.VALORVISTA')"> 
                                    <button class="button is-light" type="button">R$</button>         
                                    <b-input step=".00000001" :readonly="true"  type="number" v-model="model.valorVista"> </b-input>
                                </b-field>
                            </div>
                            <div class="column is-2">
                                <b-field :label="$t('MODALDOCUMENTOVENDA.VALORVISTASEM')"> 
                                    <button class="button is-light" type="button">R$</button>         
                                    <b-input step=".00000001" type="number" :readonly="true"  v-model="model.valorVistaSemIndicador"> </b-input>
                                </b-field>
                            </div>                                                        
                            <div class="column is-2">
                                <b-field :label="$t('MODALDOCUMENTOVENDA.VALORPERCENTUAL')">      
                                    <button class="button is-light" type="button">%</button>  
                                    <b-input  @blur="alterouValorPerc()"  step=".00000001" type="number" v-model="model.valorPercentual"> </b-input>
                                </b-field>
                            </div>
                            <div class="column is-4">
                                <b-field :label="$t('MODALDOCUMENTOVENDA.VALORREAL')">  
                                    <button class="button is-light" type="button">R$</button>      
                                    <b-input  @blur="alterouValor()"  step=".00000001" type="number" v-model="model.valor"> </b-input>
                                </b-field>                                                                                         
                            </div>
                        </div>    
                      

                        <div class="columns">   
                            <div class="column is-6">
                                <b-field :label="$t('MODALDOCUMENTOVENDA.VALORELIST')">
                                    <button class="button is-light" type="button">R$</button>                                                                                                           
                                    <b-input @blur="formataValorEList()" step=".00000001" :readonly="true" type="number" v-model="model.valorElist"> </b-input>
                                </b-field>
                            </div>                            

                            <div class="column is-2">
                                <b-field :label="$t('MODALDOCUMENTOVENDA.ASSOCIACAOPERCENTUAL')">    
                                    <button class="button is-light" type="button">%</button>    
                                    <b-input  @blur="alterouAssociacaoPerc()"  step=".00000001" type="number" v-model="model.associacaoPercentual"> </b-input>
                                </b-field>
                            </div>
                            <div class="column is-4">
                                <b-field :label="$t('MODALDOCUMENTOVENDA.ASSOCIACAOREAL')">    
                                    <button class="button is-light" type="button">R$</button>       
                                    <b-input   @blur="alterouAssociacao()"  step=".00000001" type="number" v-model="model.associacao"> </b-input>
                                </b-field>                                                                                         
                            </div>                            
                        </div>           
                        <div class="columns">                                            
                            <div class="column is-3">
                                
                                <b-field :label="$t('SISTEMA.TIPO')">
                                    <b-select expanded v-model="model.tipo" >                                        
                                        <template v-for="(item, index) in tiposVenda">
                                            <option v-bind:key="index" :value="item.valor">                                                
                                                {{(item.descricao == 'EntregaNormal' ?  'Normal' : (item.descricao == 'Assistencia' ?  'AT Cliente' : item.descricao)) }}
                                            </option>                                            
                                        </template>
                                        <option :value="4"> EntregaFutura</option>                                            
                                    </b-select>                                        
                                </b-field>                                                           
                            </div>                   
                            <div class="column is-3">
                                <b-field v-if="model.entregaFutura || model.tipo==4 " :label="$t('MODALDOCUMENTOVENDA.PRAZOLIBERACAO')">
                                    <b-datepicker  ref="data"                                        
                                                    :placeholder="$t('AGENDAMENTO.SELECIONARHORA')"
                                                    v-model="model.dataHoraBloqueadoFutura"
                                                    icon="calendar-today" >
                                    </b-datepicker>                  
                                </b-field>                                       
                            </div>        
                            <div class="column is-2">                                    
                                <b-field :label="$t('MODALDOCUMENTOVENDA.CAPTADOR')">               
                                    <b-switch v-model="model.opcaocaptador"  type="is-primary" >
                                        {{$t('MODALDOCUMENTOVENDA.MENSAGEMCAPTADOR')}}
                                    </b-switch>                                 
                                </b-field>                                                                         
                            </div> 
                            <div class="column is-2" v-if="model.opcaocaptador" >                                                
                                <b-field :label="$t('MODALDOCUMENTOVENDA.CAPTADOR')">  
                                    <button class="button is-light" type="button">%</button>        
                                    <b-input  @blur="alterouCaptadorPerc()"   step=".00000001" type="number" v-model="model.captadorPercentual"> </b-input>
                                </b-field>           
                                                                                                                               
                            </div>                                    
                            <div class="column is-2" v-if="model.opcaocaptador" >                                                
                                <b-field :label="$t('MODALDOCUMENTOVENDA.CAPTADOR')">   
                                    <button class="button is-light" type="button">R$</button>      
                                    <b-input  @blur="alterouCaptador()"   step=".00000001" type="number" v-model="model.captador"> </b-input>
                                </b-field>                                                                                                          
                            </div>                                 
                                                                                         
                        </div>                                            
                        <div class="columns">   
                            <div class="column is-6">
                                <b-field :label="$t('SISTEMA.OBSERVACAO')">      
                                    <textarea class="textarea" v-model="model.observacao" rows="3"></textarea>      
                                </b-field>
                            </div>
                            <div class="column is-6">
                                <b-field :label="$t('MODALDOCUMENTOVENDA.ALTERACAOFINANCEIRA')">      
                                    <textarea class="textarea" v-model="model.alteracoesFinanceiras" rows="3" ></textarea>      
                                </b-field>                                
                            </div>                            

                        </div>  

                        <div class="columns">                                            
                            <div class="column is-4">
                                <b-field :label="$t('MODALDOCUMENTOVENDA.PRAZOENTREGA')">
                                    <b-select expanded v-model="prazoSelecionado" >
                                        <option :value="null"></option>
                                        <template v-for="(item, index) in prazosVendas">
                                            <option v-bind:key="index" :value="item.id">
                                                {{item.nome}}
                                            </option>
                                        </template>
                                    </b-select>                                        
                                </b-field>

                                    <table class="table is-fullwidth">
                                        <tr  v-for="(item, index) in model.prazos?.filter(i => i.show===true)" v-bind:key="index">
                                            <td>{{item.prazoNome}} </td>                                                                          
                                            <td><b-button type="is-danger" size="is-small" @click="deleteItemPrazo(item,index)" title="Remover Prazo?" > <b-icon icon="delete" size="is-small"></b-icon> </b-button> </td>   
                                        </tr>
                                    </table>                                                             
                            </div>   

                            <div class="column is-2">
                                <label class="label" style="visibility: hidden;">{{$t('SISTEMA.INCLUIR')}}</label>
                                <b-button type="is-success" icon-left="plus" @click="addItemPrazo()" > {{$t('SISTEMA.INCLUIR')}}</b-button>                                  
                            </div>

                            <div v-if="model.tipo==1 && !model.id && camposApenasLeitura()" class="column is-2">
                                <label class="label">{{$t('MODALDOCUMENTOVENDA.POSSUIALTERACAO')}}</label>
                                <b-radio v-model="model.possuiAlteracao"
                                    name="possuiAlteracao"                                    
                                    native-value="true">
                                    {{$t('SISTEMA.SIM')}}
                                </b-radio>
                            </div>
                            <div v-if="model.tipo==1 && !model.id && camposApenasLeitura()" class="column is-2">
                                <label class="label" style="visibility: hidden;">{{$t('MODALDOCUMENTOVENDA.POSSUIALTERACAO')}}</label>
                                <b-radio v-model="model.possuiAlteracao"
                                    name="possuiAlteracao"                                    
                                    native-value="false">
                                    {{$t('SISTEMA.NAO')}}
                                </b-radio>
                            </div>         
                            <div class="column is-2"></div>                     
                        </div> 
                        <div v-if="model.possuiAlteracao =='true' " class="columns">   
                            <div class="column is-6"></div>  
                            <div  class="column is-3">
                                <b-field :label="$t('GUIAATENDIMENTO.TEMPERATURA')">
                                    <b-select :placeholder="$t('GUIAATENDIMENTO.TEMPERATURA')" expanded v-model="model.temperatura">
                                        <option value="1">FRIO</option>
                                        <option value="2">MORNO</option>
                                        <option value="3">QUENTE</option>
                                    </b-select>  
                                </b-field>                                                            
                            </div>  
                            <div  class="column is-3">
                                <b-field :label="$t('GUIAATENDIMENTO.PRAZOCONCLUSAOVENDEDOR')">
                                    <b-input  type="number" max="100"  min="0" v-model="model.prazoVendedorEmDias"></b-input>
                                </b-field>                                                            
                            </div>                              
                        </div>                               
                        <div v-if="model.possuiAlteracao =='true' " class="columns">                               
                            <div  class="column is-12">
                                <table  v-if="ambientesSelecionados !=null && ambientesSelecionados.length>0"   class="table  is-fullwidth is-striped">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Opção</th>
                                            <th>Ambiente</th> 
                                            <th>Tipo</th>
                                            <th>Situação</th>  
                                            <th>Alterações</th>                                                   
                                        </tr>                                                
                                    </thead>
                                    <tbody>                                                
                                        <tr v-for="(ambiente, index) in ambientesSelecionados" :key="index">
                                            
                                            <td>
                                                    <b-checkbox  @input="changedAlterados(ambiente)"
                                                    size="is-small" type="is-info"  v-model="ambiente.selecionadoAlterado"></b-checkbox >                                    
                                            </td>                                
                                            <td>{{ ambiente.opcao  }}</td>
                                            <td>{{ ambiente.descricao }}</td>                                                    
                                            <td>{{ ambiente.tipoDescricao }}</td>                            
                                            <td>{{ ambiente.tipoSituacao }}</td> 
                                            <td>
                                                <b-input  expanded v-model="ambiente.alteracao"></b-input>                                                
                                            </td>                                                                                                                                                      
                
                                        </tr>
                                    </tbody>                        
                                </table>                             
                            </div>  
                        </div>                                                    
                                                                                                                                        
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button v-if="guia.dataHoraVenda ==null && !model.id && guia.guiaVinculadaId <=0" type="is-success" :loading="salvandoDados"
                      @click="efetivarVenda" icon-left="currency-usd">Efetivar Venda</b-button>
                      
            <b-button v-if="model.id>0 && this.isInRole('vendas-alterar-resumo') && guia.guiaVinculadaId <=0" type="is-success" icon-left="check-circle"
                      :loading="salvandoDados" @click="confirmaAlteracaoDados" > {{$t('SISTEMA.SALVAR')}}</b-button>      

            <b-button @click="abrirPdf()" v-if="model.id>0 "  icon-left="eye"  type="is-info"  
            >{{$t('SISTEMA.PDF')}}</b-button>  

            <b-button @click="$emit('close'); $emit('limparVendaDireta');"> {{$t('SISTEMA.FECHAR')}}</b-button>
        </footer>
    </div>
</template>

<style scoped>
    .is-clearfix{
        width:  100% !important;
    }
</style>

<script>    
    import searchIdName from '@/components/searchIdName.vue'      
    import modalListarContratos from '@/components/venda/modalListarContratos.vue' 
    import { mapGetters, mapState } from 'vuex'  

    export default {
        props: {
            guia: Object,
            semAgendamento: {
                type: Boolean,
                default: false
            },
            vendaDireta: {
                type: Boolean,
                default: false
            }                                        
        },
        data() {
            return {
                model:{
                    guiaId: this.guia.id,
                    clienteId: this.guia.clienteId,
                    unidadeId: this.unidadeId,                    
                    observacao:null,
                    alteracoesFinanceiras:null,
                    valorElist:(0).toFixed(2),
                    valorVenda: (0).toFixed(2),
                    valorVista: (0).toFixed(2),
                    valorVistaSemIndicador: (0).toFixed(2),
                    valorPercentual: (0).toFixed(2),
                    valor:(0).toFixed(2),
                    associacaoPercentual: (0).toFixed(2),
                    associacao:(0).toFixed(2),                    
                    opcaocaptador: false,
                    captadorPercentual: (0).toFixed(2),
                    captador: (0).toFixed(2),
                    possuiAlteracao:false,
                    entregaFutura:false,
                    prazos:[],
                    tipo:1,
                    temperatura:0,    
                    prazoVendedorEmDias: null                     
                },
                salvandoDados :false,
                campoDisabledPossuiAlteracao: false,                
                prazosVendas: [],
                tiposVenda:[],
                prazoSelecionado:null,
                ambientes:[],
                ambientesSelecionados:[],
                ambientesAlteradosSelecionados : [],
                selecionadoTodos:false,
                dadosContrato : null
            }
        },
        components: {            
            searchIdName
        },
        computed: {

            ...mapState([
                'unidadeId',
                'config',                                             
                'usuario', 
            ]),
            ...mapGetters([
                'isInRole'
            ]),           
        },   
        mounted() {            
            if(!this.model.unidadeId || this.model.unidadeId == undefined)
                this.model.unidadeId = this.unidadeId
                
            if(this.guia.status == 1 || this.guia.status == 4 || this.guia.status == 5) {
                this.loadDataVenda(this.guia.Id);
            }else{
                this.loadPercentuaisUnidade();
            } 
            
            this.loadPrazosVenda();
            this.loadTiposVendas();            
            this.loadAmbientes();
        
        },             
        methods: {

            camposApenasLeitura(){

                if(this.vendaDireta){
                    return false;
                }else{
                    return true;
                }
                
            },

            preencherDadosContrato(contrato){
                
                this.salvandoDados = true;   
                
                const params = [];                                                                                                      
                params.push(`clienteId=${this.guia.clienteCodigoExterno}`);  
                params.push(`guiaId=${this.guia.id}`);  
                params.push(`unidadeId=${this.unidadeId}`); 
                params.push(`codigoContrato=${contrato.codigoContrato}`);                 

                this.$http.get(`/api/integracao/ListarContratosEList?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {     
                        this.dadosContrato = data;                       
                        this.isLoading=false;
                    }).finally(() => {                                
                        this.salvandoDados = false; 
                        this.loadAmbientes();
                        
                        setTimeout(() => {

                            this.ambientes.forEach((item) => {
                                item.selecionado=true;
                                this.changed(item);
                            });                               
                            this.model.valorVenda = parseFloat(this.dadosContrato[0].valorPrazo).toFixed(2);  
                            this.model.valorVista = parseFloat(this.dadosContrato[0].valorVista).toFixed(2);  
                            this.model.valorVistaSemIndicador = parseFloat(this.dadosContrato[0].valorVistaSemIndicador).toFixed(2); 
                            this.model.codigoContrato = this.dadosContrato[0].codigoContrato; 


                            if(this.dadosContrato[0].tipoContrato== "Normal" || this.dadosContrato[0].tipoContrato == "Futura Curto Prazo" || this.dadosContrato[0].tipoContrato == "Decorado"){
                                this.model.tipo = 1;
                            }else if(this.dadosContrato[0].tipoContrato == "Produto Mostruário"){
                                this.model.tipo = 3;
                            }else if(this.dadosContrato[0].tipoContrato == "Futura"){
                                this.model.tipo = 4;
                            }else if(this.dadosContrato[0].tipoContrato == "AT Cliente"){
                                this.model.tipo = 2;
                            }

                            if(this.model.valorPercentual > 0 )
                                this.alterouValorPerc();
                            
                            if(this.model.associacaoPercentual > 0 )
                                this.alterouAssociacaoPerc();
                                                        
                        }, 1000);
                    });   
                
            },
            listarContratos(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalListarContratos,
                    props: {
                        guiaId: this.guia.id,
                        clienteCodigoExterno: this.guia.clienteCodigoExterno                                       
                    },                   
                    events: {
                        preencherDadosContrato: this.preencherDadosContrato                        
                    },                     
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },            
            changedAll(){

                if(this.selecionadoTodos){

                    this.ambientes.forEach((item) => {
                        item.selecionado=true;
                        this.changed(item);
                    });                    

                }else{

                    this.ambientes.forEach((item) => {
                        item.selecionado=false;
                        this.changed(item);
                    });                        

                }

            },         
            changed(ambiente){

                if(ambiente.selecionado){
                    this.ambientesSelecionados.push(ambiente);
                }else{
                    this.ambientesSelecionados = this.ambientesSelecionados.filter(x=>x.id != ambiente.id);
                }
                this.atualizaValoElist(); 
            },
            atualizaValoElist(){

                let valorElist = 0.0 ;

                this.ambientes.filter(x=>x.selecionado).forEach((item) => {
                      valorElist = valorElist + item.valor                          
                });                   

                setTimeout(() => {
                    this.model.valorElist = valorElist.toFixed(2);
                    },500);

            },
            changedAlterados(ambiente){

                if(ambiente.selecionadoAlterado){
                    this.ambientesAlteradosSelecionados.push(ambiente);
                }else{
                    this.ambientesAlteradosSelecionados = this.ambientesAlteradosSelecionados.filter(x=>x.id != ambiente.id);
                }
                    
            },                    
            loadAmbientes() {              
                this.$http.get('/api/atendimento/ambientes?id=' + this.model.guiaId)
                    .then(res => res.data)
                    .then(data => {
                        this.ambientes = data;

                    }).finally(() => {                                

                        setTimeout(() => {
                                if(this.model.id>0){
                                    this.selecionadoTodos = true;
                                    this.changedAll();
                                }                                    
                            }
                        ,500);

                    }); 

                    
            },                
            abrirPdf(){                
                const url = `/api/venda/ResumoVendaPDF?guiaId=`+this.model.guiaId;
                window.open(url, "_blank");
            },         
            loadPrazosVenda(){
                this.$http.get('/api/manutencao/Prazos?unidadeId='+this.unidadeId)
                .then(res => {
                    if (res.data != null) {
                        this.prazosVendas = res.data.lista
                    }                    
                });

                this.prazoSelecionado = null;
            },
            loadTiposVendas(){
                this.$http.get('/api/search/ListarTiposVenda')
                .then(res => {
                    if (res.data != null) {
                        this.tiposVenda = res.data
                    }
                })                

            },
            loadPercentuaisUnidade(){
                this.$http.get('/api/search/PercentuaisConfiguracao?unidadeId='+this.unidadeId)
                .then(res => {
                    if (res.data.percentual_arquiteto >0 ) {                        
                        this.model.valorPercentual = parseFloat(res.data.percentual_arquiteto ).toFixed(2);  
                        this.alterouValorPerc();
                    }

                    if (res.data.percentual_associacao >0 ) {                        
                        this.model.associacaoPercentual = parseFloat(res.data.percentual_associacao ).toFixed(2);  
                        this.alterouAssociacaoPerc();
                    }                    
                })                

            },            
            
            
            addItemPrazo() {
                if(this.prazoSelecionado>0 && this.model.prazos.filter(x=>x.prazoId==this.prazoSelecionado).length==0 ){
                    this.model.prazos.push({
                        id: 0,
                        prazoNome: this.prazosVendas.filter(x=>x.id==this.prazoSelecionado)[0].nome,
                        vendaId: (this.model.id) ? this.model.id  : 0,
                        prazoId: this.prazoSelecionado,
                        show: true,                    
                    })
                }
                this.prazoSelecionado=null;

            },
            
            deleteItemPrazo(item,index) {
                if(item.id==0){
                    this.model.prazos.splice(index, 1);
                }else{
                    item.show = false; 
                }
                
                this.$forceUpdate();                
            },                  
         
            loadDataVenda(guiaId){
                this.$http.get('/api/venda/retornaVendaPorGuiaId?guiaId=' + this.guia.id)
                        .then(res => res.data)
                        .then(data => {

                            if(data && data !=""){
                                this.model = data;                                

                                if(data.dataHoraBloqueadoFutura !=null)
                                    this.model.dataHoraBloqueadoFutura = this.$moment(data.dataHoraBloqueadoFutura).toDate()

                                if(this.model.captador >0)
                                    this.model.opcaocaptador = true;

                                if(this.model.possuiAlteracao !=null )
                                    this.campoDisabledPossuiAlteracao = true;

                                if(this.model.entregaFutura !=null && this.model.status ==2 ){
                                    this.model.entregaFutura = true;   
                                    this.model.tipo = 4;                       
                                }
                                
                                if(this.model.tipo == 1 && this.model.entregaFutura){
                                    this.model.entregaFutura = true;                          
                                    this.model.tipo = 4;
                                }                                
                                                                                              
                                if(this.model.prazos == null)
                                    this.model.prazos = [];  
                                

                                this.model.valorElist = this.model.valorElist.toFixed(2);
                                this.model.valorVenda= this.model.valorVenda.toFixed(2);
                                this.model.valorVista= this.model.valorVista.toFixed(2);
                                this.model.valorVistaSemIndicador= this.model.valorVistaSemIndicador.toFixed(2);
                                this.model.valorPercentual=  (this.model.valorPercentual == null || this.model.valorPercentual =='NaN' || this.model.valorPercentual == undefined) ? 0.0 : this.model.valorPercentual.toFixed(2);
                                this.model.valor= (this.model.valor == null || this.model.valor =='NaN' || this.model.valor == undefined) ? 0.0 : this.model.valor.toFixed(2);
                                this.model.associacaoPercentual= (this.model.associacaoPercentual == null || this.model.associacaoPercentual =='NaN' || this.model.associacaoPercentual == undefined) ? 0.0 : this.model.associacaoPercentual.toFixed(2);
                                this.model.associacao= (this.model.associacao == null || this.model.associacao =='NaN' || this.model.associacao == undefined) ? 0.0 : this.model.associacao.toFixed(2);                                
                                this.model.captador= (this.model.captador == null || this.model.captador =='NaN' || this.model.captador == undefined)  ? 0.0 : this.model.captador.toFixed(2);

                                if(this.model.captador >0)
                                    this.alterouCaptador();
                                

                                this.model.prazos.forEach((p) => { 
                                    p.show = true;                             
                                });                                
                            }

                        });
            },
            alterouValor(){
                this.model.valorPercentual = parseFloat(( this.model.valor /this.model.valorVenda  ) * 100).toFixed(2);
                this.model.valor= parseFloat(this.model.valor).toFixed(2);    
            },
            alterouValorPerc(){
                this.model.valor = parseFloat((this.model.valorPercentual/100)*this.model.valorVenda).toFixed(2);
                this.model.valorPercentual= parseFloat(this.model.valorPercentual).toFixed(2);
            },  
            alterouAssociacao(){
                this.model.associacaoPercentual = parseFloat(( this.model.associacao /this.model.valorVenda  ) * 100).toFixed(2);
                this.model.associacao= parseFloat(this.model.associacao).toFixed(2);    
            },
            alterouAssociacaoPerc(){
                this.model.associacao = parseFloat((this.model.associacaoPercentual/100)*this.model.valorVenda).toFixed(2);
                this.model.associacaoPercentual= parseFloat(this.model.associacaoPercentual).toFixed(2);      
            },       
            formataValorEList(){
                this.model.valorElist = parseFloat(this.model.valorElist).toFixed(2);  
            },          
            formataValorVenda(){
                this.model.valorVenda = parseFloat(this.model.valorVenda).toFixed(2);  
            },
            alterouCaptadorPerc(){
                this.model.captador = parseFloat((this.model.captadorPercentual/100)*this.model.valorVistaSemIndicador).toFixed(2);
                this.model.captadorPercentual= parseFloat(this.model.captadorPercentual).toFixed(2);      
            },    
            alterouCaptador(){                
                this.model.captadorPercentual = parseFloat(( this.model.captador /this.model.valorVistaSemIndicador  ) * 100).toFixed(2);
                this.model.captador= parseFloat(this.model.captador).toFixed(2);                    
            },                   
            async confirmaAlteracaoDados(){
                        
                        
                if (this.model.tipo==4 && this.model.dataHoraBloqueadoFutura == null  ) {
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMDATAPRAZOLIBERACAO'),
                        type: 'is-warning',
                        hasIcon: true
                    });                    
                    return;
                }     
                                        
                if (!this.model.valorElist || this.model.valorElist <=0 ) {
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMVALORELIST'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    return;
                }      

                if (!this.model.valorVenda || this.model.valorVenda <=0 ) {
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMVALORVENDA'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    return;
                }      
                

                
                if (this.model.possuiAlteracao == null) {
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMPOSSUIALTERACAO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    return;
                }   
                
                if (this.model.possuiAlteracao == 'true' && this.ambientesAlteradosSelecionados.length==0) {
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMSELECIONEAMBIENTESALTERADOS'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    return;
                }   
                
                if(this.model.possuiAlteracao == 'true' && this.model.temperatura == null){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALALTERACAOPROJETO.CAMPOPRIORIDADEOBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true
                    }); 
                    return false;
                }       
                
                if(this.model.possuiAlteracao == 'true' && (this.model.prazoVendedorEmDias ==0 || this.model.prazoVendedorEmDias == null) ){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALALTERACAOPROJETO.CAMPOPRAZOOBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true
                    }); 
                    return false;
                }                     
                                
                
                
                if (!this.model.prazos || this.model.prazos == null 
                || this.model.prazos.filter(x=>x.show).length==0) {
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMPRAZOVENDA'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    return;
                }                      
                                                                
                if(!this.model.opcaocaptador)
                    this.model.captador = (0).toFixed(2);       
                
                if(this.model.tipo !=4 ){                    
                    this.model.entregaFutura = false;
                }  
                
                if( this.model.tipo == 4){
                    this.model.entregaFutura = true;                
                    this.model.tipo = 1;
                }

                if(this.model.dataHoraBloqueadoFutura)
                    this.model.dataHoraBloqueadoFutura = this.$moment(this.model.dataHoraBloqueadoFutura).format('YYYY-MM-DDTHH:mm:ss');                
                
                this.salvandoDados=true; 
                
                this.model.ambientesAlterados = this.ambientesAlteradosSelecionados;
                
                this.$http.post(`/api/venda/AlterarDadosResumo`,this.model)
                .then((res) => {
                    this.salvandoDados=false;
                }).finally(() => {                                
                    this.isLoading = false;    
                    this.$emit('loadData')                            
                    this.$emit('close');   
                    this.$router.back();                               
                });                                  
            },     
            async efetivarVenda() {
                

                if(this.ambientesSelecionados.length<=0){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMSELECIONEAMBIENTES'),
                        type: 'is-warning',
                        hasIcon: true
                    }); 
                    return false;
                }                    
                                

                if (this.model.tipo==4 && this.model.dataHoraBloqueadoFutura == null  ) {
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMDATAPRAZOLIBERACAO'),
                        type: 'is-warning',
                        hasIcon: true
                    });                    
                    return;
                }     

                if (!this.model.valorElist || this.model.valorElist <=0 ) {
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMVALORELIST'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    return;
                }      

                if (!this.model.valorVenda || this.model.valorVenda <=0 ) {
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMVALORVENDA'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    return;
                }      
                

                
                if (this.model.possuiAlteracao == null) {
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMPOSSUIALTERACAO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    return;
                } 
                
                if (this.model.possuiAlteracao == 'true' && this.ambientesAlteradosSelecionados.length==0) {
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMSELECIONEAMBIENTESALTERADOS'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    return;
                }       
                
                if(this.model.possuiAlteracao == 'true' && this.model.temperatura == null){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALALTERACAOPROJETO.CAMPOPRIORIDADEOBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true
                    }); 
                    return false;
                }       
                
                if(this.model.possuiAlteracao == 'true' && (this.model.prazoVendedorEmDias ==0 || this.model.prazoVendedorEmDias == null) ){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALALTERACAOPROJETO.CAMPOPRAZOOBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true
                    }); 
                    return false;
                }                     
                
                
                if (!this.model.prazos || this.model.prazos == null 
                || this.model.prazos.filter(x=>x.show).length==0) {
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMPRAZOVENDA'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    return;
                }                      
                                                                
                if(!this.model.opcaocaptador)
                    this.model.captador = (0).toFixed(2);

                if(this.model.tipo !=4 ){                    
                    this.model.entregaFutura = false;                    
                }  

                if( this.model.tipo == 4){
                    this.model.entregaFutura = true;                
                    this.model.tipo = 1;
                }


                //Apenas quando for venda Direta - Sem fluxo
                if(this.vendaDireta){
                    this.model.vendaDireta = true;

                    if(this.guia.funcionarioComercialId > 0){
                        this.model.vendedorId =  this.guia.funcionarioComercialId ;
                    }else{                        
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMCAMPOOBRIGATORIOVENDEDOR'),
                            type: 'is-warning',
                            hasIcon: true
                        }); 
                        return false;
                                                  
                    }

                    if(this.guia.funcionarioProjetistaId > 0){
                        this.model.projetistaId =  this.guia.funcionarioProjetistaId ; 
                    }else{
                        this.$buefy.dialog.alert({
                            title: this.$t('SISTEMA.ATENCAO'),
                            message: this.$t('MODALDOCUMENTOVENDA.MENSAGEMCAMPOOBRIGATORIOPROJETISTA'),
                            type: 'is-warning',
                            hasIcon: true
                        }); 
                        return false;
                    }
                    
                    if(this.guia.arquitetoId > 0)
                        this.model.arquitetoId =  this.guia.arquitetoId ;                                                        

                }

                if(this.model.dataHoraBloqueadoFutura)
                    this.model.dataHoraBloqueadoFutura = this.$moment(this.model.dataHoraBloqueadoFutura).format('YYYY-MM-DDTHH:mm:ss');

                 this.$buefy.dialog.confirm({
                    message: this.$t('ATENDIMENTO.MENSAGEMCONFIRMAVENDA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {   
                        
                        this.salvandoDados=true;

                        this.model.ambientes = this.ambientesSelecionados;
                        this.model.ambientesAlterados = this.ambientesAlteradosSelecionados;
                                                                                                         
                        try {
                            
                            this.$http.post(`/api/atendimento/vendaEfetivadaGuia?semAgendamento=`+this.semAgendamento,this.model)
                            .then((res) => {
                                this.salvandoDados=false;
                            }).finally(() => {                                
                                this.isLoading = false;    
                                this.$emit('loadData')                            
                                this.$emit('close');   
                                this.$router.back();                                         
                            });                            

                            
                        } catch (e) {
                            this.salvandoDados=false;                        
                            console.error(e);
                        }

                        return true;
                    },
                    onCancel: () => {                        
                        this.guia.status = 0;
                        this.negociacao = null;
                        this.salvandoDados = false;    
                    },
                })  
            },

        }
    }
</script>